import { retrieveUser } from 'api/account'

import { localSet } from 'utils/localStorage'
import * as Database from '../../db'
import { saveWalletInfo } from 'db/schema/wallet'
import { ethers } from 'ethers'
import useUser from 'hooks/useUser'
import { User } from '@supabase/supabase-js'
import { useStore } from '../../store'

export const createWalletAddress = async (_userInfo: User | null , getUser:(user?:User) => void) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { closeAllModalStates } = useStore()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { updateProfile, } = useUser()
    if (!_userInfo) return
    const newWallet = ethers.Wallet.createRandom();
    const privateKey = newWallet.privateKey
    const myMnemonic = newWallet.mnemonic
    const myWallet = {
        address: newWallet.address,
        pKey: privateKey,
        mnemonic: myMnemonic
    }
    saveWalletInfo(newWallet.address, privateKey, myMnemonic);
    const db = await Database.get()
    const addData = {
        walletInfo: myWallet,
        walletAddress: newWallet.address,
        userName: _userInfo.user_metadata.user_name,
        email: _userInfo.email,
        pin: _userInfo.user_metadata.pin_code,
        dateCreated: new Date().toISOString().slice(0, -5) + '+00:00',
    }
    await db.account.insert(addData)
    await updateProfile({ wallet: newWallet.address })
    const { data } = await retrieveUser()
    if (!data) return
    localSet('authAddress', data.user_metadata.user_wallet)
    localSet('user_info', data)
    closeAllModalStates(false)
    getUser(data)
}


export const findUserAddress = async (userInfo: User, getUser:(user?:User) => void) => {
    const db = await Database.get();
    const sub = db.account.findOne(
        {
            selector: {
                email: userInfo.email
            }
        }
    )
    const result = await sub.exec()
    if (!result) {
        if (!userInfo.user_metadata.user_wallet) {
            return createWalletAddress(userInfo, getUser)
        } else {
            const addData = {
                walletAddress: userInfo.user_metadata.user_wallet,
                userName: userInfo.user_metadata.user_name,
                email: userInfo.email,
                pin: userInfo.user_metadata.pin_code,
                dateCreated: new Date().toISOString().slice(0, -5) + '+00:00',
            }
            try {
                await db.account.insert(addData)
            } catch (err) {
                console.log(err)
            }
        }

    } else {
        const userData = JSON.parse(JSON.stringify(result))
        const walletInfo = userData.walletInfo ?? userData.walletAddress
        try {
            await db.account.upsert(
            {
                walletInfo: walletInfo,
                email: userInfo.user_metadata.email,
                userName: userInfo.user_metadata.user_name,
                walletAddress: userInfo.user_metadata.user_wallet,
                pin: userInfo.user_metadata.pin_code,
                dateCreated: new Date().toISOString().slice(0, -5) + '+00:00',
            },{
                email: userInfo.email
            });
        } catch (err) {
            console.log(err)
        }
    }

}