import { ActionSheetOptions, useIonActionSheet } from '@ionic/react';
import { RealtimeChannel } from '@supabase/supabase-js';
import {
  countPublicChannelMember,
  createSubscription,
  getPublicChannels,
  removeChannel
} from 'api/chat';
import { setShowLoading } from 'components/GlobalLoading';
import { useEffect, useState } from 'react';
import { useStore } from 'store';
interface CommonChannel {
  room_id: string;
  room_name: string;
}
export interface ChatMessage {
  user_id: string;
  message_id: string;
  message: string;
  room_id: string;
  message_time: string;
  edited: boolean;
  pinned: boolean;
  parent_message_id: string | null;
  message_emotes: string | null;
}

// interface Attachments {}

// interface Meta {
//   lwt: number;
// }
const useCommonChannels = () => {
  const [channelPeopleCount, setChannelPeopleCount] = useState<number>(0);
  const [channelSubscribable, setChannelSubscribable] =
    useState<RealtimeChannel>();
  const { getCommonChatMessages, addCommonChatMessages } = useStore();
  const [present] = useIonActionSheet();
  const [currentChannel, setCurrentChannel] = useState<CommonChannel>();

  const [channels, setChannels] = useState<CommonChannel[]>([]);
  const getChannels = async () => {
    try {
      setShowLoading(true);
      const data = await getPublicChannels();
      if (data.data && data.data.length > 0) {
        setCurrentChannel(data.data[0]);
      }
      setChannels(data.data ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  const onCreateChannelSubscribable = (name: string) => {
    if (!currentChannel) return;
    const _channel = createSubscription(name);
    setChannelSubscribable(_channel);
    _channel?.on('presence', { event: 'sync' }, () => {
      const newState = _channel.presenceState();
      console.log('sync', newState);
    });
    _channel?.on('presence', { event: 'join' }, ({ key, newPresences }) => {
      console.log('join', { key, newPresences });
    });
    _channel?.on('presence', { event: 'leave' }, ({ key, leftPresences }) => {
      console.log('leave', { key, leftPresences });
    });
    _channel?.on<ChatMessage>(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public_chat',
        table: 'room_messages',
        filter: `room_id=eq.${currentChannel.room_id}`
      },
      ({ new: _new }) => {
        const new_message = {
          message_id: _new['message_id'],
          message_time: _new['message_time'],
          ...JSON.parse(_new['message'])
        };
        addCommonChatMessages(new_message);
      }
    );
  };

  const getChannelPeopleNumber = async () => {
    try {
      const res = await countPublicChannelMember();
      if (res.data && res.status === 200) {
        setChannelPeopleCount(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onChooseChannel = () => {
    const buttons: ActionSheetOptions['buttons'] = channels.map((channel) => {
      return {
        text: channel.room_name,
        handler() {
          if (currentChannel?.room_id === channel.room_id) return;
          setCurrentChannel(channel);
        }
      };
    });

    present({
      mode: 'ios',
      cssClass: 'hight-level',
      header: 'Which channel do you want to open?',
      buttons
    });
  };
/*   useEffect(() => {
    const listener = async (e: BeforeUnloadEvent) => {
      e.preventDefault();
      channelSubscribable && removeChannel(channelSubscribable);
    };
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  useEffect(() => {
    if (!currentChannel) return;
    getCommonChatMessages(currentChannel.room_id);
    onCreateChannelSubscribable(currentChannel.room_name);
    getChannelPeopleNumber();
    return () => {
      channelSubscribable && removeChannel(channelSubscribable);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChannel]);

  useEffect(() => {
    channelSubscribable?.subscribe();
    return () => {
      channelSubscribable?.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelSubscribable]);

  useEffect(() => {
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scroll to bottom

  return {
    channels,
    currentChannel,
    setCurrentChannel,
    onChooseChannel,
    channelPeopleCount
  };
};

export default useCommonChannels;
