import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const notificationSchema = {
    title: 'notificationSchema',
    description: 'notification',
    version: 1,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        user: {
            type: 'string',
        },
        type: {
            type: 'string',
        },
        objectId: {
            type: 'object',
        },
        message: {
            type: 'string',
        },
        isRead: {
            type: 'boolean',
        },
        isNotify: {
            type: 'boolean',
        },
        image: {
            type: 'string',
        },
        createdTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        }
    }
}

// select notification
export const selectNotification = async (pageNumber, pageSize) => {
    const db = await Database.get();
    const query = {
        // selector: {
        //     $or: [
        //         {
        //             $and: [
        //                 { 'user': { $elemMatch: { _id: localGet('authAddress') } } },
        //                 { isNotify: true }
        //             ]
        //         },
        //         {
        //             isNotify: false
        //         }
        //     ]
        // },
        selector: {
            user: localGet('authAddress')
        },
        sort: [{ isRead: 'asc', createdTime: 'desc' }],
    }

    const data = await db.notification.find(query).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();
    return JSON.parse(JSON.stringify(data));
}

// select notification by objectId
// export const selectNotificationByObjectId = async (objectId) => {
//     const db = await Database.get();
//     const query = {
//         selector: {
//             'objectId': objectId
//         }
//     }

//     const data = await db.notification.find(query).exec();
//     return JSON.parse(JSON.stringify(data));
// }