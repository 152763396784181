import { useSetState } from "ahooks"
import { emojiCount } from "api/chat"
import SVGIcon from "components/SvgComponent"
import useClickOutside from "hooks/useClickOutside"
import { FC, MouseEvent, useEffect, useRef, useState } from "react"
import { formatBetterDate } from "utils/dateFormate"
import styles from "./chatItem.module.scss"
import ChatroomEmojiComment from "./chatroom-emoji-comment"
import ChatroomEmojiPicker from "./chatroom-emoji-picker"
type ChatMessageItem = {
    message_id: string
    avatar: string
    message_time: string
}
interface ChatroomItemProps {
    type?: 'CustomChat' | 'ChatRoom';
    role?: 'other' | 'self',
    isGroup?: boolean
    chat_message: ChatMessageItem,
    ActiveBoxId?: string | undefined,
    onClick?: () => void,
    onHandleReply?: (message_id: string) => void,
    children?: React.ReactNode
    emojiIconList: string[]
}
export type EmojiSectionState = 'EmojiPicker' | 'EmojiComment'
const ChatroomItem: FC<ChatroomItemProps> = (props) => {
    const { type, ActiveBoxId, role, chat_message, onClick = () => { }, onHandleReply = (message_id: string) => { }, emojiIconList, children } = props
    const emojiPickerRef = useRef<HTMLDivElement>(null)
    const emojiButtonRef = useRef<HTMLDivElement>(null)
    const [emojiList, setEmojiList] = useState<{ emote: string, count: number }[]>([])
    const [emojiSectionState, setEmojiSectionState] = useSetState<Record<EmojiSectionState, boolean>>({
        EmojiComment: true,
        EmojiPicker: false
    })
    useClickOutside([emojiPickerRef, emojiButtonRef], () => {
        if (emojiSectionState.EmojiPicker) {
            setEmojiSectionState({ EmojiPicker: false })
        }
    })
    useEffect(() => {
        (async () => {
            await getEmojiCount()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat_message.message_id])
    const getEmojiCount = async () => {
        try {
            const { data } = await emojiCount(chat_message.message_id)
            setEmojiList(data ?? [])
        }
        catch (error) {
            console.log(error)
        }
    }
    const [replyBtnShow, setReplyBtnShow] = useState<boolean>(false);
    const handleMsgActionClick = (event: MouseEvent) => {
        event.stopPropagation();
        setReplyBtnShow(ActiveBoxId === chat_message?.message_id)
    }

    const handleEmojiActionClick = (event: MouseEvent) => {
        event.stopPropagation();
        setEmojiSectionState({ EmojiPicker: true })
    }
    const handleOnReply = (message_id: string) => {
        return (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            onHandleReply(message_id);
            setReplyBtnShow(false)
        }
    }
    useEffect(() => {
        setReplyBtnShow(false)
    }, [ActiveBoxId])

    return (
        <li key={chat_message?.message_id} className={`${styles.chat_li} ${type === 'CustomChat' ? '' : styles.chatRoom}`} onClick={onClick}>
            <div className="main_content">
                <div className="theMessage">
                    <div className={`msg_box ${role}`}>
                        <div className="msgMain">
                            <div className="chatroom__message--emoji">
                                <div className="chatroom__message--emoji-picker">
                                    <ChatroomEmojiPicker
                                        isOpen={emojiSectionState.EmojiPicker}
                                        emojiIconList={emojiIconList}
                                        ref={emojiPickerRef}
                                        messageID={chat_message.message_id}
                                        updateEmojiList={getEmojiCount} />
                                </div>
                                <div className="chatroom__message--emoji-comment">
                                    {emojiSectionState.EmojiComment && <ChatroomEmojiComment emojiList={emojiList} />}
                                </div>
                            </div>
                            {children}
                        </div>
                        <div className="msg_acts" style={role === "other" ? {} : { marginRight: '8px' }}>
                            <span style={ActiveBoxId === chat_message?.message_id ? { display: 'block' } : { display: 'none' }}>
                                <div className="actions_box">
                                    <button className="action_btn emoji_action_btn" onClick={(event) => handleEmojiActionClick(event)}>
                                        <SVGIcon name="SmileIcon" width="12" height="12" viewBox="0 0 12 12" fill="#72767e" />
                                    </button>
                                    {
                                        type === 'CustomChat' && (
                                            <div className="msg_action_box">
                                                {
                                                    replyBtnShow && <button className="reply_btn" onClick={handleOnReply(chat_message.message_id)}>Reply</button>
                                                }
                                                <button className="action_btn msg_action_btn" onClick={(event) => handleMsgActionClick(event)}>
                                                    <SVGIcon name="MoreIcon" width="11" height="4" viewBox="0 -4 12 12" fill="#72767e" />
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </span>
                        </div>
                    </div>
                    <div className="nameAndTime" style={role === 'other' ? {} : { flexDirection: 'row-reverse' }} >
                        {/* {role === 'other' && <div>{chat_message?.avatar}</div>} */}
                        <div className="times">

                            {/* <span className="status">
                                <SVGIcon name="CurrentIcon" width="24" height="24" viewBox="0 0 24 24" fill="#337eff"/>
                            </span> */}
                            <span className="times_desc">{formatBetterDate(new Date(chat_message?.message_time))}</span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}

export default ChatroomItem