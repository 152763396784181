export function formatPrize(prize) {
    if (prize < 10000) {
        return prize?.toLocaleString()
    } else if (prize < 1000000) {
        const millions = Math.floor(prize / 1000);
        return millions + " K";
    } else if (prize < 1000000000) {
        const millions = Math.floor(prize / 1000000);
        return millions + " Million";
    } else {
        const billions = Math.floor(prize / 1000000000);
        return billions + " Billion";
    }
}

export function ticketPrize(prize) {
    if (prize < 10000) {
        return prize?.toLocaleString()
    } else if (prize < 1000000) {
        const millions = Math.floor(prize / 1000);
        return millions + "K";
    } else if (prize < 1000000000) {
        const millions = Math.floor(prize / 1000000);
        return millions + "M";
    } else {
        const billions = Math.floor(prize / 1000000000);
        return billions + "B";
    }
}