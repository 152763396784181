import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const settingSchema = {
    title: 'settingSchema',
    description: 'setting',
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100,
        },
        biometric: {
            type: 'boolean',
        },
    }
};

// get setting
export const getSetting = async () => {
    const db = await Database.get();

    return await db.setting.findOne({
        selector: { id: localGet('authAddress') }
    }).exec();
}

// update setting
export const updateSetting = async (setting) => {
    const db = await Database.get();

    await db.setting.findOne({
        selector: { id: localGet('authAddress') }
    }).exec().then(async (doc) => {
        await doc.patch(setting);
    });
}

// insert setting
export const insertSetting = async (setting) => {
    const db = await Database.get();

    return await db.setting.insert(setting);
}