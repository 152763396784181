import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonList, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { User } from "@supabase/supabase-js";
import { Countup } from 'count-up-react';
import { useLocalStorage } from "hooks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { filterDraws } from "../db/schema/draws";
import { getAllImages } from "../db/schema/images";
import img3 from '../image//billion/Option=Icon0.png';
import img from '../image//billion/Option=Icon01.png';
import img2 from '../image//billion/Option=Icon04.png';
import img1 from "../image/upcoming/1.png";
import identifier from '../image/upcoming/identifier.png';
import back from "../image/upcoming/ill.png";
import style from "../style/Upcoming.module.css";
import { formatPrize } from "../utils/formatPrize";
import { localGet, localSet } from "../utils/localStorage";
import SVGIcon from "components/SvgComponent";

const JoinedGames: React.FC = () => {
  const [userInfo] = useLocalStorage<User | null>('user_info')
  const [drawImages, setDrawImages] = useState<any>()
  useEffect(() => {
    const setimages = async () => {
      setDrawImages(await getAllImages())
    }
    setimages()
    //eslint-disable-next-line
  }, [localGet('images')])
  const history = useHistory();
  const joinimg = require('../image/upcoming/joine.png')

  const [list, setlist]: any = useState([])
  const [jumppath, setJumpPath] = useState('joinedgames')
  const addCommas = (num: any) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  const getUrl = () => {
    window.localStorage.setItem('link', history.location.pathname)
  }

  const asyncFunc = async (props: any) => {


    let ress;
    if (props.draws !== null && props.draws?.length > 0) {
      const joinedSort = localGet('joinedSort');
      const joinedSortParame = localGet('joinedSortParame');
      // ress = await filterDraws('joined', '', 'desc', 10, 1).then();
      if (joinedSort === null) {
        ress = await filterDraws('joined', 'desc', '', localGet('joinPageSize') || 10, 1).then();
      } else if (joinedSort === 'time') {
        ress = await filterDraws('joined', joinedSortParame, '', localGet('joinPageSize') || 10, 1).then()
      } else if (joinedSort === "prize") {
        ress = await filterDraws('joined', '', joinedSortParame, localGet('joinPageSize') || 10, 1).then()
      }
      setlist(ress)
    }
  }



  async function gets() {
    const ress = await filterDraws('joined', 'desc', '', 10, 1).then()
    localStorage.removeItem('joinPageSize')
    localStorage.removeItem('joinedSort')
    localStorage.removeItem('joinedSortParame')
    setLazyListPageSize(10)
    setlist(ress)
    // console.log('getlistgetlistgetlist', getlist)

    PubSub.subscribe('syncData', function (msg: any, searchName: any) {
      asyncFunc(searchName)
    })
  }

  async function listSort(type: string, sort: string) {
    localSet('joinedSort', type)
    localSet('joinedSortParame', sort)
    localSet('joinPageSize', 10)
    let ress;
    if (type === "time") {
      ress = await filterDraws('joined', sort, '', 10, 1).then()
    } else if (type === "prize") {
      ress = await filterDraws('joined', '', sort, 10, 1).then()
    }
    setLazyListPageSize(10)
    setlist(ress)
  }
  useEffect(() => {
    setJumpPath('joinedgames')
    getUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jumppath])
  useEffect(() => {
    gets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [timeSortParame, setTimeSortParame] = useState('desc')
  const [prizeSortParame, setPrizeSortParame] = useState('')
  const [isClickSort, setIsClickSort] = useState('time')
  const [sort, setSort] = useState(true)
  const [przie, setPrzie] = useState(false)

  const [lazyListPageSize, setLazyListPageSize] = useState<number>(10)

  const generateItems = async () => {
    let ress
    if (isClickSort === '') {
      ress = await filterDraws('joined', '', 'desc', 10, lazyListPageSize / 10 + 1).then();
    } else if (isClickSort === 'time') {
      ress = await filterDraws('joined', timeSortParame, '', 10, lazyListPageSize / 10 + 1).then()
    } else if (isClickSort === "prize") {
      ress = await filterDraws('joined', '', prizeSortParame, 10, lazyListPageSize / 10 + 1).then()
    }
    if (ress.length === 0) {
    } else {
      let arr = [...list]
      arr.push(...ress)
      setlist(arr)
      setLazyListPageSize(lazyListPageSize + 10)
      localSet('joinPageSize', lazyListPageSize + 10)
    }
  };
  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={style.upcoming}>
          <div className={style.movie}>
            <img src={joinimg} alt="" />
          </div>
          <div className={style.lists}>
            <div className={style.change}  >

              <div className={style.changeleft}>
                <p
                  className={jumppath === 'upcoming' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('upcoming')
                    history.push('/upcoming')
                  }}
                >
                  Upcoming Draws
                </p>
                <p
                  className={jumppath === 'joinedgames' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('joinedgames')
                    history.push('/joinedgames')
                  }}
                >
                  Joined Draws
                </p>
                <p
                  className={jumppath === 'pastgames' ? style.selected : ''}
                  onClick={() => {
                    setJumpPath('pastgames')
                    history.push('/pastgames')
                  }}
                >
                  Past Draws
                </p>
                <p style={{ width: '90px', display: localGet('auth0_user') ? 'none' : 'block' }}></p>

              </div>


            </div>

          </div>
          <div className={style.line}></div>
          <div className={style.changerigth}>
            <p>Order by</p>
            <div className={style.changesvg} onClick={() => {
              setTimeSortParame(timeSortParame === "asc" ? 'desc' : 'asc')
              listSort('time', timeSortParame === "asc" ? 'desc' : 'asc')
              setIsClickSort('time')
              setSort(true)
              setPrzie(false)
            }}>
              <SVGIcon name="ClockIcon" width="18" height="18" viewBox="0 0 18 18" fill="white"/>
              <div className={
                sort === true ? (timeSortParame === "asc" ? style.nonechangesvgtop : style.changesvgtop1) : style.node
              } >
                <SVGIcon name="SortDownArrow" width="14" height="14" viewBox="0 0 14 14" fill="#9C94FF"/>
                {/* <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7" cy="7" r="7" fill="#9C94FF" />
                  <path d="M10.5 7L9.88313 6.38313L7.4375 8.82438V3.5H6.5625V8.82438L4.12125 6.37875L3.5 7L7 10.5L10.5 7Z" fill="black" />
                </svg> */}
              </div>
              <span>Time</span>
            </div>



            <div className={style.changepath} onClick={() => {
              setPrizeSortParame(prizeSortParame === 'asc' ? "desc" : "asc")
              listSort('prize', prizeSortParame === 'asc' ? "desc" : "asc")
              setIsClickSort('prize')
              setSort(false)
              setPrzie(true)
            }}>
              <SVGIcon name="SortPriceIcon" width="18" height="18" viewBox="0 0 18 18" fill="white"/>
              <div className={
                przie === true ? (prizeSortParame === "asc" ? style.nonechangesvgtop : style.changesvgtop1) : style.node
              }
              >
              <SVGIcon name="SortDownArrow" width="14" height="14" viewBox="0 0 14 14" fill="#9C94FF"/>
              </div>
              <span>Prize</span>
            </div>
          </div>
          {list.length > 0 ?
            <IonList className={style.cards} >
              {list.map((item: any, index: any) => (
                <IonItem
                  key={index}
                  className={item.isJoined === true ? style.lijoined : ""}
                  style={{ marginBottom: '4px' }}
                >
                  <div className={style.left}>
                    <div className={style.leftimg}>
                      <img src={item.drawImage === '' ? img1 : `${drawImages?.[item.drawImage] || img1}`} alt="" />
                    </div>

                    <div className={style.detail}>
                      <p>Join with ${addCommas(item.ticketPrice)} {item.ticketCount > 1 && <span><img src={identifier} alt="" />×{item.ticketCount}</span>}</p>
                      <p>${window.innerWidth <= 340 && item.prize >= 100000000 ? formatPrize(item.prize) : addCommas(item.prize)}</p>
                      <div className={style.progrebox}>

                        <div style={{
                          width: `${item.ticketsSold / item.maxTickets * 100}%`
                        }}
                          className={item.isJoined ? style.joined : style.progress}>

                        </div>
                      </div>

                      {item.winnerWallet !== "" && item.winnerNickname !== "" ?
                        <div className={style.count}>
                          {/* <span>{item.winnerNickname} won this draw!</span> */}
                          <span>{item?.winnerMembers?.length > 0 ? `${item.winnerNickname}'s group` : userInfo?.user_metadata?.user_name} won this draw</span>
                        </div>
                        :
                        <div className={style.count}>
                          <div className={style.coutimg}>
                            <img src={img} alt="" />
                            <img src={img2} alt="" />
                            <img src={img3} alt="" />

                          </div>
                          <span style={{ marginRight: '2px' }}>&</span>
                          <Countup
                            number={item?.ticketsSold || 10}
                            scrollTime={2000}
                            className={style.countUp}
                          />
                          <span style={{ marginLeft: '5px' }}>Joined</span>
                        </div>}

                    </div>
                  </div>
                  {item.isJoined === true ? (
                    <div className={style.joinedicon}>
                      <SVGIcon name="JoinGameSuccessIcon" width="21" height="20" viewBox="0 0 21 20" fill="#42E05C"/>
                      Joined
                    </div>
                  ) : (
                    <div className={style.join} >
                      <SVGIcon name="JoinGameAddIcon" width="14" height="14" viewBox="0 0 14 14" fill="black"/>
                    </div>
                  )}
                </IonItem>
              ))}
            </IonList> :
            <div className={style.cartex}>
              <img src={back} alt="" />
              <p>No Draws, Please Wait</p>
            </div>
          }
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              generateItems();
              setTimeout(() => ev.target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default JoinedGames;
