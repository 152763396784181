import { RealtimeChannel } from '@supabase/supabase-js';

import { retrieveUser } from './account';
import { compressAvatar, uploadFile } from './file';
import { http, supabase, supabase_public } from './initSupabase';

enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Revoked = 'revoked'
}
/**
 * get chat room old messages
 * @param roomID
 * @returns array[]
 */
export const getCommonChannelMessages = async (roomID: string) => {
  // get room messages
  const { data, error } = await supabase
    .from('room_messages')
    .select('message_id, message,message_time')
    .eq('room_id', roomID)
    .order('message_time', { ascending: true });
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  let resultArry: any[] = [];
  data.forEach((val, idx, array) => {
    resultArry.push({
      message_id: val.message_id,
      message_time: val.message_time,
      ...JSON.parse(val.message)
    });
  });
  return { status: 200, msg: null, data: resultArry };
};

/**
 * create subscription channel
 * @param name channel name
 */
export const createSubscription = (name: string) => {
  return supabase.channel(name);
};

/**
 *
 * @param channel channel instance
 */
export const subscriptionStatus = (channel: any) => {
  channel.subscribe((status: string, err: { message: any }) => {
    if (status === 'SUBSCRIBED') {
      return { status: 200, msg: 'Connected', data: null };
    }

    if (status === 'CHANNEL_ERROR') {
      return { status: 400, msg: err.message, data: null };
    }

    if (status === 'TIMED_OUT') {
      return {
        status: 408,
        msg: 'Realtime server did not respond in time.',
        data: null
      };
    }

    if (status === 'CLOSED') {
      return {
        status: 404,
        msg: 'Realtime channel was unexpectedly closed.',
        data: null
      };
    }
  });
};

/**
 * remove a channel
 * @param channelInstance RealtimeChannel
 */
export const removeChannel = (channelInstance: RealtimeChannel) => {
  supabase.removeChannel(channelInstance);
};

/**
 * remove all channels
 */
export const removeAllChannels = () => {
  supabase.removeAllChannels();
};

/**
 * get all channels
 * @returns realtime channels
 */
export const getAllChannels = () => {
  const channels = supabase.getChannels();
  return channels;
};

/**
 * get all public channles
 * @returns status, msg, data
 */
export const getPublicChannels = async () => {
  const { data, error } = await supabase
    .from('chat_rooms')
    .select('room_id, room_name, room_description')
    .eq('public_view', true)
    .eq('use_invites', false)
    .order('room_name', { ascending: false });
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }

  let result: Array<any> = [];
  data.forEach((element) => {
    if (element.room_description.app_name === 'OIAM') {
      switch (process.env.REACT_APP_ENV) {
        case 'production':
          if (element.room_description.app_version === 'production') {
            result.push(element);
          }
          return;
        case 'dev':
          if (element.room_description.app_version === 'dev') {
            result.push(element);
          }
          return;
        case 'staging':
          if (element.room_description.app_version === 'staging') {
            result.push(element);
          }
          return;
        default:
          if (element.room_description.app_version === 'dev') {
            result.push(element);
          }
          return;
      }
    }
  });

  return { status: 200, msg: null, data: result };
};

/**
 * get count all users
 * @returns {Status, msg, data}
 */
export const countPublicChannelMember = async () => {
  const { count, error } = await supabase_public
    .from('profiles')
    .select('user_id', { count: 'exact', head: true });
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  return { status: 200, msg: null, data: count };
};

export const emojiAction = async (
  messageID: string,
  userID: string,
  emoji: string
) => {
  const { data: emotes, error: emotes_error } = await supabase
    .from('message_emotes')
    .select<'emotes', { emotes: string[] }>('emotes')
    .eq('message_id', messageID)
    .eq('user_id', userID);

  if (emotes_error) {
    throw new Error(emotes_error.message);
  }
  if (emotes) {
    if (emotes.length === 0) {
      //data is empty, means the database does not have any emoji, you can insert emoji
      const res = await supabase
        .from('message_emotes')
        .insert({ emotes: [emoji], message_id: messageID, user_id: userID });
      return { status: 200, msg: res.statusText, data: null };
    } else {
      const _emotes = emotes[0].emotes;
      const isExist = _emotes.includes(emoji); //Determine if an emoji exists
      await supabase
        .from('message_emotes')
        .update({
          emotes: isExist
            ? _emotes.filter((_) => _ !== emoji)
            : [..._emotes, emoji]
        })
        .eq('message_id', messageID)
        .eq('user_id', userID);
    }
  }
};

export const emojiCount = async (messageID: string) => {
  const { data, error } = await supabase
    .from('room_messages')
    .select('message_emotes')
    .eq('message_id', messageID)
    .single();
  if (error) {
    return { status: 400, error: error.message, data: null };
  }
  return { status: 200, data: data.message_emotes, msg: '' };
};

/**
 * get new friends list data
 * @returns {Status, msg, data}
 */
export const newFriendList = async (userName: string) => {
  // check params
  if (!userName) {
    throw new Error('Please enter userName.');
  }

  const inviter = await retrieveUser();
  if (inviter.status === 400) {
    throw new Error('Not Authenticated ');
  }

  // get all user data
  const user_obj = await supabase_public
    .from('users_view')
    .select('user_id')
    .neq('user_id', inviter.data?.id);
  if (user_obj.error) {
    throw new Error(user_obj.error.message);
  }
  let users = user_obj.data?.map((element) => {
    return element.user_id;
  });

  // get inviter data
  const inviter_obj = await supabase
    .from('room_invites')
    .select('inviter_id')
    .eq('invitee_id', inviter.data?.id)
    .in('invite_status', ['accepted', 'pending']);
  if (inviter_obj.error) {
    throw new Error(inviter_obj.error.message);
  }
  const inviters = inviter_obj.data?.map((element) => {
    return element.inviter_id;
  });

  // get invitee data
  const invitee_obj = await supabase
    .from('room_invites')
    .select('invitee_id')
    .eq('inviter_id', inviter.data?.id)
    .in('invite_status', ['accepted', 'pending']);
  if (invitee_obj.error) {
    throw new Error(invitee_obj.error.message);
  }
  const invitees = invitee_obj.data?.map((element) => {
    return element.invitee_id;
  });

  // remove friend data
  for (var filterArry of [inviters, invitees]) {
    if (filterArry!.length > 0) {
      for (let value of filterArry!) {
        users = users?.filter((item) => item !== value);
      }
    }
  }

  // get new friends data
  if (users) {
    const { data, error } = await supabase_public
      .from('users_view')
      .select('user_id, user_name, avatar')
      .in('user_id', users)
      .ilike('user_name', `%${userName}%`)
      .order('user_name');
    if (error) {
      throw new Error(error.message);
    }
    return data;
  }
  return [];
};

interface ChatRoom {
  room_id: string;
  room_name: string;
  avatar: string;
  room_description?: string;
  last_message_time?: string;
  public_view: boolean;
  public_post: boolean;
  use_invites: boolean;
}
/**
 * create room
 * @param roomName string
 * @param avatar string
 * @returns {Status, msg, data}
 */
export const addRoom = async ({
  roomName,
  avatar,
  inviterID
}: {
  roomName: string;
  avatar?: File;
  inviterID: string;
}) => {
  let avatarPath: string | null = null;
  let insert_data = {
    room_name: roomName,
    use_invites: true,
    avatar: '',
    public_view: true,
    room_description: {
      app_name: 'OIAM',
      app_version: process.env.REACT_APP_ENV
    }
  };
  if (avatar) {
    const compressResult = await compressAvatar(avatar);
    if (compressResult.status === 400) {
      throw new Error('Field to compress avatar');
    }

    const uploadResult = await uploadFile(
      'avatars',
      `${inviterID}/${roomName}`,
      compressResult.data!
    );
    if (uploadResult.status === 400) {
      throw new Error('Field to upload.');
    }
    avatarPath = uploadResult.data;
    insert_data.avatar = avatarPath!;
  }

  const { data, error } = await supabase
    .from('chat_rooms')
    .insert(insert_data)
    .select<string, ChatRoom>()
    .single();

  if (error) {
    throw new Error(error.message);
  }
  return data;
};

/**
 * invite friends by user id
 * @param friendIds string[]
 * @param roomName string
 * @returns {Status, msg, data}
 */
export const inviteFriendsByUserID = async ({
  friendIds,
  roomAvatar,
  roomName
}: {
  friendIds: string[];
  roomName?: string;
  roomAvatar?: File;
}) => {
  // check params
  if (friendIds.length === 0) {
    return { status: 400, msg: 'Missing friend ID.', data: null };
  }

  const {
    data: { user },
    error: _user_error
  } = await supabase_public.auth.getUser();
  if (_user_error) {
    throw new Error(_user_error.message);
  }
  if (!user) {
    throw new Error('Not Authenticated');
  }
  const isGroup = friendIds.length > 1;
  let idArry = [user.id, ...friendIds].sort();
  roomName = roomName ?? idArry.join('&');

  const data = await addRoom({
    roomName,
    avatar: roomAvatar,
    inviterID: user?.id
  });
  if (!data) {
    throw new Error('Failed to add room');
  }
  const friend_promises = friendIds.map((friendId) =>
    supabase.from('room_invites').insert({
      room_id: data.room_id,
      inviter_id: user.id,
      invitee_id: friendId
    })
  );
  const res = await Promise.all(friend_promises);
  const error = res.filter((_) => _.error !== null);
  if (error.length > 0) {
    throw new Error(error[0].error?.message);
  }
  const { data: _invites, error: _invite_error } = await supabase
    .from('room_invites')
    .select()
    .eq('room_id', data.room_id);
  if (_invite_error) {
    throw new Error(_invite_error.message);
  }
  const promises = await Promise.all(
    _invites.map((_invite: any) => {
      return http.instance.post('/inviteFriendByUserID', {
        channelId: data.room_id,
        inviteId: _invite.id,
        groupName: roomName,
        groupAvatarURL: data.avatar,
        friendId: friendIds.find((_) => _ === _invite.invitee_id),
        isGroup
      });
    })
  );
  console.log(promises);
};

/**
 * get friend list data
 * @returns {Status, msg, data}
 */
export const friendList = async () => {
  const user = await retrieveUser();
  if (user.status === 400) {
    return user;
  }

  // get distinct rooms
  const roomObj = await supabase
    .from('room_users_view')
    .select('room_id')
    .order('unseen_message', { ascending: false });
  const roomArry = roomObj.data?.map((element) => {
    return element.room_id;
  });
  const roomTuple = [...new Set(roomArry)];

  const data = await Promise.all(
    roomTuple.map(async (element) => {
      const count = await supabase
        .from('room_invites')
        .select('id', { count: 'exact', head: true })
        .eq('room_id', element);
      if (count.error) return;
      // get room member number

      // get room member info
      const { data, error } = await supabase
        .from('room_users_view')
        .select('*')
        .eq('room_id', element)
        // .or(`user_name.ilike.'${name}%',room_name.ilike.'${name}%'`)
        .order('unseen_message', { ascending: false });
      if (error) {
        return { status: 400, msg: error.message, data: null };
      }
      // get room latest message
      const message = await supabase
        .from('room_messages')
        .select('message, message_time')
        .eq('room_id', data![0].room_id)
        .order('message_time', { ascending: false })
        .limit(1);
      if (message.error) {
        return { status: 400, msg: message.error, data: null };
      }

      if (data![0].room_name.includes(user.data?.id)) {
        for (let value of data) {
          if (value.user_id !== user.data?.id) {
            return {
              new_message_count: 0,
              avatar: value.user_avatar,
              name: value.user_name,
              latest_message: message.data?.[0]?.message ?? '',
              message_time: message.data?.[0]?.message_time ?? '',
              room_id: data![0].room_id,
              isGroup: false
            };
          }
        }
      } else {
        return {
          new_message_count: 0,
          avatar: data![0].room_avatar,
          name: data![0].room_name,
          latest_message: message.data?.[0]?.message ?? '',
          message_time: message.data?.[0]?.message_time ?? '',
          room_id: data![0].room_id,
          isGroup: true,
          member_count: count.count! + 1
        };
      }
    })
  );
  return { status: 200, msg: null, data: data.filter((_) => _) };
};

/**
 * get room messages
 * @param roomID string
 * @returns
 */
export const roomMessages = async (roomID: string) => {
  const { data, error } = await supabase
    .from('room_messages_view')
    .select('*')
    .eq('room_id', roomID)
    .order('message_time');
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  return { status: 200, msg: null, data: data };
};

/**
 * send text message
 * @param roomID string
 * @param message string
 * @returns {Status, msg, data}
 */
export const sendTextMessage = async (roomID: string, message: string) => {
  const user = await retrieveUser();
  if (user.status === 400) {
    return user;
  }

  const { data, error } = await supabase
    .from('room_messages')
    .insert({
      room_id: roomID,
      user_id: user.data?.id,
      message: message
    })
    .select();
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  return { status: 200, msg: null, data: data };
};

export const getUserInfo = async (user_id: string) => {
  return await supabase_public
    .from('users_view')
    .select<string, Record<'user_name' | 'email' | 'avatar', string>>(
      'user_name,email,avatar'
    )
    .eq('user_id', user_id)
    .single();
};

export const getInviteIsGroup = async (room_id: string) => {
  const { data, error } = await supabase
    .from('room_invites')
    .select()
    .eq('room_id', room_id);
  if (error) {
    throw new Error(error.message || 'Unknown Error');
  }
  const isGroup = data && data.length > 1;

  return isGroup;
};
// export const replyMessage = async (roomID: string, parentMessageID: string, )

export const handleInviterAccepted = async (id: string, isGroup: boolean) => {
  const { error } = await supabase
    .from('room_invites')
    .update({
      invite_status: InviteStatus.Accepted,
      accept_reject_time: new Date()
    })
    .eq('id', id);
  if (error) {
    throw new Error(error.message ?? 'Unknown Error');
  }
  const {
    data: { code, msg }
  } = await http.instance.post('/acceptFriendInvite', {
    inviteId: id,
    isGroup
  });
  if (code !== 200) {
    throw new Error(msg ?? 'Unknown Error');
  }
};

export const handleInviterRejected = async (id: string, isGroup: boolean) => {
  const { error } = await supabase
    .from('room_invites')
    .update({
      invite_status: InviteStatus.Rejected,
      accept_reject_time: new Date()
    })
    .eq('id', id);
  if (error) {
    throw new Error(error.message ?? 'Unknown Error');
  }
  const {
    data: { code, msg }
  } = await http.instance.post('/rejectFriendInvite', {
    inviteId: id,
    isGroup
  });
  if (code !== 200) {
    throw new Error(msg ?? 'Unknown Error');
  }
};

export const replyRoomMessage = async ({
  parent_message_id,
  message
}: {
  room_id: string;
  parent_message_id: string;
  message: string;
}) => {
  const { data: user, status, msg } = await retrieveUser();
  if (status === 400) {
    throw new Error(msg ?? 'Unknown Error.');
  }
  if (!user) {
    throw new Error('User not found.');
  }
  const { data: room_obj, error: room_error } = await supabase
    .from('room_messages')
    .select('room_id')
    .eq('message_id', parent_message_id)
    .single();
  if (room_error) {
    throw new Error(room_error.message);
  }
  if (!room_obj) {
    throw new Error('Message not found.');
  }
  const { data, error } = await supabase
    .from('room_messages')
    .insert({
      room_id: room_obj.room_id,
      user_id: user.id,
      message: message,
      parent_message_id
    })
    .select()
    .single();
  if (error) {
    throw new Error(error.message);
  }
  if (!data) {
    throw new Error('Failed to insert message.');
  }
  return data;
};

export const getAllFriends = async (userName?: string) => {
  const {
    data: { user: _user },
    error: _user_error
  } = await supabase_public.auth.getUser();
  if (_user_error) {
    throw new Error(_user_error.message ?? 'Not Authenticated.');
  }
  if (!_user) {
    throw new Error('User not find.');
  }
  const { error: _invite_error } = await supabase_public
    .from('users_view')
    .select('user_id')
    .neq('user_id', _user.id);
  if (_invite_error) {
    throw new Error(_invite_error.message ?? 'Unknown Error.');
  }
  // get inviter data
  const inviter_obj = await supabase
    .from('room_invites')
    .select('inviter_id')
    .eq('invitee_id', _user.id)
    .eq('invite_status', 'accepted');
  if (inviter_obj.error) {
    throw new Error(inviter_obj.error.message);
  }
  const inviters = inviter_obj.data?.map((element) => {
    return element.inviter_id;
  });

  // get invitee data
  const invitee_obj = await supabase
    .from('room_invites')
    .select('invitee_id')
    .eq('inviter_id', _user.id)
    .eq('invite_status', 'accepted');
  if (invitee_obj.error) {
    throw new Error(invitee_obj.error.message);
  }
  const invitees = invitee_obj.data?.map((element) => {
    return element.invitee_id;
  });
  const friendIds = [...invitees, ...inviters];

  if (friendIds.length > 0) {
    /*     const friendPromise = friendIds.map((_) => getUserInfo(_));
    for await (const promise of friendPromise) {
      const { data, error } = promise;
      console.log(data);
    } */
    if (userName?.trim()) {
      const { data, error } = await supabase_public
        .from('users_view')
        .select('user_id, user_name, avatar')
        .in('user_id', friendIds)
        .ilike('user_name', `%${userName}%`)
        .order('user_name');
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } else {
      const { data, error } = await supabase_public
        .from('users_view')
        .select('user_id, user_name, avatar')
        .in('user_id', friendIds)
        .order('user_name');
      if (error) {
        throw new Error(error.message);
      }

      return data;
    }
  } else {
    return [];
  }
};

export const getSelfProfile = async () => {
  const { data, error } = await supabase.auth.getUser();
  if (error) {
    throw new Error(error.message);
  }
  if (!data) {
    throw new Error('UserInfo not found');
  }
  return data.user;
};

export const getUserCommentEmoji = async (message_id: string) => {
  const { data, error } = await supabase
    .from('message_emotes')
    .select()
    .eq('message_id', message_id);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

export const getRoomMembers = async (room_id: string) => {
  const user = await getSelfProfile();
  const { data, error } = await supabase
    .from('room_users_view')
    .select('user_avatar,user_id,user_name')
    .eq('room_id', room_id)
    .neq('user_id', user.id);
  if (error) {
    throw new Error(error.message);
  }
  return data;
};

export const getIsInvitee = async (id: string) => {
  const {
    data: { user },
    error: user_error
  } = await supabase.auth.getUser();
  if (user_error) {
    throw new Error(user_error.message);
  }
  if (!user) {
    throw new Error('User not found');
  }
  const { data, error } = await supabase
    .from('room_invites')
    .select('invitee_id')
    .eq('invite_status', 'pending')
    .eq('id', id)
    .single();
  if (error) {
    throw new Error(error.message);
  }
  return data && data.invitee_id === user.id;
};

export const getInviteInfo = async (room_id: string) => {
  const user = await getSelfProfile();
  const isGroup = await getInviteIsGroup(room_id);
  const { data, error } = await supabase
    .from('room_invites')
    .select('id')
    .eq('invitee_id', user.id)
    .eq('room_id', room_id)
    .single();
  if (error) {
    throw new Error(error.message);
  }
  if (!data) {
    throw new Error('Invite not found.');
  }
  return { inviteId: data.id, isGroup };
};

export const getRoomDescription = async (room_id: string) => {
  const { data, error } = await supabase
    .from('chat_rooms')
    .select('room_description')
    .eq('room_id', room_id)
    .single();
  if (error) {
    throw new Error(error.message);
  }
  if(!data){
    throw new Error('Room not found')
  }
  return data
};
