import { RealtimeClient } from '@supabase/realtime-js';
import { createClient } from "@supabase/supabase-js";
import axios from 'axios';

/**
 * Instantiate the public_chat schema of supabase
 * @returns supabse client instance with public_chat schema
 */
const supabase_client = () => {
  if (!process.env.REACT_APP_SUPABASE_URL) throw new Error('Missing env.REACT_APP_SUPABASE_URL')
  if (!process.env.REACT_APP_SUPABASE_ANON_KEY) throw new Error('Missing env.REACT_APP_SUPABASE_ANON_KEY')
  if (!process.env.REACT_APP_SCHEMA) throw new Error('Missing env.REACT_APP_SCHEMA')
  return createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY,
    {
      db: {
        schema: process.env.REACT_APP_SCHEMA
      },
      auth: {
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: false
      }
    }
  )
}


/**
 * Instantiate the public schema of supabase
 * @returns supbase client instance with public schema
 */
const supabase_public_client = () => {
  if (!process.env.REACT_APP_SUPABASE_URL) throw new Error('Missing env.REACT_APP_SUPABASE_URL')
  if (!process.env.REACT_APP_SUPABASE_ANON_KEY) throw new Error('Missing env.REACT_APP_SUPABASE_ANON_KEY')
  return createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY,
    {
      db: {
        schema: 'public'
      },
      auth: {
        autoRefreshToken: true,
        persistSession: true,
        detectSessionInUrl: false
      }
    }
  )
}


// public_chat single instance
export const supabase = supabase_client()
// public single instance
export const supabase_public = supabase_public_client()


/**
 *
 * @returns supabase real time client
 */
const real_time_client = () => {
  if (!process.env.REACT_APP_SUPABASE_URL) throw new Error('Missing env.REACT_APP_SUPABASE_URL')
  if (!process.env.REACT_APP_SUPABASE_ANON_KEY) throw new Error('Missing env.REACT_APP_SUPABASE_ANON_KEY')
  const client = new RealtimeClient(process.env.REACT_APP_SUPABASE_URL, {
    params: {
      apikey: process.env.REACT_APP_SUPABASE_ANON_KEY
    }
  })
  return client
}
export const realTimeClient = real_time_client()



/**
 * axois instance
 */
class AxiosHelper {
  instance: any

  async getInstance() {
    const { data } = await supabase_public.functions.invoke(
      'external_jwt',
      { body: { external_url: window.location.origin } }
    );

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
      timeout: 1000,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${data}`
      }
    })
    this.instance = axiosInstance
  }
}

export const http = new AxiosHelper()
await http.getInstance()
