import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const voucherSchema = {
    title: 'voucherSchema',
    description: 'voucher',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        buyer: {
            type: 'object',
        },
        receiver: {
            type: 'object',
        },
        giftMethod: {
            type: 'string',
        },
        paymentAmount: {
            type: 'number',
        },
        points: {
            type: 'number',
        },
        pointsRatio: {
            type: 'object',
        },
        createdTime: {
            type: 'string',
            format: 'date-time',
        },
        expiryTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
        state: {
            type: 'string',
        }
    }
}

// select voucher
export const selectVoucher = async (filter, pageNumber, pageSize) => {
    const db = await Database.get();
    const query = {
        selector: {},
        sort: [{ createdTime: 'desc' }],
    };

    // Received, Send, Accepted, Rejected
    switch (filter) {
        case 'Received':
            query.selector['receiver._id'] = localGet('authAddress');
            break;
        case 'Sent':
            query.selector['buyer._id'] = localGet('authAddress');
            break;
        case 'Accepted':
            query.selector.$or = [
                { 'buyer._id': localGet('authAddress') },
                { 'receiver._id': localGet('authAddress') }
            ];
            query.selector.state = 'accepted';
            break;
        case 'Rejected':
            query.selector.$or = [
                { 'buyer._id': localGet('authAddress') },
                { 'receiver._id': localGet('authAddress') }
            ];
            query.selector.state = 'rejected';
            break;
        default:
            break;
    }

    const voucherData = await db.voucher.find(query).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();

    return JSON.parse(JSON.stringify(voucherData));
}