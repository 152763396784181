
import { IonItem, IonItemOption, IonItemOptions, IonItemSliding } from '@ionic/react';
import { FC } from 'react';
import { RoomInfo } from 'store/chatRoomSlice';
import { formatTimeAgo } from 'utils/dateFormate';
import avatarGroup from '../../../image/friends/avatarGroup.jpg';
import { BlockSVG } from '../svg';
interface ChannelItemProps {
    channelInfo: RoomInfo
    openBlockModal: () => void
    openChatModal: () => void
}
const ChannelItem: FC<ChannelItemProps> = ({ openBlockModal, openChatModal, channelInfo }) => {
    return (

        <IonItemSliding>
            <IonItem>
                <div className="channel" onClick={openChatModal}>
                    <div className="channel__avatar">
                        <img src={channelInfo.avatar || avatarGroup} alt='avatar' />
                    </div>
                    <div className="channel__main">
                        <div className="channel__head">
                            <span className="channel__head--name">{channelInfo.name}</span>
                            <span className="channel__head--time">{channelInfo.message_time && formatTimeAgo(new Date(channelInfo.message_time))}</span>
                        </div>
                        <div className="channel__bottom">
                            <p>{channelInfo.latest_message}</p>
                            {channelInfo.new_message_count > 0 && <span>{channelInfo.new_message_count}</span>}
                        </div>
                    </div>
                </div>
            </IonItem>
            <IonItemOptions>
                <IonItemOption color="danger" onClick={openBlockModal}>
                    <div className='channel-block'>
                        <BlockSVG />
                        <span>Block</span>
                    </div>
                </IonItemOption>
            </IonItemOptions>
        </IonItemSliding>


    )
}

export default ChannelItem