import { IonContent, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import movie from '../image//billion//pic-re.png';
import img3 from '../image//billion/Option=Icon0.png';
import img2 from '../image//billion/Option=Icon04.png';
import img4 from '../image//billion/Option=Icon041.png';
import dice from '../image/upcoming/dice.gif';
import billstyle from "../style/Billion.module.css";
import style from "../style/Upcoming.module.css";

import { useEffect, useState } from "react";
import { localGet, localSet } from "../utils/localStorage";
import { Countup } from 'count-up-react'
import { filterPromotedDraws } from "../db/schema/draws";
import { formatPrize } from "../utils/formatPrize";
import { showToastMessage } from "../components/GlobalLoading";
import { useNetworkStatus, useUser } from "hooks";
import { useStore } from "../store"
import SVGIcon from "components/SvgComponent";

const Billion: React.FC = () => {
  const { changeModalState } = useStore();

  const addCommas = (num: any) => num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  const [data, setData] = useState<any>([]);
  const isOnline = useNetworkStatus()
  const { logout } = useUser()
  // const { loginWithRedirect } = useAuth0()


  const asyncFunc = async (props: any) => {
    if (props.draws !== null && props.draws?.length > 0) {
      const res = await filterPromotedDraws();
      setData(res);
    }

    if (props.tickets !== null && props.tickets?.length > 0) {
    }
  }
  async function getPromted() {
    const res = await filterPromotedDraws();
    setData(res);
    PubSub.subscribe('syncData', function (msg: any, searchName: any) {
      asyncFunc(searchName)
    })
  }

  useEffect(() => {
    getPromted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // useEffect(() => {
  //   if (data?.winnerWallet !== "") {
  //     setTimeout(() => {
  //       getPromted()
  //     }, 5000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data])

  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={billstyle.billion}>
          <div className={billstyle.main}>
            <div className={billstyle.movie}>
              <img src={movie} alt="" />
              <span className={billstyle.moviespan1}>${data?.prize ? formatPrize(data?.prize) : 0}</span>
            </div>
          </div>

          <div className={billstyle.bottom}>
            <div className={billstyle.texsbox}>
              <div className={billstyle.texstop}>
                Estimated draw takes place in about 3 days.
              </div>
              <div className={billstyle.progrebox}>

                <div style={{
                  width: `${data?.ticketsSold / data?.maxTickets * 100}%` || 0
                }}
                  className={data?.isJoined ? style.joined : style.progress}
                >

                </div>
              </div>
              {data?.winnerWallet !== "" && data?.winnerNickname !== "" ?
                <div className={billstyle.textbottom}>
                  <span>{data?.winnerNickname} won this draw!</span>
                </div>
                :
                <div className={billstyle.textbottom}>
                  <div className={billstyle.texthead}>
                    <img src={img2} alt="" />
                    <img src={img3} alt="" />
                    <img src={img4} alt="" />
                  </div>
                  <span> & </span>
                  <Countup
                    number={data?.ticketsSold ? data?.ticketsSold : 0}
                    scrollTime={2000}
                    className={style.countUp}
                  // className={style.coutnum}
                  />
                  <span>Joined</span>
                </div>
              }
            </div>
            <div className={billstyle.carsLine}>
              <div className={billstyle.carsAnd}>
                <span>Sponsored by</span>
                <img src={require('../image/billion/ferrari-vector-logo 1.png')} alt="" />
                <img src={require('../image/billion/lamborghini-vector-logo 1.png')} alt="" />
                <img src={require('../image/billion/rolls-royce-vector-logo 1.png')} alt="" />
                <img src={require('../image/billion/pilatus-vector-logo 1.png')} alt="" />
              </div>
              <div className={billstyle.howit}>How it works?</div>
            </div>
            <ul className={billstyle.cards}>
              <li>
                {/* <div className={billstyle.popular} style={{ background: '#FFFFFF' }}>{ticketCount}</div> */}
                <div className={billstyle.mains} style={data?.isJoined ? { border: '1px solid #42E0A7' } : {}}>
                  <span>1 Ticket</span>
                  <p>${addCommas(data?.ticketPrice) || 0}</p>
                  {data?.winnerWallet !== "" ?
                    <div className={billstyle.dice}>
                      <img style={{ borderRadius: '50%' }} src={
                        // `${data?.winnerAvatarURL}?${new Date().getTime()}`
                        data?.winnerAvatarURL
                      } alt="" />
                    </div>
                    : data?.winnerWallet === "" && data?.ticketsSold === data?.maxTickets ?
                      <div className={billstyle.dice}>
                        <img src={dice} alt="" />
                      </div>
                      : <div className={billstyle.joined}
                        style={data?.isJoined && data?.ticketCount >= data?.maxTicketsPerUser ? {} : { display: 'none' }}>
                          <SVGIcon name="BillionJoinSuccessIcon"  width="25" height="24" viewBox="0 0 25 24" />
                        Joined
                      </div>}
                </div>

                {/* data?.isJoined ||  */}
                <div className={billstyle.tojoin} style={data?.ticketCount >= data?.maxTicketsPerUser || data?.winnerWallet !== "" || (data.winnerWallet === "" && data.ticketsSold === data.maxTickets) ? { display: 'none' } : {}} onClick={async () => {
                  if (localGet('auth0_user')) {
                    localSet('click_Item', data)
                    changeModalState('isClickOpenModal', true)
                  } else {
                    if (!isOnline) {
                      showToastMessage('Please log in your account first.')
                      return
                    }
                    logout()
                  }
                }}>
                  <SVGIcon name="BillionAddIcon" width="44" height="44" viewBox="0 0 44 44" style={data?.maxTicketsPerUser - data?.ticketCount < 5 ? { fill: 'gray' } : { fill:'#9C94FF' }} />
                </div>

              </li>
              <li>
                <div className={billstyle.popular}>Popular</div>
                <div className={billstyle.mains} style={data?.isJoined && data?.ticketCount >= 5 ? { border: '1px solid #42E0A7' } : {}}>
                  <span>5 More Tickets</span>
                  <p>${addCommas((data?.ticketPrice || 0) * 5)}</p>
                  {data?.winnerWallet !== "" ?
                    <div className={billstyle.dice}>
                      <img style={{ borderRadius: '50%' }} src={
                        // `${data?.winnerAvatarURL}?${new Date().getTime()}`
                        data?.winnerAvatarURL
                      } alt="" />
                    </div>
                    : data?.winnerWallet === "" && data?.ticketsSold === data?.maxTickets ?
                      <div className={billstyle.dice}>
                        <img src={dice} alt="" />
                      </div>
                      : <div className={billstyle.joined}
                        style={data?.isJoined && data?.ticketCount >= data?.maxTicketsPerUser ? {} : { display: 'none' }}>
                        <SVGIcon name="BillionJoinSuccessIcon"  width="25" height="24" viewBox="0 0 25 24" fill="#9C94FF"/>
                        Joined
                      </div>}
                </div>
                {/* data?.isJoined ||  */}
                <div className={billstyle.tojoin} style={data?.ticketCount === data?.maxTicketsPerUser || data?.winnerWallet !== "" || (data.winnerWallet === "" && data.ticketsSold === data.maxTickets) ? { display: 'none' } : {}}
                  onClick={async () => {
                    if (data?.maxTicketsPerUser - data?.ticketCount < 5) {
                      return
                    }
                    if (localGet('auth0_user')) {
                      localSet('click_Item', data)
                      changeModalState('isClickOpenModal', true)
                      localSet('ticketAmount', 5)
                    } else {
                      if (!isOnline) {
                        showToastMessage('Please log in your account first.')
                        return
                      }
                      logout()
                    }
                  }}
                >
                  <SVGIcon name="BillionAddIcon"  width="44" height="44" viewBox="0 0 44 44" fill={data?.maxTicketsPerUser - data?.ticketCount < 5 ? 'gray' : '#9C94FF'}/>
                  {/* <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="44" height="44" rx="22" fill="#9C94FF" style={data?.maxTicketsPerUser - data?.ticketCount < 5 ? { fill: 'gray' } : {}} />
                    <path d="M29 23H23V29H21V23H15V21H21V15H23V21H29V23Z" fill="black" />
                  </svg> */}
                </div>
              </li>
              <li>
                <div className={billstyle.exchange}>10x Chance</div>
                <div className={billstyle.mains} style={data?.isJoined && data?.ticketCount >= 10 ? { border: '1px solid #42E0A7' } : {}}>
                  <span>10 More Tickets</span>
                  <p>${addCommas((data?.ticketPrice || 0) * 10)}</p>
                  {data?.winnerWallet !== "" ?
                    <div className={billstyle.dice}>
                      <img style={{ borderRadius: '50%' }} src={
                        // `${data?.winnerAvatarURL}?${new Date().getTime()}`
                        data?.winnerAvatarURL
                      } alt="" />
                    </div>
                    : data?.winnerWallet === "" && data?.ticketsSold === data?.maxTickets ?
                      <div className={billstyle.dice}>
                        <img src={dice} alt="" />
                      </div>
                      : <div className={billstyle.joined}
                        style={data?.isJoined && data?.ticketCount >= data?.maxTicketsPerUser ? {} : { display: 'none' }}>
                        <SVGIcon name="BillionJoinSuccessIcon"  width="25" height="24" viewBox="0 0 25 24" />
                        Joined
                      </div>}
                  {/* <div>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM12.5 20C8.09 20 4.5 16.41 4.5 12C4.5 7.59 8.09 4 12.5 4C16.91 4 20.5 7.59 20.5 12C20.5 16.41 16.91 20 12.5 20ZM17.09 7.58L10.5 14.17L7.91 11.59L6.5 13L10.5 17L18.5 9L17.09 7.58Z" fill="#42E05C" />
                    </svg>
                  </div> */}
                </div>
                {/* data?.isJoined ||  */}
                <div className={billstyle.tojoin} style={data?.ticketCount === data?.maxTicketsPerUser || data?.winnerWallet !== "" || (data.winnerWallet === "" && data.ticketsSold === data.maxTickets) ? { display: 'none' } : {}}
                  onClick={async () => {

                    if (data?.maxTicketsPerUser - data?.ticketCount < 10) {
                      return
                    }
                    if (localGet('auth0_user')) {
                      localSet('click_Item', data)
                      localSet('ticketAmount', 10)
                      changeModalState('isClickOpenModal', true)
                    } else {
                      if (!isOnline) {
                        showToastMessage('Please log in your account first.')
                        return
                      }
                      logout()

                    }
                  }}
                >
                  <SVGIcon name="BillionAddIcon"  width="44" height="44" viewBox="0 0 44 44" fill={data?.maxTicketsPerUser - data?.ticketCount < 10 ? 'gray' : '#9C94FF'}/>
                  {/* <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="44" height="44" rx="22" fill="#9C94FF" style={data?.maxTicketsPerUser - data?.ticketCount < 10 ? { fill: 'gray' } : {}} />
                    <path d="M29 23H23V29H21V23H15V21H21V15H23V21H29V23Z" fill="black" />
                  </svg> */}
                </div>
              </li>
            </ul>
          </div>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default Billion;
