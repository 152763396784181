import { IonContent, IonModal } from '@ionic/react';
import { signInWithOAuth, signUpWithEmail, signUpWithEmailSchema } from 'api/auth';
import { setShowLoading, showToastMessage } from 'components/GlobalLoading';
import Button from 'components/common/Button';
import { FC, SetStateAction, useState } from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { useStore } from '../../store';
import { CloseModalSVG, EthereumSVG, FacebookSVG, GoogleSVG, PasswordErrorSVG, PasswordHideSVG, PasswordShowSVG, PasswordSuccessSVG, TwitchSVG } from './svg';
enum PasswordRules {
    MINLENGTH = 0,
    CONTAIN = 1,
    START = 2
}
const passwordRules = [{
    label: 'At least 6 characters', rule: PasswordRules.MINLENGTH
}, {
    label: 'Only contain letters,numbers or !@#?', rule: PasswordRules.CONTAIN
}, {
    label: 'Start with letters', rule: PasswordRules.START
}]

const SignUpModal: FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm<signUpWithEmailSchema>();
    // const [present] = useIonToast();
    const [errorState, setErrorState] = useState<PasswordRules[]>([])
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
    const { isSignUpModalOpen , changeModalState , closeAllModalStates } = useStore()
    const onSwitchPasswordShow = () => {
        setIsShowPassword(isShow => !isShow)
    }

    const handleSignInWithOauth = async (provider: any) => {
        try {
            const signInResult = await signInWithOAuth(provider);
            console.log(signInResult);
        } catch (error) {
            console.log(error)
        }

    }
    const clearForm = () => {
        // TODO:
    }
    const fieldRequirements: Record<keyof signUpWithEmailSchema, RegisterOptions> = {
        userName: {
            required: {
                value: true,
                message: 'Please enter your Username.'
            },

        },
        password: {
            required: {
                value: true,
                message: 'Please enter your Password.'
            },
            validate: (value: string) => {
                const pattern = /^[a-zA-Z0-9!@#?]+$/;
                const errors: SetStateAction<PasswordRules[]> = []
                if (value.length < 6) {
                    errors.push(PasswordRules.MINLENGTH)
                }
                if (!pattern.test(value)) {
                    errors.push(PasswordRules.CONTAIN)
                }
                if (!/^[a-zA-Z]/.test(value.charAt(0))) {
                    errors.push(PasswordRules.START)
                }
                setErrorState(errors)
                if (errors.length > 0) {
                    const rule = passwordRules.find(_ => _.rule === errors[0])
                    showToastMessage(rule?.label ?? '')
                    // present({
                    //     message: rule?.label,
                    //     duration: 1500,
                    //     position: 'top',
                    // });
                    return false
                } else {
                    return true
                }

            }
        },
        email: {
            required: {
                value: true,
                message: 'Please enter your Email.'
            },
        }
    }
    const onSubmit = async (form: signUpWithEmailSchema) => {
        let res
        setShowLoading(true)
        try {
            res = await signUpWithEmail(form)
            if (res.status === 200) {
                console.log(res)
                closeAllModalStates(false)
                changeModalState('isSignInModalOpen',true)
                reset()
            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setShowLoading(false)
            showToastMessage(res?.msg ?? 'Register Successfully')
            // present({
            //     message: res?.msg ?? 'Register Successfully',
            //     duration: 1500,
            //     position: 'top',
            // });
        }
    }
    const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const pattern = /^[a-zA-Z0-9!@#?]+$/;
        const _errors = []
        if (value.length < 6) {
            _errors.push(PasswordRules.MINLENGTH)
        }
        if (!pattern.test(value)) {
            _errors.push(PasswordRules.CONTAIN)
        }
        if (!/^[a-zA-Z]/.test(value.charAt(0))) {
            _errors.push(PasswordRules.START)
        }
        setErrorState(_errors)
    }
    const isPasswordFocus = document.activeElement === document.querySelector('#sign-up-password')
    return (
        <IonModal className='sign-up' isOpen={isSignUpModalOpen} onWillDismiss={clearForm}>
            <IonContent>
                <div className='sign-up-modal'>
                    <div className='sign-up-modal__header'>
                        <span>Sign Up</span>
                        <CloseModalSVG onClick={()=>changeModalState('isSignUpModalOpen',false)} />
                    </div>
                    <div className='sign-up-modal__content'>
                        <form className='auth-form' onSubmit={handleSubmit(onSubmit)}>
                            <div className="auth-form-group">
                                <div className="auth-form-group-control">
                                    <label>Username</label>
                                    <input {...register('userName', fieldRequirements.userName)} />
                                </div>
                                <p>{errors.userName?.message}</p>
                            </div>
                            <div className="auth-form-group">
                                <div className="auth-form-group-control">
                                    <label>Email Address</label>
                                    <input  {...register('email', fieldRequirements.email)} type='email' />
                                </div>
                                <p>{errors.email?.message}</p>
                            </div>
                            <div className="auth-form-group">
                                <div className="auth-form-group-control">
                                    <label>Password</label>
                                    <input  {...register('password', fieldRequirements.password)} onChange={onPasswordChange} className='form-password' type={isShowPassword ? 'text' : 'password'} id='sign-up-password' />
                                    {isShowPassword ? <PasswordShowSVG onClick={onSwitchPasswordShow} /> : <PasswordHideSVG onClick={onSwitchPasswordShow} />}
                                </div>
                                <ul className={`password-rules ${errorState.length > 0 && isPasswordFocus ? 'show' : ''}`}>
                                    {
                                        passwordRules.map(passwordRule => <li key={passwordRule.rule}>
                                            {errorState.includes(passwordRule.rule) ? <PasswordErrorSVG /> : <PasswordSuccessSVG />}
                                            <span>{passwordRule.label}</span>
                                        </li>)
                                    }
                                </ul>
                            </div>
                            <p className='sign-up-modal__tips'>By clicking Sign Up, you agree to the <span>Terms & Conditions</span>  and <span>Private Policy</span> </p>
                            <Button type='submit'>Sign Up with Email</Button>
                        </form>
                        <div className='sign-up-modal__content--or'>
                            <span>OR</span>
                        </div>
                        <ul className='sign-up-modal__content--social '>
                            <li>
                                <Button onClick={() => handleSignInWithOauth('google')}><GoogleSVG /> Sign Up with Google</Button>
                            </li>
                            <li>
                                <Button onClick={() => handleSignInWithOauth('facebook')}><FacebookSVG /> Sign Up with Facebook</Button>
                            </li>
                            <li>
                                <Button onClick={() => handleSignInWithOauth('twitch')}><TwitchSVG /> Sign Up with Twitch</Button>
                            </li>
                            <li>
                                <Button><EthereumSVG /> Sign Up with Ethereum</Button>
                            </li>
                        </ul>
                        <p className='sign-in-modal__content--link'>Already have an account?<span onClick={
                            ()=>{closeAllModalStates(false);changeModalState('isSignInModalOpen',true)
                            }}>Sign In</span></p>
                    </div>
                </div>
            </IonContent>


        </IonModal>
    )
}

export default SignUpModal
