import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonModal } from "@ionic/react";
import { User } from "@supabase/supabase-js";
import { handleInviterAccepted, handleInviterRejected } from "api/chat";
import { selectInvites } from "db/schema/invites";
import { useLocalStorage } from "hooks";
import { useEffect, useState } from "react";
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import avatarGroup from '../../../image/friends/avatarGroup.jpg';
import back from "../../../image/upcoming/ill.png";
import { useStore } from "../../../store";
import style from "../../../style/Friends.module.css";
import errorstyle from "../../../style/Upcoming.module.css";
import { formatDate } from "../../../utils/formatDate";
interface Props {
    getInviteLength: any
}
const InviteLIst: React.FC<Props> = ({ getInviteLength }) => {
    const [userInfo] = useLocalStorage<User | null>('user_info')
    const { isInviteListModalOpen, changeModalState } = useStore();
    const [page, setPage] = useState(1)
    const [inviteList, setInviteList] = useState<any>([])
    const initInviteList = async (pagenum = 1, pageSize = 10, init = false) => {
        let preData: any
        if (init) {
            preData = []
        } else {
            preData = [...inviteList]
        }
        const invitelistData = await selectInvites(pagenum, pageSize)
        if (invitelistData.length > 0) {
            preData.push(...invitelistData)
            setInviteList(preData);
        }
    }
    useEffect(() => {
        PubSub.subscribe('initInviteList', async (msg: any, data: any) => {
            await initInviteList(1, 10, true)
            await setPage(1)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAccept = async (i: any) => {
        setShowLoading(true)
        try {
            await handleInviterAccepted(i.supabaseId, !!i.groupName)
            await initInviteList(1, 10, true)
            await setPage(1)
            await getInviteLength()
            showToastMessage('The invitation was accepted successfully.')

        } catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            setShowLoading(false)
            setTimeout(()=>{
                initInviteList(1, 10, true)
            },100)
        }
    }

    const handleReject = async (i: any) => {
        setShowLoading(true)
        try {
            await handleInviterRejected(i.supabaseId, !!i.groupName)
            await initInviteList(1, 10, true)
            await getInviteLength()
            await setPage(1)
            showToastMessage('The invitation was refused successfully.')
        } catch (error: any) {
            showToastMessage(error.message)
        } finally {
            setShowLoading(false)
            setTimeout(()=>{
                initInviteList(1, 10, true)
            },100)
        }
    }

    return (
        <>
            <IonModal
                isOpen={isInviteListModalOpen}
                mode="ios"
                className={style.inviteListModal}
                onDidDismiss={() => {
                    setInviteList([])
                    setPage(1)
                }}
                onWillPresent={() => {
                    initInviteList(1, 10, true)
                    setPage(1)
                }}
            >
                <IonContent>
                    <div className={style.inviteList}>
                        <div className={style.tit}>
                            <span onClick={() => changeModalState('isInviteListModalOpen', false)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                                </svg>
                            </span>
                            <div>
                                <span>Invitation List</span>
                            </div>
                            <span></span>
                        </div>
                        {
                            inviteList?.length > 0 ?
                                <>
                                    <ul className={style.lists}>
                                        {inviteList?.map((i: any, index: any) => (
                                            <li key={index}
                                                style={i?.inviteMethod === 'share' &&
                                                    i?.invitees?.avatar === '' &&
                                                    i?.invitees?._id === '' &&
                                                    i?.invitees?.email === '' &&
                                                    i?.invitees?.nickname === '' &&
                                                    i?.invitees?.phone === '' ?
                                                    { display: 'none' } : {}
                                                }>
                                                <div className={style.top}>
                                                    {i?.inviteMethod === "wallet" && (
                                                        i?.groupName?.length > 0 ?
                                                            <img src={i?.groupAvatar?.length > 0 ? i?.groupAvatar : avatarGroup} alt="" />
                                                            :
                                                            <img src={i?.invitees?._id === userInfo?.user_metadata?.user_wallet ? i?.initiator?.avatarURL : i?.invitees?.avatarURL} alt="" />
                                                    )
                                                    }
                                                    <div>
                                                        {i.inviteMethod === "email" ? <span>{i?.invitees?.email}</span>
                                                            : i.inviteMethod === "wallet" ? (
                                                                i?.groupName?.length > 0 ? <span>{i?.groupName}</span>
                                                                    : <span>{i?.invitees?._id === userInfo?.user_metadata?.user_wallet ? i?.initiator?.nickname : i?.invitees?.nickname}</span>
                                                            ) : i.inviteMethod === "phone" ? <span>{i?.invitees?.phone}</span>
                                                                : <span></span>}
                                                        <p>{
                                                            i?.groupName?.length > 0 ?
                                                                'Request to join group chat'
                                                                : 'Request to add friend'
                                                        }</p>
                                                    </div>
                                                </div>
                                                <div className={style.bottom}>
                                                    <i>{formatDate(new Date(i?.invitationTime))}</i>
                                                    <div>
                                                        {
                                                            i?.inviteMethod === 'wallet' &&
                                                            i?.state === 'pending' &&
                                                            (i?.invitees?._id === userInfo?.user_metadata?.user_wallet ?
                                                                <>
                                                                    <span
                                                                        style={{
                                                                            border: '1px solid #9C94FF',
                                                                            color: '#9C94FF',
                                                                            marginRight: '10px'
                                                                        }}
                                                                        onClick={() => handleAccept(i)}
                                                                    >Accept
                                                                    </span>
                                                                    <span
                                                                        style={{
                                                                            background: '#9C94FF',
                                                                            color: '#2D323E'
                                                                        }}
                                                                        onClick={() => handleReject(i)}
                                                                    >Reject
                                                                    </span>
                                                                </>
                                                                :
                                                                <span
                                                                    style={{
                                                                        background: 'rgba(66,224,167,0.1)',
                                                                        color: '#42E0A7'
                                                                    }}
                                                                >
                                                                    Pending
                                                                </span>)
                                                        }
                                                        {
                                                            i?.inviteMethod !== 'wallet' && i?.state === 'pending' &&
                                                            <span
                                                                style={{
                                                                    background: 'rgba(66,224,167,0.1)',
                                                                    color: '#42E0A7'
                                                                }}
                                                            >
                                                                Pending
                                                            </span>
                                                        }
                                                        {i?.state === 'accepted' &&
                                                            <span
                                                                style={{
                                                                    background: "#2D323E",
                                                                    color: '#fff'
                                                                }}
                                                            >
                                                                Accepted
                                                            </span>
                                                        }
                                                        {i?.state === 'rejected' &&
                                                            <span
                                                                style={{
                                                                    color: '#AA6060',
                                                                    background: 'rgba(170, 96, 96, 0.10)'
                                                                }}>
                                                                Rejected
                                                            </span>}
                                                    </div>
                                                </div>
                                                {
                                                    i?.initiator?._id === userInfo?.user_metadata?.user_wallet &&
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                                        <path d="M2.76362 1.13361H9.26006C9.81235 1.13361 10.2601 1.58132 10.2601 2.13361V8.41243M6.46105 4.7792L1.74023 9.50001" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
                                                    </svg>
                                                }
                                            </li>
                                        ))
                                        }
                                    </ul>
                                    <IonInfiniteScroll
                                        onIonInfinite={(ev) => {
                                            initInviteList(page + 1);
                                            setPage(page + 1);
                                            setTimeout(() => ev.target.complete(), 500);
                                        }}
                                    >
                                        <IonInfiniteScrollContent ></IonInfiniteScrollContent>
                                    </IonInfiniteScroll>
                                </>
                                :
                                <div className={errorstyle.cartex}>
                                    <img src={back} alt="" />
                                    <p style={{ width: '100%', textAlign: 'center' }}>No Invitation Information</p>
                                </div>
                        }
                    </div>
                </IonContent>
            </IonModal >
        </>
    )
}
export default InviteLIst