import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const pointsSchema = {
    title: 'pointsSchema',
    description: 'points',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        voucherId: {
            type: 'string',
        },
        user: {
            type: 'string',
            maxLength: 100,
        },
        points: {
            type: 'number',
        },
        transactionType: {
            type: 'string',
        },
        transactionDetail: {
            type: 'string',
        },
        createdTime: {
            type: 'string',
            format: 'date-time',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        }
    },
    indexes: [
        'user'
    ]
}

// select points
export const selectPoints = async (sort, pageNumber, pageSize) => {
    const db = await Database.get();

    const pointsData = await db.balance_history.find({
        selector: {
            user: localGet('authAddress'),
            payment: 'point'
        },
        sort: [{ date: sort }],
    }).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();

    return JSON.parse(JSON.stringify(pointsData));
}