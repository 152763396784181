

import { IonContent, IonModal } from "@ionic/react";
import { selectUser } from "db/schema/user";
import { FC, useState } from "react";
import { tokenApi } from "../../../api";
import { selectRatiosByVoucher } from "../../../db/schema/ratios";
import { useStore } from "../../../store";
import { localGet } from "../../../utils/localStorage";
import { setShowLoading, showToastMessage } from "../../GlobalLoading";
import PinCode from "../../PinCode";
import style from "./index.module.css";
interface GiftVoucherDetailProps {

}

const GiftVoucherDetail: FC<GiftVoucherDetailProps> = () => {
    const { inFriends, changeModalState,isGiftOptionsModalOpen } = useStore()
    const [amountType, setAmountType] = useState(0)
    const [amount, setAmount] = useState<any>('')
    const [outdoNum, setOutDoNum] = useState(false)


    const [openPinCode, setOpenPinCode] = useState(false)
    const closePinCode = () => {
        setOpenPinCode(false)
    }
    const buyFunc = async () => {
        setShowLoading(true)
        const receiverObj = {
            wallet: localGet('giftTo')?.user,
            email: localGet('giftTo')?.email,
            phone: localGet('giftTo')?.phone
        }
        const data = {
            "invitationGift": localGet('giftTo')?.from === 'friends' ? false : true,
            "inviteString": receiverObj[localGet('giftTo')?.method as 'wallet' | 'email' | 'phone'],
            "giftMethod": localGet('giftTo')?.method,
            "paymentAmount": amountType === 0 ? amount : amountType
        }
        await tokenApi('/giftAVoucher', data).then(res => {
            if (res.code === 200) {
                setShowLoading(false)
                setOpenPinCode(false)
                changeModalState('isGiftResultModalOpen', true);
                showToastMessage('Gift voucher successfully.');
                onCloseModal()
            } else {
                setShowLoading(false)
                setOpenPinCode(false)
                showToastMessage(res.detail)
            }
            setShowLoading(false)
        })
    }
    const [ratio, setRatio] = useState(0)
    const handleSub = async () => {
        if (outdoNum) {
            showToastMessage('The maximum amount is $100.')
            return
        }
        let localMoney: any
        await selectUser().then((res) => {
            localMoney = res?.money || 0
        })
        if (Number(localMoney) < Number(amountType === 0 ? amount : amountType)) {
            showToastMessage('Player does not have enough money to gift a voucher.')
            return
        }
        setOpenPinCode(true);

    }
    const onCloseModal = () => {
        changeModalState('isGiftOptionsModalOpen', false)
    }
    const onDidDismiss = () => {
        closePinCode()
        setOutDoNum(false)
        changeModalState('isGiftOptionsModalOpen', false)
    }
    return (
        <>
            <IonModal
                isOpen={isGiftOptionsModalOpen}
                onWillPresent={async () => {
                    setAmount('')
                    setOutDoNum(false)
                    setAmountType(0)
                    const res = await selectRatiosByVoucher('voucher')
                    setRatio(res.ratio);
                }}
                onDidDismiss={onDidDismiss}
                className={inFriends ? style.giftOptionsDetailInFriends : ''}>
                <IonContent>
                    <div className={style.giftOption}
                    // style={context.giftVocher.inFriends ? {} : { minHeight: 'calc(100vh - 83px)' }}
                    >
                        <div className={style.tit}>
                            <span onClick={onCloseModal}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
                                </svg>
                            </span>
                            <div>
                                <span>Gift a Voucher</span>
                            </div>
                            <span style={{ width: '56px' }} ></span>
                        </div>
                        <div className={style.main}>
                            <div className={style.topMain}>
                                <ul className={style.options}>
                                    <li onClick={() => {
                                        setAmount('')
                                        setOutDoNum(false)
                                        setAmountType(10)
                                    }}
                                        className={amountType === 10 ? style.act : ""}
                                    >
                                        <div>
                                            <span>$</span>10
                                        </div>
                                        <p>Voucher</p>
                                    </li>
                                    <li onClick={() => {
                                        setAmount('')
                                        setOutDoNum(false)
                                        setAmountType(20)
                                    }}
                                        className={amountType === 20 ? style.act : ""}
                                    >
                                        <div>
                                            <span>$</span>20
                                        </div>
                                        <p>Voucher</p>
                                    </li>
                                    <li onClick={() => {
                                        setAmount('')
                                        setOutDoNum(false)
                                        setAmountType(50)
                                    }}
                                        className={amountType === 50 ? style.act : ""}
                                    >
                                        <div>
                                            <span>$</span>50
                                        </div>
                                        <p>Voucher</p>
                                    </li>
                                </ul>
                                <ul className={style.options}>
                                    <li onClick={() => {
                                        setAmount('')
                                        setOutDoNum(false)
                                        setAmountType(60)
                                    }}
                                        className={amountType === 60 ? style.act : ""}
                                    >
                                        <div>
                                            <span>$</span>60
                                        </div>
                                        <p>Voucher</p>
                                    </li>
                                    <li onClick={() => {
                                        setAmount('')
                                        setOutDoNum(false)
                                        setAmountType(80)
                                    }}
                                        className={amountType === 80 ? style.act : ""}
                                    >
                                        <div>
                                            <span>$</span>80
                                        </div>
                                        <p>Voucher</p>
                                    </li>
                                    <li onClick={() => {
                                        setAmount('')
                                        setOutDoNum(false)
                                        setAmountType(100)
                                    }}
                                        className={amountType === 100 ? style.act : ""}
                                    >
                                        <div>
                                            <span>$</span>100
                                        </div>
                                        <p>Voucher</p>
                                    </li>
                                </ul>
                                <div className={style.amount}>
                                    <input type="number" placeholder="Enter custom amount"
                                        inputMode="numeric" pattern="[0-9]*"
                                        value={amount}
                                        onKeyDown={(e: any) => {
                                            const { key } = e;
                                            const filteredKeys = new Set(["e", "-", "+", ".", "~"]);
                                            if (filteredKeys.has(key)) {
                                                e.preventDefault();
                                            }
                                            if (e.key === 'Enter') {
                                                handleSub()
                                            }
                                        }}
                                        onChange={(e) => {
                                            if (amountType !== 0) {
                                                setAmountType(0)
                                            }
                                            const input: any = e.target.value.replace(/^0+/, '').replace(/[^\d]/g, '')
                                            if (Number(input) > 100) {
                                                setOutDoNum(true)
                                            } else {
                                                setOutDoNum(false)
                                            }
                                            setAmount(input)
                                        }}
                                        style={{ borderColor: outdoNum ? '#FF6881' : '#2e323e' }}
                                    />
                                    <div className={style.err} style={{ display: outdoNum ? 'block' : 'none' }}>
                                        The maximum amount is $100.
                                    </div>
                                </div>
                                <div className={style.points}>
                                    <div>
                                        <p>The receiver will get gems to spend on any draws.</p>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <g clipPath="url(#clip0_2092_4727)">
                                                    <mask id="mask0_2092_4727" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                                                        <path d="M18 0H0V18H18V0Z" fill="white" />
                                                    </mask>
                                                    <g mask="url(#mask0_2092_4727)">
                                                        <path d="M3.72178 3H10.4201C10.4942 2.99998 10.5673 3.02214 10.6334 3.06466C10.6995 3.10719 10.7567 3.16886 10.8003 3.24465L12.5967 6.36582C12.6287 6.42135 12.6444 6.48915 12.6413 6.55769C12.6381 6.62622 12.6162 6.69126 12.5794 6.7417L7.24339 14.0465C7.2214 14.0765 7.19472 14.1005 7.16504 14.1168C7.13536 14.1332 7.10332 14.1416 7.07093 14.1416C7.03854 14.1416 7.0065 14.1332 6.97682 14.1168C6.94714 14.1005 6.92047 14.0765 6.89846 14.0465L1.56247 6.7423C1.52551 6.69181 1.50357 6.62666 1.5004 6.55799C1.49723 6.48933 1.51302 6.42141 1.54508 6.36582L3.34161 3.24465C3.3852 3.16886 3.44237 3.10719 3.50846 3.06466C3.57453 3.02214 3.64763 2.99998 3.72178 3Z" fill="#42E0A7" />
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.16797 13.1492L10.9861 15.6379C11.0081 15.6679 11.0348 15.692 11.0645 15.7083C11.0941 15.7248 11.1262 15.7332 11.1586 15.7332C11.191 15.7332 11.223 15.7248 11.2527 15.7083C11.2824 15.692 11.309 15.6679 11.3311 15.6379L16.6671 8.33327C16.7038 8.28281 16.7258 8.21779 16.7289 8.14925C16.7321 8.08072 16.7163 8.01291 16.6844 7.95737L14.8879 4.83621C14.8443 4.76043 14.7871 4.69874 14.721 4.65622C14.655 4.6137 14.5819 4.59154 14.5077 4.59155H12.8449L13.866 6.36571C13.898 6.42124 13.9137 6.48905 13.9106 6.55758C13.9073 6.62612 13.8855 6.69114 13.8487 6.7416L9.16797 13.1492Z" fill="#42E0A7" />
                                                    </g>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2092_4727">
                                                        <rect width="18" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span>{amountType !== 0 ? amountType * ratio : amount.length > 0 ? amount * ratio : 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.learnMore}>
                                    <p>
                                        The voucher will be expired and refunded if not being activated in 10 days.
                                        <span>
                                            Learn More
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className={style.payBtn}
                            /*                                 style={context.giftVocher.inFriends ?
                                                                { paddingBottom: "45px" } :
                                                                { paddingBottom: "22px" }} */
                            >
                                <div onClick={handleSub}
                                >
                                    <p>Pay</p>
                                    <span>${amountType !== 0 ? amountType : amount.length > 0 ? amount : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonModal>
            {openPinCode && <PinCode
                openAuth0Modal={openPinCode}
                setOpenAuth0Modal={closePinCode}
                buyFunc={buyFunc}
            // afterBuyCloseModal={setOpenPinCode}
            />}
        </>
    )
}
export default GiftVoucherDetail