import * as Database from "../";
import {localGet} from "../../utils/localStorage";

export const faceIdSchema = {
    title: 'faceIdSchema',
    description: 'face id',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        faceId: {
            type: 'string',
        },
        challenge: {
            type: 'string',
        }
    }
}

// select face id
export const selectFaceId = async () => {
    const db = await Database.get();

    return await db.face_id.findOne({
        selector: {_id: localGet('authAddress')}
    }).exec();
}

// save face id
export const saveFaceId = async (faceId, challenge) => {
    const db = await Database.get();

    await db.face_id.upsert({
        _id: localGet('authAddress'),
        faceId: faceId,
        challenge: challenge,
    });
}

// delete face id
export const deleteFaceId = async () => {
    const db = await Database.get();

    return await db.face_id.findOne({
        selector: {_id: localGet('authAddress')}
    }).exec().then((doc) => {
        try {
            doc.remove();
        } catch (e) {
            console.log(e);
        }
    });
}