import style from './index.module.css';
import { useEffect, useState } from 'react';
import { tokenApi } from '../../api';
import { setShowLoading, showToastMessage } from '../GlobalLoading';
// import { selectNotificationByObjectId } from '../../db/schema/notification';
const GiftPointsModal = () => {

    const [show, setShow] = useState(false);
    const [infos, setInfos] = useState<any>(null)

    useEffect(() => {
        PubSub.subscribe('syncData', async (msg, searchName) => {
            if (!searchName.vouchers) { } else {
                setInfos(searchName.vouchers[0])
                setShow(true)
                // const doc = await selectNotificationByObjectId(searchName.vouchers[0]._id)
                // await tokenApi('/readNotification', {
                //     read_id: doc[0]?._id
                // })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAccept = async () => {
        setShowLoading(true)
        tokenApi('/acceptVoucher', {
            "voucherId": infos?._id
        }).then(res => {
            if (res.code === 200) {
                showToastMessage('Accetped successfully.')
                setShowLoading(false)
                setShow(false)
            } else {
                showToastMessage(res?.detail)
                setShowLoading(false)
            }
        })
    }

    const handleReject = async () => {
        setShowLoading(true)
        tokenApi('/rejectVoucher', {
            "voucherId": infos?._id
        }).then(res => {
            if (res.code === 200) {
                showToastMessage('Rejected succesfully.')
                setShowLoading(false)
                setShow(false)
            } else {
                showToastMessage(res?.detail)
                setShowLoading(false)
            }
        })
    }

    return (
        <div className={style.giftPointsModal} style={show ? {} : { display: 'none' }}>
            <div className={style.modalBox}>
                <img src={
                    // `${infos?.buyer?.avatar}?${new Date().getTime()}`
                    infos?.buyer?.avatar
                } alt="" />
                <p className={style.name}>{infos?.buyer?.nickname}</p>
                <p className={style.amount}>Gifted you {infos?.points} gems!</p>
                <div className={style.points}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                        <g clipPath="url(#clip0_2172_4229)">
                            <mask id="mask0_2172_4229" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                                <path d="M22 0H0V22H22V0Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_2172_4229)">
                                <path d="M4.54949 3.6665H12.7363C12.8269 3.66648 12.9163 3.69356 12.997 3.74554C13.0778 3.79751 13.1477 3.87289 13.201 3.96553L15.3967 7.78028C15.4357 7.84815 15.455 7.93103 15.4511 8.01479C15.4473 8.09856 15.4204 8.17804 15.3755 8.2397L8.85368 17.1677C8.8268 17.2044 8.7942 17.2337 8.75793 17.2537C8.72164 17.2737 8.68248 17.2841 8.6429 17.2841C8.60331 17.2841 8.56415 17.2737 8.52788 17.2537C8.4916 17.2337 8.459 17.2044 8.43211 17.1677L1.91033 8.24043C1.86516 8.17872 1.83835 8.09909 1.83447 8.01516C1.8306 7.93124 1.8499 7.84822 1.88909 7.78028L4.08484 3.96553C4.13812 3.87289 4.208 3.79751 4.28876 3.74554C4.36952 3.69356 4.45887 3.66648 4.54949 3.6665Z" fill="#42E0A7" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.2051 16.0711L13.4272 19.1129C13.4541 19.1496 13.4867 19.1791 13.523 19.199C13.5593 19.2191 13.5984 19.2294 13.638 19.2294C13.6776 19.2294 13.7168 19.2191 13.753 19.199C13.7893 19.1791 13.8219 19.1496 13.8489 19.1129L20.3706 10.185C20.4156 10.1234 20.4424 10.0439 20.4463 9.96012C20.4501 9.87635 20.4309 9.79348 20.3918 9.7256L18.1961 5.91084C18.1429 5.81822 18.0729 5.74283 17.9922 5.69085C17.9114 5.63889 17.8221 5.6118 17.7315 5.61182H15.6991L16.9472 7.78023C16.9862 7.8481 17.0055 7.93097 17.0016 8.01474C16.9976 8.0985 16.9709 8.17798 16.926 8.23965L11.2051 16.0711Z" fill="#42E0A7" />
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_2172_4229">
                                <rect width="22" height="22" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>
                        {infos?.points}
                    </span>
                </div>
                <div className={style.infos}>
                    <p>The receiver will get gems to</p>
                    <p>spend on any draws.</p>
                </div>
                <div className={style.acts}>
                    <div className={style.accept} onClick={handleAccept}>Accept</div>
                    <div className={style.reject} onClick={handleReject}>Reject</div>
                </div>
                <div className={style.close} onClick={() => setShow(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15 9L9 15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 9L15 15" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <div className={style.mask} onClick={() => setShow(false)}></div>
        </div>
    )
}
export default GiftPointsModal