import { IonContent, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";




// import MoreActModal from "./components/moreActModal";
// import ClickAddModal from "./components/clickAddModal";
import GroupModal from "./components/groupModal";
import NewChatModal from "./components/newChatModal";


import style from "../../style/Friends.module.css";

import { MyContext } from "../../components/UseContextComponent";
import InviteLIst from "./components/inviteLIst";
// import { showToastMessage } from "../../components/GlobalLoading";
import GiftVoucherDetail from "components/giftComponent/GiftVocherDetail";
import GroupVoucherChoose from "components/giftComponent/GroupVouvherChoose";
import { selectInvitesCountByinviteesId } from "db/schema/invites";
import RecentContact from "pages/chatroom/components/recent-contacts";
import RecentContactsResults from "pages/chatroom/components/recent-contacts-results";
import { RoomInfo } from "store/chatRoomSlice";
import { useStore } from "../../store";
import GiftVoucherChoose from "./components/GiftVoucherChoose";
/* const options = { state: true, presence: true };
const filter: any = {
  type: "team",
  members: { $in: [localGet('authAddress')] },
  member_count: { $gte: 2 },
  isPublic: false,
  isHidden: false,
  joined: {
    $eq: true
  }
};
const sort: any = { last_message_at: -1, updated_at: -1 };

const CustomChannelTeamFilter = (channels: Channel[]) => {
  const [userInfo] = useLocalStorage<User | null>('user_info')
  return channels.filter((channel: any) => {
    // console.log(channel);

    const members = channel.state.members
    let isAccept = false
    // console.log('membersmembers', members);

    for (const key in members) {
      if (members[key].user_id === userInfo?.user_metadata?.wallet_address) {
        if (!members[key].invite_accepted_at) {
        } else {
          isAccept = true
        }
      }
    }
    if (channel.data.created_by.id === localGet('usename').user_address) isAccept = true
    return channel.type === 'team' && !channel.id.includes('hide') && channel.data.member_count >= 2 && (channel.data.isGroup ? isAccept : true)
  });
}; */

export const Friends: React.FC = () => {
  const { context } = useContext(MyContext);
  const { changeModalState, setCurrentRoomInfo} = useStore();
  // const { user } = useAuth0()

  // Activemodal
  const [client] = useState<any>(context.common.client)
  // const [channel, setchannel] = useState<any>()
  // const [, setChannelUser] = useState<any>()

  const init = async () => {
    // let localClient: any
    await getInviteLength()
    if (!client) {
      /*       localClient = await myclient(user?.picture, localGet('chatString', true))
            setclient(localClient)
            localClient?.on('notification.invited', async (event: any) => {
              setTimeout(() => {
                PubSub.publish('initInviteList')
                getInviteLength()
              }, 800);
            }); */
    } else {
      client?.on('notification.invited', async (event: any) => {
        setTimeout(() => {
          PubSub.publish('initInviteList')
          getInviteLength()
        }, 800);
      });
    }
  }


  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [, setinvitecount] = useState(0)
  const getInviteLength = async () => {
    let num = await selectInvitesCountByinviteesId()
    setinvitecount(num);
  }

  // const handleChatModalChange = (newChatModal: any) => {
  //   changeModalState('isNewChatModalOpen', newChatModal);
  // };
  // const changeChannelUser = (user: any) => {
  //   setChannelUser(user)
  // }
  useEffect(() => {
    PubSub.subscribe('inviteNum', function (msg: any, data: any) {
      setinvitecount(data)
    })
  }, [])
  const [query, setQuery] = useState('');
  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }
  const openChatModal = (room_info: RoomInfo) => {
    changeModalState(room_info.isGroup ? 'isChatModalOpen' : 'isChatModalOpen', true)
    setCurrentRoomInfo(room_info)
  }
  return (
    <IonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={style.appFriends}>

          <div className={style.change}>
            <p>Friends/Private Messages</p>
            {/* <div onClick={() => updateContext('activemodal', true)} style={{ width: 36, display: 'flex', justifyContent: 'end' }}>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
              </svg>
            </div> */}
          </div>
          <div className={style.line}></div>
          <div className={style.chats}>
            <div className={style.serarch}>
              <div className={style.searchInputBox}>
                <input
                  type="text"
                  name="search"
                  placeholder="Search friends"
                  onChange={handleSearch}
                />
                <span className={style.iconClick}>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill="white" />
                  </svg>
                </span>
              </div>
              {
                query && query.trim() && (
                  <RecentContactsResults
                    query={query}
                    openChatModal={openChatModal}
                  />
                )
              }

            </div>
            <ul className={style.listss}>
              <li onClick={() =>
                changeModalState('isGroupModalOpen', true)
                // showToastMessage('The function is not available yet.')
              }>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
                  <rect y="0.0638428" width="48" height="48" rx="24" fill="#9C94FF" />
                  <path d="M27.5919 22.4443C29.661 22.7005 31.3755 20.9466 31.0996 18.8972C30.9222 17.4784 29.799 16.3551 28.3998 16.1778C26.3307 15.9216 24.5966 17.6557 24.8724 19.7249C25.0498 21.1437 26.1928 22.267 27.5919 22.4443ZM19.8174 22.4443C21.8866 22.7005 23.601 20.9466 23.3251 18.8972C23.1478 17.4784 22.0048 16.3551 20.6057 16.1778C18.5365 15.9216 16.8024 17.6557 17.0783 19.7249C17.2753 21.1437 18.3986 22.267 19.8174 22.4443ZM20.0933 24.0405C17.768 24.0405 13.0977 25.2426 13.0977 27.627V31.5288C13.0977 31.7456 13.275 31.9229 13.4918 31.9229H26.6948C26.9116 31.9229 27.089 31.7456 27.089 31.5288V27.627C27.089 25.2426 22.4186 24.0405 20.0933 24.0405ZM27.7692 24.0405C27.4736 24.0405 27.1386 24.0602 26.7839 24.0996C27.9663 24.947 28.794 26.1096 28.794 27.627V31.5288C28.794 31.7456 28.9713 31.9229 29.1881 31.9229H34.5087C34.7255 31.9229 34.9028 31.7456 34.9028 31.5288V27.627C34.9028 25.2426 30.1537 24.0405 27.7692 24.0405Z" fill="#2D323E" />
                </svg>
                New Group Chats
              </li>
              <li onClick={() => changeModalState('isNewChatModalOpen', true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="47" height="49" viewBox="0 0 47 49" fill="none">
                  <rect y="0.0638428" width="47" height="48" rx="23.5" fill="#9C94FF" />
                  <path d="M18.9972 18.719C19.164 16.2373 21.2077 14.2145 23.6894 14.0685C26.5881 13.9017 29.0072 16.1956 29.0072 19.0735C29.0072 21.8263 26.7758 24.0785 24.0022 24.0785C21.1243 24.0785 18.8095 21.6386 18.9972 18.719ZM25.6497 30.731C25.6497 28.8333 26.7133 27.1649 28.2773 26.3308C26.7133 25.9345 25.1492 25.726 23.9814 25.726C20.6655 25.726 13.9922 27.3943 13.9922 30.731V33.2335C13.9922 33.6923 14.3676 34.0677 14.8264 34.0677H26.9218C26.1293 33.1918 25.6497 32.024 25.6497 30.731ZM34.0122 29.8968H31.5097V27.3943H29.8414V29.8968H27.3389V31.5652H29.8414V34.0677H31.5097V31.5652H34.0122V29.8968Z" fill="#2D323E" />
                </svg>
                New Friends
              </li>
              <li onClick={() =>
                changeModalState('isInviteListModalOpen', true)
                // showToastMessage('The function is not available yet.')
              }
                style={{ justifyContent: "space-between" }}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="49" viewBox="0 0 47 49" fill="none">
                    <rect y="0.0638428" width="47" height="48" rx="23.5" fill="#9C94FF" />
                    <path d="M14.123 18.7655H33.6373" stroke="#2D323E" strokeWidth="2.5" />
                    <path d="M14.123 25.3893H26.4816" stroke="#2D323E" strokeWidth="2.5" />
                    <path d="M26.9961 30.2058H35.4097" stroke="#2D323E" strokeWidth="2.5" />
                    <path d="M31.2051 25.999L31.2051 34.4126" stroke="#2D323E" strokeWidth="2.5" />
                    <path d="M14.123 32.0129H23.4299" stroke="#2D323E" strokeWidth="2.5" />
                  </svg>
                  Invitation List
                </div>
                {/* {
                        inviteList?.length > 0 &&
                        <span>{inviteList?.length}</span>
                      } */}
              </li>
              <li onClick={() =>
                // showToastMessage('The function is not available yet.')
                changeModalState('isGiftAVoucherModalOpen', true)
              }
                style={{ justifyContent: "space-between" }}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
                    <rect y="0.217041" width="40" height="40" rx="20" fill="#9C94FF" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.5365 27.1392H13.4003C12.8397 27.1392 12.3851 26.6641 12.3851 26.0782V22.6911C13.3115 22.4376 13.9924 21.5898 13.9924 20.5829C13.9924 19.5761 13.3115 18.7283 12.3851 18.4748V15.0877C12.37 14.5017 12.8397 14.0266 13.4003 14.0266H26.5365C27.0971 14.0266 27.5516 14.5017 27.5516 15.0877V18.5669C26.7642 18.8973 26.2112 19.6755 26.2112 20.5829C26.2112 21.4904 26.7642 22.2686 27.5516 22.599V26.0782C27.5516 26.6641 27.0971 27.1392 26.5365 27.1392Z" fill="#2D323E" />
                    <line x1="17.623" y1="14.024" x2="17.6684" y2="27.1367" stroke="#9C94FF" strokeWidth="1.5" strokeDasharray="3 3" />
                  </svg>
                  Gift a Voucher
                </div>
              </li>
            </ul>
            <div className={style.friendlistname}>Recent Contacts</div>
            <RecentContact />


          </div>

          {/* new chat modal  */}
          <NewChatModal
          />
          {/* Gift a Voucher  */}
          <InviteLIst getInviteLength={getInviteLength} />
          {/* GroupModal */}
          <GroupModal
            client={client}
          // setShowLoading={setShowLoading}
          />
        </div>
        <GiftVoucherChoose />
          <GroupVoucherChoose  />
          <GiftVoucherDetail  />
      </IonContent>
    </IonPage>
  )
}