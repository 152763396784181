import { RefObject, useEffect } from 'react';

const useClickOutside = <T extends HTMLElement>(
  refs: RefObject<T>[],
  callback: () => void
) => {
  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    const isContain = refs.filter(
      (ref) => ref.current && ref.current.contains(event.target as Node)
    );
    if (isContain.length === 0) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refs, callback]);
};

export default useClickOutside;
