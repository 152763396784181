import { friendList, getCommonChannelMessages } from 'api/chat';
import { setShowLoading } from 'components/GlobalLoading';
import { ChatMessage } from 'hooks/useCommonChannels';
import { StateCreator } from 'zustand';
export interface ParsedChatMessage extends ChatMessage {
  user_name: string;
  avatar: string;
}
export interface CommonChatRoomMessage {
  avatar?: string | null;
  message_id: string;
  message_time: string;
  text: string;
  mentioned_users: string[];
  avatar_url: string;
}

export interface RoomInfo {
  new_message_count: number;
  avatar: string;
  name: string;
  latest_message: string;
  message_time: string;
  room_id: string;
  isGroup: boolean;
  member_count?: number;
}
interface UpdateRoomMessageParams {
  roomID: string;
  latest_message: string;
  message_time: string;
}
export interface ChatRoomState {
  commonChatRoomMessages: CommonChatRoomMessage[];
  chatRooms: RoomInfo[];
  currentRoomInfo: RoomInfo | null;
  newMessage: ParsedChatMessage | null;
  getRecentContacts: () => void;
  getCommonChatMessages: (roomID: string) => void;
  addCommonChatMessages: (message: CommonChatRoomMessage) => void;
  setNewMessage: (message: ParsedChatMessage) => void;
  setCurrentRoomInfo: (room_info: RoomInfo | null) => void;
  updateChatRoomsMessage: ({
    roomID,
    latest_message,
    message_time
  }: UpdateRoomMessageParams) => void;
  readMessage: (roomID: string) => void;
}
export const createChatRoomSlice: StateCreator<ChatRoomState> = (set) => ({
  commonChatRoomMessages: [],
  chatRooms: [],
  currentRoomInfo: null,
  newMessage: null,
  // Get all recent contacts
  getRecentContacts: async () => {
    setShowLoading(true);
    try {
      const res = await friendList();
      console.log({ RecentContacts: res.data });
      if (res.status === 200) {
        set({ chatRooms: res.data as RoomInfo[] });
      }
    } finally {
      setShowLoading(false);
    }
  },
  // Adding a message to the public channel message list
  addCommonChatMessages: (message) => {
    set(({ commonChatRoomMessages: prev }) => ({
      commonChatRoomMessages: [...prev, message]
    }));
  },
  //Get all the information in this public channel's message list
  getCommonChatMessages: async (roomID: string) => {
    setShowLoading(true);
    try {
      const data = await getCommonChannelMessages(roomID);
      if (data.status === 200 && data.data) {
        set({ commonChatRoomMessages: data.data });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  },
  setCurrentRoomInfo: (room_info: RoomInfo | null) => {
    set({ currentRoomInfo: room_info });
  },
  setNewMessage: (message) => {
    set({ newMessage: message });
  },
  updateChatRoomsMessage: ({ roomID, latest_message, message_time }) => {
    set(({ chatRooms, currentRoomInfo }) => {
      const index = chatRooms.findIndex((room) => room.room_id === roomID);
      if (index >= 0) {
        if (currentRoomInfo && currentRoomInfo?.room_id === roomID) {
          chatRooms[index] = {
            ...chatRooms[index],
            message_time,
            latest_message
          };
        } else {
          chatRooms[index] = {
            ...chatRooms[index],
            message_time,
            latest_message,
            new_message_count: chatRooms[index].new_message_count + 1
          };
        }
        console.log(chatRooms);
        return { chatRooms: [...chatRooms] };
      } else {
        return { chatRooms };
      }
    });
  },
  readMessage: (roomID: string) => {
    set(({ chatRooms }) => {
      const index = chatRooms.findIndex((room) => room.room_id === roomID);
      if (index >= 0) {
        chatRooms[index].new_message_count = 0;
        return { chatRooms: [...chatRooms] };
      } else {
        return { chatRooms };
      }
    });
  }
});
