export interface NotificationInfo {
  _id: string;
  objectId: AdditionalInfo;
  type: NotificationMessageType;
  user: string;
  message: string;
  isRead: boolean;
  isNotify: boolean;
  image?: any;
  createdTime: string;
  lastUpdateTime: string;
  _meta: Meta;
  _deleted: boolean;
  _attachments: Attachments;
  _rev: string;
}
interface Attachments {}
export interface AdditionalInfo {
  channelId?: string;
  voucherId?: string;
  organizerId?: string;
  drawId?: string;
  ticketNumber?: number;
  gems?:number
  questsHistoryId?:string;
  type?:string;
}

interface Meta {
  lwt: number;
}
export enum NotificationMessageType {
  voucher = 'Voucher',
  invite_split_ticket = 'Invite join split ticket',
  invite_new_friend = 'Invite New Friend',
  invite_group_chat = 'Invite Group Chat',
  win_draw = 'Win Draw',
  lost_draw = 'Lost Draw',
  daily_quests = 'Daily Quests'
}
