import PubSub from 'pubsub-js';
import { useEffect, useState } from "react";
import { filterDraws, getDrawsInOneHour } from "../db/schema/draws";
import { scrollWinNotify, syncDatabase } from "../db/sync/syncDatabase";
import { localGet, localSet } from "../utils/localStorage";
// import img1 from "../image/upcoming/1.png";
import { IonAlert } from "@ionic/react";
import { User } from "@supabase/supabase-js";
import { checkVersion, getLastVersion } from "db/schema/newVersion";
import { useLocalStorage, useUser } from "hooks";
import { selectFaceId } from "../db/schema/faceId";
import { getSetting, insertSetting } from "../db/schema/settings";
import { useStore } from '../store';
import clearCache from "../utils/clearCache";
import { setShowLoading } from "./GlobalLoading";
// import { getAllImages } from "../db/schema/images";
// import { useHistory, useLocation } from "react-router-dom";


const GlobalSyncData = () => {

    // const { loginWithRedirect } = useAuth0()
    // loading
    const { logout } = useUser()
    const [newVersion, setNewVersion] = useState(false);
    // const [versionAction, setVersionAction] = useState();

    // check version
    const version = async () => {
        const isUpdate = await checkVersion();
        console.log('isUpdate', isUpdate);
        if (isUpdate === true) {
            setNewVersion(true)
        }
    }
    useEffect(() => {
        version();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // settings
    const userSetting = async () => {
        const data = await getLastVersion();
        console.log('data', data);

        const userSetting = await getSetting();
        if (userSetting === null) {
            const faceId = await selectFaceId();
            await insertSetting(
                {
                    id: localGet('authAddress'),
                    biometric: faceId === null ? false : true,
                }
            );
        }
    }
    useEffect(() => {

        userSetting();

        /* console.log(localGet('usename').pin_code);

        derivePinCode(localGet('usename').pin_code).then((res: any) => {
            console.log('res', res);

        }); */

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const location = useLocation();
    // const history = useHistory()
    // useEffect(() => {
    //     if (localStorage.getItem('haslogin') !== 'true' && location.pathname !== '/login') {
    //         history.push('/login')
    //     }
    // }, [location.pathname]);
    const { globalUserInfo } = useStore()
    const [localStorageUserInfo] = useLocalStorage<User>('user_info');
    const userInfo = localStorageUserInfo ?? globalUserInfo;
    // const { user, isAuthenticated } = useAuth0()
    const getDatas = async () => {
        const drawsInOneHour = await getDrawsInOneHour();

        scrollWinNotify(drawsInOneHour, (onCompletion: any) => {
            const winning: Array<any> = onCompletion.winning;
            const draws: Array<any> = onCompletion.draws;
            const user: any = onCompletion.user;
            const scrollWin: any = onCompletion.scrollWin;
            const tickets: Array<any> = onCompletion.tickets;
            const vouchers: Array<any> = onCompletion.vouchers
            const notifications: Array<any> = onCompletion.notifications
            const splitTicketInvites: Array<any> = onCompletion.splitTicketInvites
            let getlist: any
            PubSub.publish('syncData', { winning, draws, user, scrollWin, tickets, getlist, vouchers, notifications, splitTicketInvites });
        })

        await syncDatabase(async (onSyncComplete: any) => {
            console.log('onSyncComplete', onSyncComplete);

            const winning: Array<any> = onSyncComplete.winning;
            const draws: Array<any> = onSyncComplete.draws;
            const user: any = onSyncComplete.user;
            const scrollWin: any = onSyncComplete.scrollWin;
            const tickets: Array<any> = onSyncComplete.tickets;
            const vouchers: Array<any> = onSyncComplete.vouchers
            const notifications: Array<any> = onSyncComplete.notifications
            const splitTicketInvites: Array<any> = onSyncComplete.splitTicketInvites
            const newVersion: boolean = onSyncComplete.newVersion
            let getlist: any
            if (draws !== null && draws.length > 0) {
                // const images = await getAllImages()
                // let ress = await filterDraws('upcoming', '', 'desc', localGet('pageSize') || 10, 1).then();
                let ress;
                const upcomingSort = localGet('upcomingSort');
                const upcomingSortParame = localGet('upcomingSortParame');
                if (upcomingSort === null) {
                    ress = await filterDraws('upcoming', '', 'desc', localGet('pageSize') || 10, 1).then();
                } else if (upcomingSort === 'time') {
                    ress = await filterDraws('upcoming', upcomingSortParame, '', localGet('pageSize') || 10, 1).then()
                } else if (upcomingSort === "prize") {
                    ress = await filterDraws('upcoming', '', upcomingSortParame, localGet('pageSize') || 10, 1).then()
                }
                getlist = ress.map((item: any) => {
                    return item
                })
            }
            if (newVersion === true) {
                setNewVersion(true)
            }
            PubSub.publish('syncData', { winning, draws, user, scrollWin, tickets, getlist, vouchers, notifications, splitTicketInvites });
            localSet('balance', user)
        })

    }
    // useEffect(()=>{
    //     getDatas()
    // },[userInfo])
    useEffect(() => {
        if (userInfo&&userInfo.user_metadata?.pin_code) {
            if (localGet('reflesh') === true) {
            } else {
                localSet('reflesh', true);
                setTimeout(() => {
                    localStorage.removeItem('checkpoint')
                    window.location.reload()
                }, 0);
            }
            if (localGet('requesed') === true) {

            } else {
                getDatas()
                localSet('requesed', true)
            }
        } else {
            getDatas()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo])
    return (
        <>
            <IonAlert
                isOpen={newVersion}
                onDidDismiss={() => {
                    // setNewVersion(false)
                }}
                backdropDismiss={false}
                mode="ios"
                message="New version available, the system will now update to the latest version."
                buttons={[
                    {
                        text: 'OK',
                        role: 'yes',
                        handler: async () => {
                            setShowLoading(true)
                            await clearCache()
                            localSet('haslogin', true)
                            // await loginWithRedirect()
                            logout()
                        },
                    }
                ]}
            />
        </>
    )

}


export default GlobalSyncData;
