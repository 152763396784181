import { IonButton } from '@ionic/react';
import { supabase } from 'api/initSupabase';
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { tokenApi } from "../api";
import style from "../style/Topdata.module.css";
import { localGet, localSet } from "../utils/localStorage";
import Notification, { PageConfig } from './Notification';

// import { myBalance } from '../db/schema/balanceHistory'
import { selectUser } from "../db/schema/user";
// import { createCreds } from "../utils/Webauthn";
import { User } from "@supabase/supabase-js";
import { externalJWT } from "api/auth";
import { selectNotification } from "../db/schema/notification";
import { useLocalStorage, useOnce } from "../hooks";
import { useStore } from '../store';
import { NotificationInfo } from "../typings/notification";
import Daily from "./Daily";
import { setShowLoading, showToastMessage } from "./GlobalLoading";
import SVGIcon from './SvgComponent';
import ForgotPasswordModal from "./auth/forgot-password-modal";
import PinCodeModal from "./auth/pincode-modal";
import SignInModal from "./auth/sign-in-modal";
import SignUpModal from "./auth/sign-up-modal";
import { findUserAddress } from './auth/storeUserInfo';
// import { selectFaceId } from "../db/schema/faceId";

const Topdata: React.FC = () => {

  // const [authModal, setAuthModalState] = useSetState<AuthModalState>({
  //   SignInModal: false,
  //   SignUpModal: false,
  //   ForgotPassword: false,
  //   PinCodeModal: false
  // });
  const [amount, setamount]: any = useState()
  const [points, setPoints]: any = useState()

  const { globalUserInfo,isClickDaily, isClickRing, setGlobalState, changeModalState, setChatProps,setUserInfo,closeAllModalStates } = useStore()
  const [localStorageUserInfo] = useLocalStorage<User>('user_info');
  const userInfo = localStorageUserInfo ?? globalUserInfo;

  const history = useHistory();

  const location = useLocation()

  const refs = useRef<any>([]);

  const [hasPinCode] = userInfo?.user_metadata?.pin_code ? [true] : [false];


  const [infos] = useState(false)
  // const [updatePinCode, setUpdatePinCode] = useState(false)
  useEffect(() => {
    if (userInfo) {
      // setIsAuth(true)
      localSet('auth0_user', true);
      localSet('language', window.navigator.language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    const storedUser = localGet('auth0_user');

    if (storedUser) {
      // setuserInfo(true);
      setShowLoading(false)
    }
  }, []);

  useEffect(() => {
    if (userInfo && !hasPinCode) {
      changeModalState('isPincodeModalOpen', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPinCode, userInfo])


  // const reffextion = localGet('userBalance')
  // useEffect(() => {
  //   setamount(localGet("userBalance")?.toLocaleString() || 0)
  //   /* myBalance().then((res) => {
  //     setamount(res)
  //   }) */
  //   console.log('reffextionreffextion ', reffextion)
  //   // getNum()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user, userInfoenticated, reffextion])

  /* async function getNum() {

    // setamount(localGet("userBalance")?.toLocaleString() || 0)

    await myBalance().then((res) => {
      setamount(res)
    })

  } */
  const getUser = async (user?:User) => {
    const _userInfo = user??userInfo
    if (_userInfo && _userInfo.user_metadata.user_wallet) {
      const exp = await externalJWT()
      localSet('id_token', exp.data)
      await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/getUser?wallet=${_userInfo.user_metadata.user_wallet}`,
        {
          credentials: 'same-origin',
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${exp.data}`
          },
        }).then(async (response) => {
          if (response.status !== 200) {
            const resp = tokenApi('/createUser')
            resp.then((res: { code: number; }) => {
              if (res.code === 200) {
                // loginWithRedirect()
                window.location.reload()
              }
            })
          } else {
            if (!localGet('chatString')) {
              const infos = await response.json()
              // const chatToken = await getChatToken()
              setChatProps([infos?.data?.user?.avatarURL])
              localSet('chatProps', [infos?.data?.user?.avatarURL])
            }
          }
        })
    }
  }
  useEffect(() => {
    selectUser().then((res) => {
      setamount(res?.money?.toLocaleString() || 0)
      setPoints(res?.points?.toLocaleString() || 0)
    });

    // setamount(Number(localStorage.getItem('userBalance'))?.toLocaleString() || 0)
    PubSub.subscribe('syncData', function (msg, searchName) {
      // console.log('searchNamesearchNamesearchNamesearchName---------------', searchName)
      if (searchName.user !== null && searchName.user !== undefined) {
        setamount(searchName?.user?.money?.toLocaleString())
        setPoints(searchName?.user?.points?.toLocaleString())
      }
    })

    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])



  // const [openAuth0Modal, setOpenAuth0Modal] = useState(false)
  // const [authName, setAuthName] = useState('')
  // const [authEmail, setAuthEmail] = useState('')
  // const [nameExist, setNameExist] = useState(false)


  useEffect(() => {
    if (infos === false && userInfo && refs.current[0]) {
      refs.current[0].focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infos, refs])

  useEffect(() => {
    if (userInfo) {
      localSet('requesed', false)
    }

  }, [userInfo])



  const [isNoMore, setIsNoMore] = useState<boolean>(false)
  const [isAllRead, setIsAllRead] = useState<boolean>(false)
  const [notificationList, setNotificationList] = useState<NotificationInfo[]>([])

  useOnce(() => {
    PubSub.subscribe('syncData', async (msg, searchName) => {
      if (searchName.notifications) {
        const list: NotificationInfo[] = await selectNotification(1, notificationList.length || 10)
        setIsNoMore(!list || list.length < 10)
        console.log(list)
        setNotificationList(list)
        setIsAllRead(list.every(_ => _.isRead))
      }
    })
  })
  const ringClick = () => {
    if (isClickDaily) {
      setGlobalState('isClickDaily', false)
      setGlobalState('isClickRing', true)
    } else if (isClickRing) {
      setGlobalState('isClickRing', isClickRing === true ? false : true)
    } else {
      setGlobalState('isClickRing', true)
    }
  }
  const onSetNotify = useCallback((_id: string) => {
    const _index = notificationList.findIndex(_ => _._id === _id)
    setNotificationList(prev => {
      prev[_index].isNotify = false
      return prev
    })
  }, [notificationList])
  const getNotificationDropdownData = async (pageConfig: PageConfig) => {
    const { pageNumber, pageSize } = pageConfig
    const list: NotificationInfo[] = await selectNotification(pageNumber, pageSize)
    console.log(list, pageConfig)
    setIsNoMore(!list || list.length < 10)
    setNotificationList(prevList => {
      setIsAllRead([...prevList, ...list].every(_ => _.isRead))
      return [...prevList, ...list]
    })
  }

  useEffect(() => {
    const getFirstNotification = async () => {
      const list: NotificationInfo[] = await selectNotification(1, 10)
      setIsNoMore(!list || list.length < 10)
      setNotificationList(prevList => list)
      setIsAllRead(list.every(_ => _.isRead))
      console.log(list)
    }

    getFirstNotification()
  }, [])

  // OauthLogin

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handelSetOAuthToken =async(accessToken: string, refresh_token: string) => {
      const { data, error } = await supabase.auth.setSession({
        access_token:accessToken,
        refresh_token
      })
      const userData = data?.user??null;
      if (userData) {
        localSet('user_info', userData);
        if (userData?.user_metadata.pin_code) {
          localSet('has_pin_code', true)
          localSet('authAddress', userData.user_metadata.user_wallet)
          await findUserAddress(userData, getUser)
          setUserInfo(userData);
          history.push('/');
        }else {
          closeAllModalStates(false)
          changeModalState('isPincodeModalOpen', true)
        }
      }else{
        console.log(error)
      }
  }


  const hashParams = new URLSearchParams(window.location.hash.substr(1));
  // const accessToken = hashParams.get('access_token');
  // const refresh_token = hashParams.get('refresh_token');

  useEffect(() => {
    const accessToken = hashParams.get('access_token');
    const refresh_token = hashParams.get('refresh_token');
    if(accessToken&&refresh_token){
      handelSetOAuthToken(accessToken,refresh_token)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // const [shake, setShake] = useState(false);




  return (
    <div className={style.upComingTop}
      style={
        location.pathname === "/"
          || location.pathname === "/upcoming"
          || location.pathname === "/billion"
          || location.pathname === "/joinedgames"
          || location.pathname === "/pastgames"
          || location.pathname === "/hightolow"
          || location.pathname === "/lowtohight"
          ?
          {} : { visibility: 'hidden' }
      }
    >

      <div className={style.pjlogo}>
        <SVGIcon name="TopDataLogo" width="28" height="24" viewBox="0 0 28 24" />
      </div>

      {
        userInfo ?
          <div className={style.rigthData}>
            <div className={style.datas}>
              <SVGIcon
                name='TopDataDiamond'
                width="11"
                height="9"
                viewBox="0 0 11 9"
              />

              <span>{points}</span>
            </div>
            <div className={style.datas}>
              <SVGIcon
                name='TopDataDollar'
                width="5"
                height="10"
                viewBox="0 0 5 10"
              />
              <span>{amount}</span>
            </div>
          </div>
          :
          <div className={style.unAuth}>
            <IonButton
              onClick={async () => {
                if (navigator.onLine === false) {
                  showToastMessage('Please check your network and try later.')
                  return
                }
                localSet('clicked', true)
                changeModalState('isSignInModalOpen', true)
                // loginWithRedirect()
              }}
            >
              Sign In/Up
            </IonButton>

          </div>
      }
      <div className={style.icon} style={userInfo ? {} : { display: 'none' }}>

        <div className={style.isDailyActive} onClick={() => {
          // showToastMessage('This feature is not yet available.')
          if (isClickRing) {
            setGlobalState('isClickRing', false)
            setGlobalState('isClickDaily', true)
          } else if (isClickDaily) {
            setGlobalState('isClickDaily', isClickDaily === true ? false : true)
          } else {
            setGlobalState('isClickDaily', true)
          }
        }}>
          <SVGIcon
            name='TopDataDaily'
            width="20"
            height="22"
            viewBox="0 0 20 22"
          />
        </div>
        <div className={style.isRingActive} onClick={ringClick
        }>
          <SVGIcon
            name='NotificationSVG'
            width="16"
            height="20"
            viewBox="0 0 16 20"
          />
          <div style={{ display: isAllRead ? 'none' : 'block' }} className={style.purpleCircle}></div>
        </div>
        <div className={isClickRing || isClickDaily ? `${style.dropdown} ${style.setvh}` : style.dropdown}>
          <div className={isClickRing ? style.Ringtarget : style.DailyTarget} style={userInfo ? {} : { left: '22px' }}
          ></div>

          <div className={style.main}>
            {isClickRing && <Notification list={notificationList} getList={getNotificationDropdownData} isNoMore={isNoMore} setNotify={onSetNotify} />}
            {isClickDaily && <Daily />}
          </div>
          <div className={style.mask} onClick={() => {
            isClickRing ? setGlobalState("isClickRing", false) : setGlobalState("isClickDaily", false)
          }}></div>
        </div>
      </div>

      <SignInModal getUser={getUser} />

      <SignUpModal />

      <ForgotPasswordModal />

      <PinCodeModal getUser={getUser} />
    </div >
  );
};
export default Topdata;
