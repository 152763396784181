import { useState, useEffect, SetStateAction } from 'react';

const useToast = () => {
    const [showToast, setShowToast] = useState(false);
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (showToast) {
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [showToast]);

    const showToastMessage = (text: SetStateAction<string>) => {
        setMessage(text);
        setShowToast(true);
    };

    return { showToast, showToastMessage, message };
};

export default useToast;