import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const questsSchema = {
    title: 'questsSchema',
    description: 'quests',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        user: {
            type: 'string',
        },
        questCategory: {
            type: 'object',
        },
        qty: {
            type: 'number',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
        isReceived: {
            type: 'boolean',
        },
        isActivate: {
            type: 'boolean',
        }
    }
};

/**
 * get quests
 * @returns {Promise<quests>}
 */
export const getQuests = async () => {
    const db = await Database.get();
    const quests = await db.quests.find({
        selector: {
            user: localGet('authAddress'),
        }
    }).exec();

    return JSON.parse(JSON.stringify(quests));
}