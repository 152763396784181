import { handleInviterAccepted, handleInviterRejected } from "api/chat";
import { selectInvitesCountByinviteesId } from "db/schema/invites";
import useGlobalInvite from "hooks/useGlobalInvite";
import { useEffect, useState } from "react";
import { useStore } from "store";
import { setShowLoading, showToastMessage } from "../components/GlobalLoading";
import avatarGroup from '../image/friends/avatarGroup.jpg';
import style from './ChannelInvite.module.css';

const ChannelInvite = () => {
    const { getRecentContacts } = useStore()
    const [show, setShow] = useState(false);
    const { inviteInfo, onCreateInviteeSubscribable, onCreateInviterSubscribable, onCreateRoomMessageSubscribable } = useGlobalInvite()
    useEffect(() => {
        setShow(!!inviteInfo);
    }, [inviteInfo]);

    useEffect(() => {
        (async () => {
            await onCreateInviteeSubscribable();
            await onCreateInviterSubscribable()
            await onCreateRoomMessageSubscribable()
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* useEffect(() => {
        if (user && isAuthenticated) {
            if (!client && localGet('chatProps')) {
                myclient(localGet('chatProps')[0], localGet('chatProps')[1]).then(res => {
                    setclient(res)
                })
            } else {
                PubSub.subscribe('chatclient', async (msg, searchName) => {
                    setclient(searchName)
                })
            }


            if (client) {
                client.on('notification.invited', async (event: any) => {
                    console.log('eventeventeventeventeventevent', event)
                    setShow(true)
                    setinviteInfo(event)
                });

                // const rejected = async () => {
                //     const d = await client.queryChannels({
                //         invite: 'pending',
                //     });
                //     setinviteInfo(d)
                //     console.log('rejectedrejectedrejected', d)
                // }
                // rejected()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, user, isAuthenticated]) */

    const handleAccept = async () => {
        setShowLoading(true)
        if (!inviteInfo) return
        try {
            await handleInviterAccepted(inviteInfo.id, inviteInfo.isGroup)
            await getRecentContacts()
            const num = await selectInvitesCountByinviteesId()
            PubSub.publish('inviteNum', num)
            PubSub.publish('initInviteList')
            setShowLoading(false)
            setShow(false)

        } catch (error: any) {
            setShowLoading(false)
            showToastMessage(error.message)
        }
        finally {
            setShow(false)
            setShowLoading(false)
        }
    }

    const handleReject = async () => {
        setShowLoading(true)
        if (!inviteInfo) return
        try {
            await handleInviterRejected(inviteInfo.id, inviteInfo.isGroup)
            const num = await selectInvitesCountByinviteesId()
            PubSub.publish('inviteNum', num)
            PubSub.publish('initInviteList')
            setShowLoading(false)
            setShow(false)
        } catch (error: any) {
            setShowLoading(false)
            showToastMessage(error.message)
        }
    }
    return (
        <>
            <div className={show ? `${style.inviteModal} ${style.active}` : style.inviteModal}>
                <div className={style.main}>
                    <svg onClick={() => setShow(false)} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.9038 10.0099C19.9038 15.1732 15.7765 19.3472 10.6995 19.3472C5.62244 19.3472 1.49512 15.1732 1.49512 10.0099C1.49512 4.84662 5.62244 0.672607 10.6995 0.672607C15.7765 0.672607 19.9038 4.84662 19.9038 10.0099Z" stroke="#9E9E9E" />
                        <path d="M13.9666 6.69653L7.42969 13.323M7.42969 6.69653L13.9666 13.323" stroke="#9E9E9E" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {!inviteInfo?.isGroup ?
                        (<div className={style.info}>
                            <img src={
                                // `${inviteInfo?.channel?.created_by?.image}?${new Date().getTime()}`
                                inviteInfo?.avatar
                            } alt="" />
                            <div>
                                <span>{inviteInfo?.name}</span>
                                <p>Invite you to chat together</p>
                            </div>
                        </div>
                        )
                        :
                        (<div className={style.info}>
                            <img src={inviteInfo?.avatar
                                || avatarGroup} alt="" />
                            <div style={{ color: '#000000' }}>
                                <span>{inviteInfo?.name}</span>
                                Invites you to join group chat
                                <span style={{ fontWeight: '400' }}>{inviteInfo?.name}</span>
                            </div>
                        </div>)
                    }
                    <div className={style.actions}>
                        <div className={style.accept} onClick={handleAccept}>Accept</div>
                        <div className={style.refuse} onClick={handleReject}>Reject</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChannelInvite