import {clearIndexedDB} from "../db/clearIndexedDB";

export default async function clearCache() {
    // clear localStorage, sessionStorage
    window.localStorage.clear();
    window.sessionStorage.clear();

    // clear indexedDB
    await clearIndexedDB();

    // Web SQL
    // window.openDatabase(null, null, null, null).transaction(function (tx) {
    //     tx.executeSql('SELECT name FROM sqlite_master WHERE type="table"', [], function (tx, result) {
    //         for (let i = 0; i < result.rows.length; i++) {
    //             tx.executeSql('DROP TABLE ' + result.rows.item(i).name);
    //         }
    //     });
    // });

    // clear cookie
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }

    // clear cache
    caches.keys().then(function(keys) {
        keys.forEach(function(key) {
            caches.delete(key);
        });
    });

    // caches.open("OIAM-assets").then(function(cache) {
    //     cache.keys().then(function(keys) {
    //         keys.forEach(function(key) {
    //             cache.delete(key);
    //         });
    //     });
    // });


    // clear service worker
    if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for (let registration of registrations) {
                registration.unregister();
            }
        });
    }

    console.log("clear cache success")
}