import { IonRippleEffect } from '@ionic/react';
import React, { ButtonHTMLAttributes } from 'react';
import style from '../../style/common.module.css';
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {

}

const Button: React.FC<Props> = ({ children, ...prop }) => {
    return (
        <button {...prop} className={`${style.button} ion-activatable ${prop.className}`}>
            {children}
            <IonRippleEffect></IonRippleEffect>
        </button>
    )
}

export default Button