import { IonContent, IonModal } from '@ionic/react'
import { User } from '@supabase/supabase-js'
import { retrieveUser } from 'api/account'
import { signIn, signInWithOAuth } from 'api/auth'
import { showToastMessage } from 'components/GlobalLoading'
import Button from 'components/common/Button'
import { FC, useState } from 'react'
import { RegisterOptions, useForm } from 'react-hook-form'
import { localSet } from 'utils/localStorage'
import { useStore } from '../../store'
import { CloseModalSVG, EthereumSVG, FacebookSVG, GoogleSVG, PasswordHideSVG, PasswordShowSVG, TwitchSVG } from './svg'
import { findUserAddress } from './storeUserInfo'
interface SignInModalProps {
    getUser: (user?:User) => void
}
interface SignInParams {
    email: string
    pwd: string
}
const SignInModal: FC<SignInModalProps> = ({ getUser }) => {
    const { isSignInModalOpen, changeModalState, closeAllModalStates,setUserInfo } = useStore()
    // const history = useHistory()

    // const [userInfo] = useLocalStorage<User>('user_info')
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignInParams>();
    const fieldRequirements: Record<keyof SignInParams, RegisterOptions> = {
        pwd: {
            required: 'Please enter your Password.'
        },
        email: {
            required: 'Please enter your Email.'
        }
    }
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false)
    const onSwitchPasswordShow = () => {
        setIsShowPassword(isShow => !isShow)
    }

    const onSubmit = async (form: SignInParams) => {
        try {
            const res = await signIn(form.email, form.pwd)
            console.log(res)
            showToastMessage(res?.msg)
            // present({
            //     message: res?.msg,
            //     duration: 1500,
            //     position: 'top',
            // });
            if (res.status === 200) {
                const data = await retrieveUser()
                if (data.status === 200) {
                    const user_data = data.data
                    if(user_data){
                        localSet('user_info', user_data)
                        setUserInfo(user_data)
                    }
                    closeAllModalStates(false)
                    changeModalState('isSignInModalOpen', false)
                    if (user_data?.user_metadata.pin_code) {
                        localSet('has_pin_code', true)
                        localSet('authAddress', user_data.user_metadata.user_wallet)
                        await findUserAddress(user_data, getUser)
                        // window.location.reload()
                    } else {
                        closeAllModalStates(false)
                        changeModalState('isPincodeModalOpen', true)
                    }
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleSignInWithOauth = async (provider: any) => {
        try {
            const signInResult = await signInWithOAuth(provider);
            console.log(signInResult);
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <IonModal className='sign-in' isOpen={isSignInModalOpen}>
            <IonContent>
                <div className='sign-in-modal'>
                    <div className='sign-in-modal__header'>
                        <span>Sign In</span>
                        <CloseModalSVG onClick={() => changeModalState('isSignInModalOpen', false)} />
                    </div>
                    <div className='sign-in-modal__content'>
                        <form onSubmit={handleSubmit(onSubmit)} className='auth-form mb-10'>
                            <div className="auth-form-group">
                                <div className="auth-form-group-control">
                                    <label>Email</label>
                                    <input type='email' {...register('email', fieldRequirements.email)} />
                                </div>
                                <p>{errors.email?.message}</p>
                            </div>
                            <div className="auth-form-group mb-10">
                                <div className="auth-form-group-control">
                                    <label>Password</label>
                                    <input className='form-password' type={isShowPassword ? 'text' : 'password'} {...register('pwd', fieldRequirements.pwd)} />
                                    {isShowPassword ? <PasswordShowSVG onClick={onSwitchPasswordShow} /> : <PasswordHideSVG onClick={onSwitchPasswordShow} />}
                                </div>
                                <p>{errors.pwd?.message}</p>
                            </div>
                            <p onClick={() => changeModalState('isForgotPasswordModalOpen', true)} className='sign-in-modal__content--forget'>
                                Forgot Password?
                            </p>
                            <Button className='mb-20' type='submit'>Sign In with Email</Button>
                        </form>

                        <div className='sign-in-modal__content--or'>
                            <span>OR</span>
                        </div>
                        <ul className='sign-in-modal__content--social '>
                            <li>
                                <Button onClick={() => handleSignInWithOauth('google')}><GoogleSVG /> Sign In with Google</Button>
                            </li>
                            <li>
                                <Button onClick={() => handleSignInWithOauth('facebook')}><FacebookSVG /> Sign In with Facebook</Button>
                            </li>
                            <li>
                                <Button onClick={() => handleSignInWithOauth('twitch')}><TwitchSVG /> Sign In with Twitch</Button>
                            </li>
                            <li>
                                <Button><EthereumSVG /> Sign In with Ethereum</Button>
                            </li>
                        </ul>
                        <p className='sign-in-modal__content--link'>Dn not have an account?<span onClick={
                            () => {
                                closeAllModalStates(false); changeModalState('isSignUpModalOpen', true)
                            }}>Sign Up</span></p>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default SignInModal