
import { IonModal, IonContent } from "@ionic/react";
import mixstyle from "../../../style/Topdata.module.css";
import { useStore } from "../../../store"
import SVGIcon from "components/SvgComponent";

interface Props {
    imgUrl: any;
    setImgUrl: any;
}

const UploadResultModal: React.FC<Props> = ({
    imgUrl,
    setImgUrl,
}) => {
    const { isUpLoadResultModalOpen,changeModalState } = useStore();
    return (
        <IonModal
            isOpen={isUpLoadResultModalOpen}
            mode="ios"
            onDidDismiss={() => {
                changeModalState('isUpLoadResultModalOpen',false)
                setImgUrl()
            }}>
            <IonContent className="ion-padding">
                <div className={mixstyle.upLoadResultModal}>

                    <div className={mixstyle.des}>
                        <SVGIcon 
                            name="SuccessSvg" 
                            width="64"
                            height="64"
                            viewBox="0 0 64 64"
                            fill="none"
                        />
                        <p>Identity Verified</p>
                        <div>Thanks for verifying your identity. You can now get tickets to enjoy the draws.</div>
                        {/* <img src={imgUrl} alt="" /> */}

                    </div>
                    <div className={mixstyle.sub} style={{flexShrink:0}} onClick={() => {
                        changeModalState('isDriverLicenseModal',false)
                        changeModalState('isUpLoadResultModalOpen',false)
                    }}>
                        Done
                    </div>

                </div>
            </IonContent>
        </IonModal>
    )
}
export default UploadResultModal