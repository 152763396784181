import { AccountParams, retrieveUser, updateAccount } from 'api/account';
import { supabase } from 'api/initSupabase';
import { localSet } from 'utils/localStorage';

const useUser = () => {
  // const history = useHistory();

  const updateProfile = async (params: Omit<AccountParams, 'id'>) => {
    try {
      const { data } = await retrieveUser();
      if (!data) return;
      const res = await updateAccount({ id: data.id, ...params });
      return res;
    } catch (error) {
      return { data: null, msg: 'Unknown Error' };
    }
  };
  const getProfile = async () => {
    try {
      const res = await retrieveUser();
      if (res?.status === 200 && res.data) {
        // localSet('user_info', res.data);
        return res.data;
      } else {
        // throw Error(res?.msg ?? 'Unknown Error');
      }
    } catch (error: any) {
      // showToastMessage(error?.message??'Unknown Error');
      // logout({ isNeedCaptcha: false });
    }
  };
  const logout = async ({
    isNeedCaptcha,
    returnTo
  }: { isNeedCaptcha?: boolean; returnTo?: string } = {}) => {
    localStorage.clear();
    if (!isNeedCaptcha) {
      localSet('haslogin', true);
    }
    supabase.auth.signOut();
    if (returnTo) {
      window.location.href = returnTo;
    } else {
      window.location.href = '/'
    }
  };
  return { updateProfile, getProfile, logout };
};

export default useUser;
