
export const getContacts = async () => {
    // check if browser supports Contact Picker API
    const supportsContacts = "contacts" in navigator && "ContactsManager" in window;

    // is it iOS device
    const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // get iOS version number
    const iOSVersion = parseFloat(
        ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
            .replace('undefined', '3_2').replace('_', '.').replace('_', '')
    );
    // is it iOS 14.5 or above
    const isiOS14_5OrAbove = isiOS && iOSVersion >= 14.5;

    console.log('is it iOS device:', isiOS, iOSVersion, isiOS14_5OrAbove);

    if (isiOS14_5OrAbove && !supportsContacts) {
        console.log('Need to open Contact Picker API in Safari settings to use contact function, do you want to watch the help video?');
        return 'ios14.5+';
    }

    if (!supportsContacts) {
        console.log("Your browser doesn't support Contact Picker API");
        return 'failed';
    }

    if ("contacts" in navigator &&
        "select" in navigator.contacts &&
        "getProperties" in navigator.contacts) {
        try {
            const props = await navigator.contacts.getProperties();
            console.log(props);
            const contacts = await navigator.contacts.select(props);
            console.log(contacts);
            return contacts;
        } catch (err) {
            console.log("Unexpected error happened in Contact Picker API");
            return 'failed';
        }
    } else {
        console.log("Your browser doesn't support Contact Picker API");
        return 'failed';
    }
};