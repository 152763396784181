import * as Database from '../';
import { localGet } from "../../utils/localStorage";

export const balanceHistorySchema = {
    title: 'balanceHistorySchema',
    description: 'balance history',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        type: {
            type: 'string',
        },
        user: {
            type: 'string',
        },
        amount: {
            type: 'number'
        },
        date: {
            type: 'string',
            format: 'date-time',
        },
        draw: {
            type: 'number',
        },
        payment: {
            type: 'string',
        },
    },
    required: [
        'user',
        'payment',
        'type',
        'amount',
        'date'
    ]
}

// select balance history
export const selectBalanceHistory = async (sort, pageNumber, pageSize) => {

    const db = await Database.get();

    const balanceHistoryData = await db.balance_history.find({
        selector: { user: localGet('authAddress'), payment: 'money'},
        sort: [{ date: sort}],
    }).skip((pageNumber - 1) * pageSize).limit(pageSize).exec();

    return balanceHistoryData;

}