
export const checkJwtExpiration = (jwtToken: string) => {
    const jwtParts = jwtToken.split('.');
    console.log(jwtParts.length)
    if (jwtParts.length === 3) {
        const payload = JSON.parse(atob(jwtParts[1]));
        if (payload && payload.exp) {
            const currentTime = Math.floor(Date.now() / 1000);
            if (payload.exp > currentTime) {
                return true;
            } else {
                return false;
            }
        } else {
            console.log('Expiration date not found');
        }
    } else {
        console.log('Invalid JWT');
    }
}