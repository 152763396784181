import data from '@emoji-mart/data';
import Picker from "@emoji-mart/react";
import { useToggle } from 'ahooks';
import useClickOutside from 'hooks/useClickOutside';
import { ChangeEvent, KeyboardEvent, forwardRef, useRef, useState } from "react";
import { useStore } from "store";
import { AddSVG, SendSVG, SmileSVG } from "../svg";
interface ChatroomControllerProps {
    sendMessage: (room_id: string, value: string, onAfterSend: () => void) => void
    onOpenUserInfoModal: () => void
    onAfterHandleBlur?: () => void

}
const ChatroomController = forwardRef<HTMLInputElement, ChatroomControllerProps>(({ sendMessage, onOpenUserInfoModal, onAfterHandleBlur }, refs) => {
    const [isOpenEmojiPicker, { toggle, set }] = useToggle<boolean>(false)
    const [value, setValue] = useState<string>('')
    const { currentRoomInfo } = useStore()
    const emojiWrapperRef = useRef<HTMLDivElement>(null)
    const handleClickSendMessage = async () => {
        if (!currentRoomInfo) return
        sendMessage(currentRoomInfo.room_id, value, () => {
            setValue('')
            set(false)
        })
    }
    const handleSubmitSendMessage = async (event: KeyboardEvent<HTMLInputElement>) => {
        if (!currentRoomInfo) return
        if (event.key === 'Enter') {
            sendMessage(currentRoomInfo.room_id, value, () => {
                setValue('')
                set(false)
            })
        }
    }
    const onOpenEmojiPicker = () => {
        toggle()
    }
    const onEmojiSelect = (emoji: any) => {
        setValue(prev => prev + emoji.native)
    }
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }
    const onHandleBlur = () => {
        onAfterHandleBlur?.()
    }
    useClickOutside([emojiWrapperRef], () => {
        set(false)
    })
    return (
        <div className="chat-controller__content--send" ref={emojiWrapperRef}>
            <div className='chat-controller__content--send--emoji-picker' >
                {isOpenEmojiPicker && <Picker previewPosition={'none'} data={data} onEmojiSelect={onEmojiSelect} />}
            </div>
            <AddSVG onClick={onOpenUserInfoModal} />
            <div className="chat-controller__content--send--input">
                <SmileSVG isActive={isOpenEmojiPicker} onClick={onOpenEmojiPicker} />
                <input onKeyDown={handleSubmitSendMessage} onChange={handleInputChange} onBlur={onHandleBlur} value={value} placeholder="Type a message" ref={refs} />
            </div>
            <span><SendSVG onClick={handleClickSendMessage} /></span>
        </div>
    )
})

export default ChatroomController