import { IonModal, IonContent, IonRefresher, IonRefresherContent } from "@ionic/react";

import style from "../../../style/Account.module.css";
import noneimg from "../../../image/account/node.png"
import MyticketSwiper from "../../../components/myticketSwiper";
import { useStore } from "../../../store"
import SVGIcon from "components/SvgComponent";
interface Props {
    gets: any;
    ticketlist: any;
}
const MyticketsModal: React.FC<Props> = ({
    gets,
    ticketlist,
}) => {
    const { isMyTicketsModalOpen,changeModalState } = useStore();
    return (
        <IonModal
            isOpen={isMyTicketsModalOpen}
            mode="ios"
            className={style.myticketsbox}
            onDidDismiss={() => {
                changeModalState('isMyTicketsModalOpen', false)
            }}>
            <IonContent >
                <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className={style.mytickets_top}>
                        <div onClick={() => {
                            changeModalState('isMyTicketsModalOpen', false)
                        }}>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        </div>
                        <span>My Tickets</span>
                        <div style={{ paddingRight: '16px', justifyContent: 'flex-end' }}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="7.11803" cy="7.64794" r="2.85314" stroke="white" strokeWidth="1.5" />
                                <circle cx="16.8819" cy="16.3521" r="2.85314" stroke="white" strokeWidth="1.5" />
                                <line x1="12.6516" y1="7.86499" x2="19.8049" y2="7.86499" stroke="white" strokeWidth="1.5" />
                                <line x1="3.9353" y1="16.5691" x2="11.3484" y2="16.5691" stroke="white" strokeWidth="1.5" />
                            </svg>
                        </div>
                    </div>
                    <IonRefresher slot="fixed" onIonRefresh={(e) => {
                        setTimeout(() => {
                            gets()
                            e.detail.complete()
                        }, 1000);
                    }}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {ticketlist.length > 0 ?
                            <MyticketSwiper />
                            :
                            <div className={style.mytickets_none}>
                                <div className={style.mytickets_none_img}>
                                    <img src={noneimg} alt="" />
                                </div>
                                <p>A little empty here</p>
                            </div>
                        }
                    </div>

                </div>
            </IonContent>
        </IonModal>
    )
}
export default MyticketsModal