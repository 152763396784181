import imageCompression from 'browser-image-compression';
import { supabase } from './initSupabase';


/**
 * compress avatar image
 * @param file 
 * @returns 
 */
export const compressAvatar = async (file: File) => {
    if (!file) { return { status: 400, msg: 'Missing avatar image.', data: null } }
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 80,
        useWebWorker: true
    }
    try {
        const compressedFile = await imageCompression(file, options)
        return { status: 200, msg: null, data: compressedFile }
    } catch (error) {
        return { status: 400, msg: error, data: null }
    }

}


/**
 * supabse upload file and return file url
 * @param bucketName string
 * @param filePath string
 * @param file File
 * @returns file url
 */
export const uploadFile = async (bucketName: string, filePath: string, file: File) => {
    const { data, error } = await supabase
        .storage
        .from(bucketName)
        .upload(filePath, file, {
            cacheControl: '3600',
            upsert: true
        })
    if (error) { return { status: 400, msg: error.message, data: null } }

    // get avatar url
    const result = await supabase
        .storage
        .from(bucketName)
        .getPublicUrl(data.path)
    if (!result.data.publicUrl) {
        return { status: 400, msg: 'File does not exist.', data: null }
    } else {
        return { status: 200, msg: null, data: result.data.publicUrl }
    }

}
