import * as Database from '../';
import { localGet } from '../../utils/localStorage';

export const userSchema = {
  title: 'userSchema',
  description: 'sync user info ',
  version: 1,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
      maxLength: 100
    },
    money: {
      type: 'number',
      default: 0
    },
    points: {
      type: 'number',
      default: 0
    },
    creationTime: {
      type: 'string',
      format: 'date-time'
    },
    lastUpdateTime: {
      type: 'string',
      format: 'date-time'
    },
    nickname: {
      type: 'string'
    },
    auth0ID: {
      type: 'string'
    },
    auth0AvatarURL: {
      type: 'string'
    }
  }
};

// select user
export const selectUser = async () => {
  const db = await Database.get();
  const address = localGet('authAddress');
  if (!address) return;
  return await db.user
    .findOne({
      selector: { _id: address }
    })
    .exec();
};
