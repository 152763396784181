import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/variables.css";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import 'stream-chat-react/dist/css/v2/index.css';
import "./style/global.css";

import Footer from "./components/Footer";
import Account from "./pages/account/Account";

import InviteSplitPopUp from "components/inviteSplitPopUp";
import { useLocalStorage } from "hooks";
import NewChatRoom from "pages/chatroom/new-chat-room";
import React from 'react';
import { localGet } from "utils/localStorage";
import BuyTicket from "./components/BuyTicket";
import GiftPointsModal from "./components/GiftPointsModal";
import { GLobalLoading } from "./components/GlobalLoading";
import GlobalSyncData from "./components/GlobalSyncData";
import LoopText from "./components/LoopText";
import Topdata from "./components/TopData";
import { MyProvider } from "./components/UseContextComponent";
import GiftComponent from "./components/giftComponent";
import NotExist from "./pages/404";
import Billion from "./pages/Billion";
import JoinedGames from "./pages/JoinedGames";
import Login from "./pages/Login";
import PastGames from "./pages/PastGames";
import Upcoming from "./pages/Upcoming";
import { Friends } from "./pages/friends/Friends";
import Share from "./pages/share/Share";
import ChannelInvite from "./streamchat/ChannelInvite";
import './style/main.scss';
setupIonicReact();
const App: React.FC = () => {
  const [isLogin] = useLocalStorage('haslogin')
  /*   const DOMAIN: any = process.env.REACT_APP_DOMAIN
    const CLIENTID: any = process.env.REACT_APP_CLIENTID
    const AUTH0_AUDIENCE: any = process.env.REACT_APP_AUTH0_AUDIENCE */
  return (
    /*     <Auth0Provider
          domain={DOMAIN}
          clientId={CLIENTID}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: AUTH0_AUDIENCE,
            useRefreshTokens: true
          }}
          cacheLocation="localstorage"
        > */
    <div className="alexBlocks">
      <IonApp>
        <MyProvider >
          <IonReactRouter>
            <Topdata></Topdata>
            <LoopText></LoopText>
            <GlobalSyncData></GlobalSyncData>
            <ChannelInvite></ChannelInvite>
            <GiftPointsModal></GiftPointsModal>
            <GLobalLoading />
            <BuyTicket></BuyTicket>
            <GiftComponent></GiftComponent>
            <InviteSplitPopUp />
            <IonRouterOutlet>
            <Switch>
            <Route exact path="/" render={() => (
                localGet('haslogin') ? (
                  <Upcoming />
                ) : (
                  <Redirect to="/login" />
                )
              )} >
            </Route>
              <Route path="/upcoming" render={() => (
                localGet('haslogin') ? (
                  <Upcoming />
                ) : (
                  <Redirect to="/login" />
                )
              )} />
              <Route path="/friends" render={() => (
                localGet('haslogin') ? (
                  <Friends />
                ) : (
                  <Redirect to="/login" />
                )
              )} />

              <Route path="/billion" render={() => (
                localGet('haslogin') ? (
                  <Billion />
                ) : (
                  <Redirect to="/login" />
                )
              )} />
              <Route path="/chatRoom" render={() => (
                localGet('haslogin') ? (
                  <NewChatRoom />
                  // <ChatRoom />
                ) : (
                  <Redirect to="/login" />
                )
              )} />
              <Route path="/account" render={() => (
                localGet('haslogin') ? (
                  <Account />
                ) : (
                  <Redirect to="/login" />
                )
              )} />
              <Route path="/joinedGames" render={() => (
                localGet('haslogin') ? (
                  <JoinedGames />
                ) : (
                  <Redirect to="/login" />
                )
              )} />
              <Route path="/pastGames" render={() => (
                localGet('haslogin') ? (
                  <PastGames />
                ) : (
                  <Redirect to="/login" />
                )
              )} />
              <Route exact path="/share">
                <Share />
              </Route>
              <Route exact path="/login">
                {isLogin ? <Redirect to="/upcoming" /> : <Login />}
              </Route>
              <Route>
                <NotExist />
              </Route>
            </Switch>

            </IonRouterOutlet>
            <Footer></Footer>
          </IonReactRouter>
        </MyProvider>
      </IonApp>
    </div>
    // </Auth0Provider >
  )
}
export default App;


/* const RouteGuard = ({ path, children }: { path: string, children: ReactNode }) => {
  const haslogin = localGet('haslogin')
  const userInfo = localGet('user_info')
  return <Route path={path} render={() => (haslogin ? userInfo ? children : <Redirect to="/" /> : < Redirect to="/login" />
  )}>
  </Route >
} */