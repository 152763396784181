import { IonCheckbox, IonContent, IonModal, IonSearchbar, SearchbarCustomEvent } from "@ionic/react";
import { getAllFriends } from "api/chat";
import React, { useState } from "react";
import { setShowLoading, showToastMessage } from "../../../components/GlobalLoading";
import back from "../../../image/friends/Group.png";
import { useStore } from '../../../store';
import style from "../../../style/Friends.module.css";
import errorstyle from "../../../style/Upcoming.module.css";
import NewGroup from "./NewGroup";

interface GroupModalProps {
  client: any;
}
type FriendKeys = 'user_id' | 'user_name' | 'avatar'
export type FriendItem = Record<FriendKeys, string> & { show: boolean }

const GroupModal: React.FC<GroupModalProps> = () => {
  const { isGroupModalOpen, changeModalState } = useStore();
  const [, setSearchFriend] = useState<any>('');
  // const [addNum,setAddNum] = useState<any>(0);
  // const [friendId, setFriendId] = useState<any>();
  const [friendList, setFriendList] = useState<FriendItem[]>([]);
  const [chooseFriend, setChooseFriend] = useState<FriendItem[]>([]);

  const handleSearch = async (event: SearchbarCustomEvent) => {
    const arr = [...friendList]
    arr.map((i) => {
      i.show = true
      if (i.user_name.includes(event?.target?.value ?? '')) {
        i.show = true
      } else {
        i.show = false
      }
      return i
    })
    setFriendList(arr)
  }

  const onSelectFriends = (friend: FriendItem) => {
    return () => {
      const chooseIndex = chooseFriend.findIndex((item) => item.user_id === friend.user_id);
      if (chooseIndex < 0) {
        setChooseFriend([...chooseFriend, friend]);
      } else {
        setChooseFriend((preChooseFriend) => {
          return preChooseFriend.filter((item) => item.user_id !== friend.user_id);
        });
      }
    }

  }

  const handleDeleteFriend = (user_id: string) => {
    setChooseFriend((preChooseFriend) => {
      return preChooseFriend.filter((item) => item.user_id !== user_id);
    });
  }

  const getFriendList = async () => {
    setShowLoading(true)
    try {
      const data = await getAllFriends()
      setFriendList(data.map(_ => ({ ..._, show: true })))
    }
    catch (error: any) {
      showToastMessage(error.message)
    }
    finally {
      setShowLoading(false)
    }
  }

  const handleNextStep = () => {
    if (chooseFriend?.length > 1) {
      changeModalState('isNewGroupModalOpen', true)
    } else {
      showToastMessage('Please choose at least two friend.')
    }
  }
  return (
    <IonModal
      isOpen={isGroupModalOpen}
      mode="ios"
      onDidPresent={async () => {
        getFriendList();
      }}
      onDidDismiss={() => {
        setSearchFriend('')
        setFriendList([])
        changeModalState('isGroupModalOpen', false)
        setChooseFriend([])
      }}>
      <IonContent>
        <div className={style.newChatModal}>
          <div className={style.tit}>
            <span
              onClick={() => changeModalState('isGroupModalOpen', false)}
              style={{ marginRight: '18.88px' }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="white" />
              </svg>
            </span>
            <div>
              <span>Add Members</span>
              <p>{chooseFriend?.length || 0}/{friendList?.length || 0}</p>
              {/* <p>{addNum}/{list?.users?.length}</p> */}
            </div>
            <span onClick={handleNextStep}
            >
              <i style={{
                backgroundColor: '#9C94FF',
                color: '#000',
                padding: '4px 8px',
                marginTop: '0px',
                fontSize: '12px',
                borderRadius: '4px',
                fontStyle: 'normal'
              }}>Next</i>
            </span>
          </div>

          <div className={style.addSerch}>
            <IonSearchbar className={style.search_bar} onIonInput={handleSearch}></IonSearchbar>
            {/*             <div>
              <input type="text" name="search" placeholder="Search" value={searchFriend} onChange={(e) => {
                setSearchFriend(e.target.value);
                handleSearch(e.target.value)
              }} />
              <span onClick={() => handleSearch(searchFriend)}>
                <svg
                  width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.1291 11.8792H12.4707L12.2374 11.6542C13.0541 10.7042 13.5457 9.47083 13.5457 8.12916C13.5457 5.13749 11.1207 2.71249 8.12907 2.71249C5.1374 2.71249 2.7124 5.13749 2.7124 8.12916C2.7124 11.1208 5.1374 13.5458 8.12907 13.5458C9.47074 13.5458 10.7041 13.0542 11.6541 12.2375L11.8791 12.4708V13.1292L16.0457 17.2875L17.2874 16.0458L13.1291 11.8792ZM8.12907 11.8792C6.05407 11.8792 4.37907 10.2042 4.37907 8.12916C4.37907 6.05416 6.05407 4.37916 8.12907 4.37916C10.2041 4.37916 11.8791 6.05416 11.8791 8.12916C11.8791 10.2042 10.2041 11.8792 8.12907 11.8792Z" fill="white" />
                </svg>
              </span>
            </div> */}
          </div>
          {
            chooseFriend?.length > 0 ? (<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', padding: '0px 16px 16px' }}>
              {
                chooseFriend?.map((item) => {
                  return (
                    <div key={item.user_id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px', paddingBottom: '10px', position: 'relative' }}>
                      <img src={item.avatar} alt="" style={{ width: 60, height: 60, borderRadius: '50%' }} />
                      <p style={{ fontSize: '12px', lineHeight: '16px', color: '#fff', marginTop: '6px' }}>{item.user_name}</p>
                      <div
                        onClick={() => { handleDeleteFriend(item.user_id) }}
                        style={{ width: 20, height: 20, background: '#434958', borderRadius: '24px', position: 'absolute', left: '44px' }}>
                        <svg style={{ position: "absolute", right: "20.83%", top: "20.83%" }} xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <path d="M9.66683 1.27325L8.72683 0.333252L5.00016 4.05992L1.2735 0.333252L0.333496 1.27325L4.06016 4.99992L0.333496 8.72659L1.2735 9.66659L5.00016 5.93992L8.72683 9.66659L9.66683 8.72659L5.94016 4.99992L9.66683 1.27325Z" fill="white" />
                        </svg>
                      </div>
                    </div>
                  )
                })
              }
            </div>) : null
          }
          <ul>
            {
              friendList?.length > 0 ? (
                friendList.map((item) => {
                  return (
                    <li onClick={onSelectFriends(item)} key={item.user_id} style={{ display: item.show ? 'flex' : 'none', gap: '8px', justifyContent: 'flex-start', alignItems: 'center', paddingLeft: '16px', width: '100%', marginBottom: '8px' }}>
                      <IonCheckbox
                        slot="start"
                        checked={chooseFriend.some((chooseItem) => chooseItem.user_id === item.user_id)}
                        // onIonChange={(event)=>{onSelectFriends(event)}}
                        value={item.user_id}
                      ></IonCheckbox>
                      <div>
                        <img src={item.avatar} alt="" style={{ width: 48, height: 48, borderRadius: '50%' }} />
                      </div>
                      <div style={{ flex: 1, borderBottom: '1px solid #334663', height: 52, display: 'flex', alignItems: 'center' }}>
                        <p style={{ color: '#fff', textIndent: '0.5rem' }}>{item.user_name}</p>
                      </div>
                    </li>
                  )
                })
              ) :
                (<div className={errorstyle.cartex}>
                  <img src={back} alt="" />
                  <p style={{ width: '100%', textAlign: 'center', fontSize: '20px', lineHeight: '24px' }}>No friends found</p>
                </div>)
            }
          </ul>

          {/* New Group */}
          <NewGroup
            chooseFriend={chooseFriend}
            handleDeleteFriend={handleDeleteFriend}
            setChooseFriend={setChooseFriend}
          />
        </div>
      </IonContent>
    </IonModal>
  );
}

export default GroupModal;