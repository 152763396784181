import { IonModal, IonContent } from "@ionic/react";
import style from "./index.module.css";
import { useStore } from "../../../store"
const GiftResult = () => {
    const { inFriends,isGiftResultModalOpen,isGiftAVoucherModalOpen,changeModalState } = useStore();
    return (
        <IonModal mode="ios"
            isOpen={isGiftResultModalOpen}
            className={inFriends ? style.giftResultInFriends : ''}
            onDidDismiss={() => changeModalState('isGiftResultModalOpen', false)}>
            <IonContent className="ion-padding">
                <div className={style.giftResultBox}>
                    <div className={style.giftResultsvg}>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 64C23.4525 64 15.4166 60.6714 9.37263 54.6275C3.3285 48.5834 0 40.5475 0 32C0 23.4525 3.3285 15.4166 9.37263 9.3725C15.4166 3.32863 23.4525 0 32 0C40.5475 0 48.5834 3.32863 54.6274 9.3725C60.6715 15.4166 64 23.4525 64 32C64 40.5475 60.6715 48.5834 54.6274 54.6275C48.5834 60.6714 40.5475 64 32 64ZM32 2.75C15.8715 2.75 2.75 15.8715 2.75 32C2.75 48.1285 15.8715 61.25 32 61.25C48.1285 61.25 61.25 48.1285 61.25 32C61.25 15.8715 48.1285 2.75 32 2.75Z" fill="#CCCCCC" />
                            <path d="M27.407 41.3993C27.0568 41.3993 26.7065 41.2664 26.4384 41.0003L16.505 31.1409C15.966 30.6059 15.9628 29.7353 16.4978 29.1963C17.0326 28.6574 17.9031 28.654 18.4423 29.1889L27.4034 38.0833L44.3709 21.1158C44.9079 20.5789 45.7785 20.5789 46.3155 21.1158C46.8524 21.6528 46.8524 22.5234 46.3155 23.0604L28.3794 40.9967C28.1109 41.265 27.759 41.3993 27.407 41.3993Z" fill="#9C94FF" />
                        </svg>
                        <p>Purchase Successful</p>
                        <div className={style.giftResultp}>Notification successfully sent!</div>
                    </div>

                    <div className={style.giftResultbtn} onClick={() => {
                        changeModalState('isGiftOptionsModalOpen', false)
                        changeModalState('isGiftResultModalOpen', false)
                        if (isGiftAVoucherModalOpen) {
                            changeModalState('isGiftAVoucherModalOpen', false)
                        }
                        localStorage.removeItem('giftTo')
                    }}>
                        <p>Done</p>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default GiftResult