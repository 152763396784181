import { IonModal, IonContent } from "@ionic/react";
import style from "../../../style/Account.module.css";
import { useStore } from "../../../store"
import SVGIcon from "components/SvgComponent";

const ManageBankAccountModal: React.FC = () => {
    const { isManageBankModalOpen,changeModalState } = useStore();

    return (
        <IonModal isOpen={isManageBankModalOpen} mode="ios" className={style.withdrawn} onDidDismiss={() =>  changeModalState('isManageBankModalOpen',false)}>
            <IonContent>
                <div className={style.withbox}>
                    <div className={style.withtop} >
                        <div onClick={() => changeModalState('isManageBankModalOpen',false)}>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        </div>
                        <p>Manage Bank Account</p>

                        <div className={style.managesvg}>
                            <SVGIcon name="JoinGameAddIcon" width="14" height="14" viewBox="0 0 14 14" fill="white"/>
                        </div>
                    </div>
                    <div className={style.withsvg}>
                        <div>
                            <SVGIcon name="AddBankAccountIcon" width="64" height="64" viewBox="0 0 64 64" fill="none"/>
                        </div>
                        <div className={style.withp}>
                            <p>Add your back account to receive the Withdrawal</p>
                        </div>

                    </div>

                    <div className={style.withbtn} onClick={() =>  changeModalState('isAddBankModalOpen',true)}>
                        <p>Add a Bank Account</p>
                    </div>


                </div>
            </IonContent>
        </IonModal>
    )
}
export default ManageBankAccountModal