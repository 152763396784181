import { useSetState } from 'ahooks'
import { useEffect } from 'react'
import { useStore } from 'store'
import { RoomInfo } from 'store/chatRoomSlice'
import back from "../../../image/upcoming/ill.png"
import errorstyle from "../../../style/Upcoming.module.css"
import BlockModal from '../block-modal'
import ChannelItem from './channel-item'
import ChatModal from './chat-modal'


type ModalType = 'BlockModal' | 'PrivateChatModal' | 'UserInfoModal' | 'GroupChatModal'
const RecentContact = () => {
    const { isChatModalOpen, setCurrentRoomInfo, changeModalState } = useStore()
    const { chatRooms, getRecentContacts } = useStore()
    const [modalState, setModalState] = useSetState<Record<ModalType, boolean>>({ BlockModal: false, PrivateChatModal: false, UserInfoModal: false, GroupChatModal: false })
    const isNull = chatRooms.length === 0
    useEffect(() => {
        getRecentContacts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const onOpenChatModal = (room_info: RoomInfo) => {
        return () => {
            changeModalState(room_info.isGroup ? 'isChatModalOpen' : 'isChatModalOpen', true)
            setCurrentRoomInfo(room_info)
        }
    }
    const onCloseChatModal = (isGroup: boolean) => {
        changeModalState(isGroup ? 'isChatModalOpen' : 'isChatModalOpen', false)
        setCurrentRoomInfo(null)
    }
    return (
        <section className="channels">
            {isNull && <NoRecentContacts />}
            <div>
                {
                    chatRooms.map(roomInfo => (
                        <ChannelItem channelInfo={roomInfo} key={roomInfo.room_id} openBlockModal={() => setModalState({ BlockModal: true })} openChatModal={onOpenChatModal(roomInfo)} />
                    ))
                }
            </div>
            <BlockModal isOpen={modalState.BlockModal} closeModal={() => setModalState({ BlockModal: false })} />
            <ChatModal isOpen={isChatModalOpen} closeModal={onCloseChatModal} />
        </section>
    )
}

export default RecentContact

const NoRecentContacts = () => (
    <div className={errorstyle.cartex}>
        <img src={back} alt="" />
        <p style={{ width: '100%', textAlign: 'center' }}>Timeout, please refresh the page</p>
    </div>
)