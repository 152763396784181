export function formatDate(inputDate) {
    // Get the offset of the local time zone relative to UTC (in minutes)
    const offset = inputDate.getTimezoneOffset();
    // Apply the offset to the timestamp to get the local time zone time
    const localTimestamp = inputDate.getTime() - (offset * 60 * 1000);
    // Create a new Date object, using the local timestamp
    const date = new Date(localTimestamp);

    const now = new Date();
    const diff = (now - date) / 1000;

    if (diff < 60 && diff >= 0) {
        return "Just now";
    }

    const timeString = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    const today = new Date().toLocaleDateString();
    const yesterday = new Date(now.setDate(now.getDate() - 1)).toLocaleDateString();

    if (date.toLocaleDateString() === today) {
        return timeString;
    } else if (date.toLocaleDateString() === yesterday) {
        return "Yesterday";
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const day = date.getDate();
    const daySuffix = (day % 10 === 1 && day !== 11) ? "st" :
        (day % 10 === 2 && day !== 12) ? "nd" :
            (day % 10 === 3 && day !== 13) ? "rd" : "th";

    if (date.getFullYear() === now.getFullYear()) {
        return `${monthNames[date.getMonth()]} ${day}${daySuffix}`;
    }

    return `${monthNames[date.getMonth()]} ${day}${daySuffix}, ${date.getFullYear()}`;
}


export function formatTimeDate(inputDate) {
    // Get the offset of the local time zone relative to UTC (in minutes)
    const offset = inputDate.getTimezoneOffset();
    // Apply the offset to the timestamp to get the local time zone time
    const localTimestamp = inputDate.getTime() - (offset * 60 * 1000);
    // Create a new Date object, using the local timestamp
    const date = new Date(localTimestamp);

    const now = new Date();
    const diff = (now - date) / 1000;

    if (diff < 60 && diff >= 0) {
        return "Just now";
    }

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const day = date.getDate();
    const daySuffix = (day % 10 === 1 && day !== 11) ? "st" :
        (day % 10 === 2 && day !== 12) ? "nd" :
            (day % 10 === 3 && day !== 13) ? "rd" : "th";

    if (date.getFullYear() === now.getFullYear()) {
        return `${monthNames[date.getMonth()]} ${day}${daySuffix}`;
    }

    return `${monthNames[date.getMonth()]} ${day}${daySuffix}, ${date.getFullYear()}`;
}
