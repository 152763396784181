import * as Database from '../';

export const assetsSchema = {
    title: 'assetsSchema',
    description: 'assets',
    version: 0,
    primaryKey: '_id',
    type: 'object',
    properties: {
        _id: {
            type: 'string',
            maxLength: 100,
        },
        data: {
            type: 'object',
        },
        lastUpdateTime: {
            type: 'string',
            format: 'date-time',
        },
    },
};

// get assets
export const getAssets = async (id) => {
    const db = await Database.get();
    return await db.assets.find({
        selector: {
            _id: id
        }
    }).exec();
}

// get video url to new array
export const getVideoUrl = async () => {
    const db = await Database.get();
    let urls = [];
    let videos = await db.assets.find().exec();
    videos?.forEach(obj => {
        let data = obj.data;
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                let resolutions = data[key];
                for (let res in resolutions) {
                    if (resolutions.hasOwnProperty(res)) {
                        urls.push(resolutions[res]);
                    }
                }
            }
        }
    });

    // Get the Service Worker registration
    navigator.serviceWorker.ready.then(registration => {
        // Send the video URLs to the Service Worker
        registration.active.postMessage({ type: 'videoUrls', data: urls });
    });
    
    console.log('video urls', urls);
}