import { IonContent, IonModal } from "@ionic/react";
import { replyRoomMessage, sendTextMessage } from "api/chat";
import { showToastMessage } from "components/GlobalLoading";
import GiftVoucherDetail from "components/giftComponent/GiftVocherDetail";
import GroupVoucherChoose from "components/giftComponent/GroupVouvherChoose";
import { useChatMessage } from "hooks";
import UserInfoModal from "pages/friends/components/userInfoModal";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useStore } from "store";
import avatarGroup from '../../../image/friends/avatarGroup.jpg';
import { ClearWordSVG, PeopleSVG, ReturnSVG, SearchWordSVG } from "../svg";
import ChatroomController from './chatroom-controller';
import ChatroomItem from "./chatroom-item";
interface ChatModalProps {
    isOpen: boolean
    closeModal: (isGroup: boolean) => void
    openUserInfoModal?: () => void
}
const ChatModal: FC<ChatModalProps> = ({ closeModal, isOpen, openUserInfoModal }) => {
    const searchRef = useRef<HTMLInputElement>(null)
    const [searchValue, setSearchValue] = useState<string>('')
    const [isSearch, setISSearch] = useState<boolean>(false)
    const { currentRoomInfo, changeModalState } = useStore()
    const [replyMessageID, setReplyMessageID] = useState<string>('')
    const [activeChatId, setActiveChatId] = useState<string | undefined>()
    const scrollRef = useRef<HTMLUListElement>(null)
    const { messages, currentUserID, setMessages, privateUserId } = useChatMessage({ scrollRef })
    const handleChatClick = (id: string) => {
        return () => {
            setActiveChatId(id)
        }

    }
    const refs = useRef<HTMLInputElement>(null);
    const onHandleReply = (message_id: string) => {
        refs?.current?.focus();
        setReplyMessageID(message_id)
    }
    const sendMessage = async (room_id: string, value: string, onAfterSend: () => void) => {
        if (!currentRoomInfo) return
        const _value = value.trim()
        if (!_value) return showToastMessage('Please enter the message you want to send.')
        try {
            if (replyMessageID) {
                await replyRoomMessage({ message: _value, parent_message_id: replyMessageID, room_id })
            } else {
                const { status, msg } = await sendTextMessage(room_id, value.trim())
                if (status !== 200) {
                    showToastMessage(msg ?? 'Unknown Error')
                }
            }
        }
        catch (error: any) {
            showToastMessage(error.message)
        }
        finally {
            onAfterSend()
        }
    }
    const isSelf = (id: string) => id === currentUserID


    const onOpenUserInfoModal = () => {
        changeModalState('isUserInfoModalOpen', true)
    }
    const onAfterHandleBlur = () => {
        if (replyMessageID.length > 0) {
            setReplyMessageID('')
        }
    }
    const onCloseModal = () => {
        if (!currentRoomInfo) return
        closeModal(currentRoomInfo.isGroup)
    }
    const changeSearchState = (isOpen: boolean) => {
        setISSearch(isOpen)
        changeModalState('isUserInfoModalOpen', false)
    }
    useEffect(() => {
        let timer: NodeJS.Timeout
        if (isSearch) {
            timer = setTimeout(() => {
                searchRef.current?.focus()
            }, 800)
        }
        return () => {
            timer && clearTimeout(timer)
        }

    }, [isSearch])
    /*     useDebounceEffect(() => {
            handleGlobalSearch(searchValue)
        }, [searchValue], { wait: 1000 }) */

    const handleGlobalSearch = (value: string) => {
        const _messages = messages.map(message => {
            const newMessage = message.message.replaceAll(`<span style="background: rgb(255, 153, 80); border-radius: 4px;">`, '').replaceAll(`</span>`, '')
            message.message = newMessage;
            return message;
        })
        const newMessages = _messages.map(message => {
            const newMessage = message.message.replaceAll(value, `<span style="background: rgb(255, 153, 80); border-radius: 4px;">${value}</span>`)
            message.message = newMessage
            return message
        })
        setMessages(newMessages)
    }
    const isGroupOther = (id: string) => !isSelf(id) && currentRoomInfo?.isGroup
    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
        handleGlobalSearch(event.target.value)
    }
    const clearSearchValue = () => {
        setSearchValue('')
        setISSearch(false)
        const _messages = messages.map(message => {
            const newMessage = message.message.replace(`<span style="background: rgb(255, 153, 80); border-radius: 4px;">`, '').replace(`</span>`, '')
            message.message = newMessage;
            return message;
        })
        setMessages(_messages)
    }
    const onDidDismiss = () => {
        setISSearch(false)
    }
    return (
        <IonModal isOpen={isOpen} className="personal-chat-modal" onDidDismiss={onDidDismiss}>
            <IonContent>
                <div className="personal-chat">
                    <div className="personal-chat__header">
                        <ReturnSVG onClick={onCloseModal} />
                        {isSearch ?
                            <div className="personal-chat__header--search">
                                <SearchWordSVG />
                                <input value={searchValue} onChange={onSearchChange} ref={searchRef} />
                                <ClearWordSVG onClick={clearSearchValue} />
                            </div> :
                            <div className="personal-chat__header--info">
                                <img src={currentRoomInfo?.avatar || avatarGroup} alt="avatar" />
                                <div className="personal-chat__header--info-right">
                                    <p>{currentRoomInfo?.name}</p>
                                    {currentRoomInfo?.isGroup && <div className="personal-chat__header--info-right-member">
                                        <PeopleSVG /><span>{currentRoomInfo?.member_count ?? 0}</span>
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="personal-chat__content">
                        <div className="personal-chat__content--list">
                            <ul ref={scrollRef}>
                                {
                                    messages.map(message => {
                                        const parentMessage = messages.find(_ => _.message_id === message.parent_message_id)
                                        return <ChatroomItem
                                            emojiIconList={['👍', '❤️', '😂', '😲', '😔', '😠']}
                                            key={message.message_id}
                                            type="CustomChat"
                                            role={isSelf(message.user_id) ? 'self' : 'other'}
                                            chat_message={{ message_id: message.message_id, message_time: message.message_time, avatar: message.avatar }}
                                            ActiveBoxId={activeChatId}
                                            onClick={handleChatClick(message.message_id)}
                                            onHandleReply={onHandleReply}
                                        >
                                            <div className={`message_chat_content ${isSelf(message.user_id) ? 'self' : 'other'}`}>
                                                {isGroupOther(message.user_id) && <img src={message.avatar} alt={message.user_name} />}
                                                <div className="message_chat_info">
                                                    {isGroupOther(message.user_id) && <span>{message.user_name}</span>}
                                                    <div className={isSelf(message.user_id) ? "self_chat_txt_box" : "other_chat_txt_box"}>
                                                        {message.parent_message_id && <div className="msg_quoted">
                                                            <img src={parentMessage?.avatar} alt="" />
                                                            <div className="msg_quoted_main">
                                                                {currentRoomInfo?.isGroup && <span>{parentMessage?.user_name}</span>}
                                                                <div style={{ color: `${isSelf(message.user_id) ? '#fff' : '#000'}`, background: `${isSelf(message.user_id) ? 'rgb(45, 50, 62)' : '#9c94ff'}` }}>
                                                                    <p dangerouslySetInnerHTML={{ __html: parentMessage?.message ?? '' }} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                        <span dangerouslySetInnerHTML={{ __html: message.message }} />
                                                    </div>
                                                </div>


                                            </div>
                                        </ChatroomItem>
                                    })
                                }
                            </ul>
                        </div>
                        {/* <div className="personal-chat__content--list">
                            <ul>
                                <li className="other">
                                    <div className="other__info">
                                        <p>I Will Arrive On Time Tonight. I Hope lCan Have A Good Time With YouToday.!!!</p>
                                    </div>
                                </li>
                                <li className="self">
                                    <p>I Will Arrive On Time Tonight. I Hope lCan Have A Good Time With YouToday.</p>
                                </li>
                            </ul>
                        </div> */}
                        <ChatroomController sendMessage={sendMessage} onOpenUserInfoModal={onOpenUserInfoModal} onAfterHandleBlur={onAfterHandleBlur} ref={refs} />
                    </div>
                </div>
                <UserInfoModal userId={privateUserId} changeSearchState={changeSearchState} />
                <GroupVoucherChoose />
                <GiftVoucherDetail />
            </IonContent>
        </IonModal>
    )
}

export default ChatModal
