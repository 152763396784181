import { createRxDatabase, addRxPlugin } from 'rxdb';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
// import { mySchema } from './Schema';
import { accountSchema } from './schema/account'
// import { chatRoomSchema } from './schema/chatRoom'
// import { chatRoomMessageSchema, initChatRoomMessage } from './schema/chatRoomMessage'
// import { friendMessagesSchema } from './schema/friendMessages'
// import { friendsSchema } from './schema/friends'
import { drawsSchema } from './schema/draws'
import { ticketsSchema } from './schema/tickets'
import { balanceHistorySchema } from './schema/balanceHistory'
import { imagesSchema } from './schema/images'
import { checkpointSchema } from './schema/checkpoint'
import { assetsSchema } from './schema/assets'

import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';

// import { replicateCouchDB } from 'rxdb/plugins/replication-couchdb';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
// import { replicateRxCollection } from 'rxdb/plugins/replication';
// import { Subject } from "rxjs";

import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';

import { wrappedKeyEncryptionCryptoJsStorage } from 'rxdb/plugins/encryption-crypto-js';
import { userSchema } from './schema/user';
import { faceIdSchema } from "./schema/faceId";
import { channelsSchema } from "./schema/channels";
import { versionSchema } from './schema/version';
import { settingSchema } from './schema/settings';
import { shareSchema } from "./schema/share";
import { walletSchema } from './schema/wallet';
import { voucherSchema } from './schema/voucher';
import { pointsSchema } from './schema/points';
import { invitesSchema } from './schema/invites';
import { notificationSchema } from './schema/notification';
import { ratiosSchema } from './schema/ratios';
import { questsSchema } from './schema/quests';
import { newVersionSchema } from './schema/newVersion';
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

addRxPlugin(RxDBDevModePlugin);

// const syncURL = 'http://' + window.location.hostname + ':8100/';

let dbPromise = null;

const _create = async () => {
  console.log('DatabaseService: creating database..');
  const encryptedDexieStorage = wrappedKeyEncryptionCryptoJsStorage({
    storage: getRxStorageDexie()
  });
  const db = await createRxDatabase({
    name: 'mydatabase',
    storage: encryptedDexieStorage,
    password: 'foooooobaaaaar',
    // allowSlowCount: true,
  });
  console.log('DatabaseService: created database');
  window['db'] = db; // write to window for debugging

  // show leadership in title
  // db.waitForLeadership().then(() => {
  //     console.log('isLeader now');
  //     document.title = '♛ ' + document.title;
  // });

  // create collections
  console.log('DatabaseService: create collections');
  await db.addCollections({
    account: {
      schema: accountSchema,
    },
    /* chat_room: {
      schema: chatRoomSchema,
    },
    chat_room_message: {
      schema: chatRoomMessageSchema,
    },
    friend_messages: {
      schema: friendMessagesSchema,
    },
    friends: {
      schema: friendsSchema,
    }, */
    draw: {
      schema: drawsSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          oldDoc.winnerNickname = '';
          return oldDoc;
        },
        2: (oldDoc) => {
          oldDoc.ticketCount = 0;
          return oldDoc;
        },
        3: (oldDoc) => {
          oldDoc.isSplitTicket = false;
          return oldDoc;
        },
        4: (oldDoc) => {
          oldDoc.winnerMembers = [];
          return oldDoc;
        }
      }
    },
    tickets: {
      schema: ticketsSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        },
        2: (oldDoc) => {
          return oldDoc;
        },
        3: (oldDoc) => {
          return oldDoc;
        },
        4: (oldDoc) => {
          return oldDoc;
        }
      }
    },
    balance_history: {
      schema: balanceHistorySchema,
    },
    images: {
      schema: imagesSchema,
    },
    checkpoint: {
      schema: checkpointSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          oldDoc.checkpoint.asset_checkpoint = null;
          return oldDoc;
        },
        2: (oldDoc) => {
          oldDoc.checkpoint.channel_checkpoint = null;
          return oldDoc;
        },
        3: (oldDoc) => {
          return oldDoc;
        }
      }
    },
    user: {
      schema: userSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        }
      }
    },
    assets: {
      schema: assetsSchema,
    },
    face_id: {
      schema: faceIdSchema,
    },
    channels: {
      schema: channelsSchema,
    },
    version: {
      schema: versionSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          oldDoc.id = 'version';
          return oldDoc;
        }
      }
    },
    setting: {
      schema: settingSchema,
    },
    share: {
      schema: shareSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        }
      }
    },
    wallet: {
      schema: walletSchema,
    },
    voucher: {
      schema: voucherSchema,
    },
    points: {
      schema: pointsSchema,
    },
    invites: {
      schema: invitesSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        }
      }
    },
    notification: {
      schema: notificationSchema,
      migrationStrategies: {
        1: (oldDoc) => {
          return oldDoc;
        }
      }
    },
    ratios: {
      schema: ratiosSchema,
    },
    quests: {
      schema: questsSchema,
    },
    new_version: {
      schema: newVersionSchema,
    }

  });

  // sync
  console.log('DatabaseService: sync');
  // await Promise.all(
  //     Object.values(db.collections).map(async (col) => {
  //         try {
  //             // create the CouchDB database
  //             await fetch(
  //                 syncURL + col.name + '/',
  //                 {
  //                     method: 'PUT'
  //                 }
  //             );
  //         } catch (err) { }
  //     })
  // );
  console.log('DatabaseService: sync - start live');
  // Object.values(db.collections).map(col => { return col.name }).map(colName => {
  //   // const url = syncURL + colName + '/';
  //   const url = syncURL + colName + '/';
  //   const replicationState = replicateCouchDB({
  //     collection: db[colName],
  //     url,
  //     live: false,
  //     pull: {},
  //     push: {},
  //     autoStart: false
  //   });
  //   replicationState.error$.subscribe(err => {
  //     // console.log('Got replication error:');
  //     // console.dir(err);
  //   });
  //   return replicationState;

  // });

  // initChatRoomMessage();

  return db;
};

export const get = () => {
  if (!dbPromise)
    dbPromise = _create();
  return dbPromise;
};
