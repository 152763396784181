import { useEffect, useRef } from 'react';

const useOnce = <T extends () => void>(fn: T) => {
  const didExecuteRef = useRef(false);
  useEffect(() => {
    if (didExecuteRef.current) return;
    fn();
    didExecuteRef.current = true;
  }, [fn]);
};
export default useOnce