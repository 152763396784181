import * as Database from '../';

export const newVersionSchema = {
  title: 'versionSchema',
  description: 'version info',
  version: 0,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
      maxLength: 100
    },
    version: {
      type: 'string'
    },
    lastUpdateTime: {
      type: 'string',
      format: 'date-time'
    }
  }
};

const appVersion = process.env.REACT_APP_VERSION;
export const checkVersion = async () => {
  const version = await getLastVersion();
  if (version&&appVersion !== version.version) {
    return true;
  }
};

export const getLastVersion = async () => {
  const db = await Database.get();
  const data = await db.new_version
    .findOne({
      sort: [{ lastUpdateTime: 'desc' }]
    })
    .exec();
  return JSON.parse(JSON.stringify(data));
};
