import { StateCreator } from 'zustand/vanilla';
import { ModalKey } from './modalKey';
type ChangeModalState = (key: ModalKey, state: boolean) => void;
type CloseAllModalStates = (state: boolean) => void;

export type ModalNameState = Record<ModalKey, boolean>;
export type ModalState = ModalNameState & {
  changeModalState: ChangeModalState;
  closeAllModalStates: CloseAllModalStates;
};

export const createModalSlice: StateCreator<ModalState> = (set) => {
  const initialState: ModalNameState = {
    // account modal
    isSignInModalOpen: false,
    isSignUpModalOpen: false,
    isForgotPasswordModalOpen: false,
    isPincodeModalOpen: false,
    isDepositModalOpen: false,
    isEditModalOpen: false,
    isAddKeyModalOpen: false,
    isBalanceModalOpen: false,
    isVerifyModalOpen: false,
    isUpLoadImageModalOpen: false,
    isTakeASelfieModalOpen: false,
    isDriverLicenseModal: false,
    isUpLoadResultModalOpen: false,
    isWithDrawnModalOpen: false,
    isAddBankModalOpen: false,
    isManageBankModalOpen: false,
    isWithDrawalModalOpen: false,
    isClearDatabaseModalOpen: false,
    isLogoutAlertOpen: false,
    isSettingModalOpen: false,
    isHelpModalOpen: false,
    isMyTicketsModalOpen: false,
    isGameRecordModalOpen: false,
    isNotSupportsModalOpen: false,
    isVoucherModalOpen: false,
    // friends modal;
    isChatModalOpen: false,
    isNewChatModalOpen: false,
    isCreateChatModalOpen: false,
    isChannelModalOpen: false,
    isGroupModalOpen: false,
    isInviteListModalOpen: false,
    isUserInfoModalOpen: false,
    isNewGroupModalOpen: false,
    isGiftAVoucherModalOpen: false,
    // buy ticket modal
    isClickOpenModal: false,
    isJackpotModalOpen: false,
    isSplitTicketModalOpen: false,
    isAuth0ModalOpen: false,
    isFaceIdFailedAlertOpen: false,
    isWinModalOpen: false,
    // giftVoucher modal
    isGiftOptionsModalOpen: false,
    isGiftResultModalOpen: false,
    isGroupVoucherChooseModalOpen: false,
    // split Ticket
    isSplitTicketShareModalOpen: false,
    isSplitTicketFriendsModalOpen: false
  };

  return {
    ...initialState,
    changeModalState: (key, state) => set({ [key]: state }),
    closeAllModalStates: (state: boolean) => {
      const modalStates: Partial<ModalNameState> = {};
      (Object.keys(initialState) as ModalKey[]).forEach((key) => {
        modalStates[key] = state;
      });
      set(modalStates as ModalNameState);
    }
  };
};
