
import { IonModal, IonContent } from "@ionic/react";
import style from "../../../style/Account.module.css";
import { useState } from "react";
import { saveWalletInfo } from "../../../db/schema/wallet";
import { localGet } from "../../../utils/localStorage";
import { showToastMessage } from "../../../components/GlobalLoading";
import { useStore } from "../../../store"


interface Props {
    getdatas?: any;
    getWallet?: any;
}
const AddPrivateKeyModal: React.FC<Props> = ({
    getdatas,
    getWallet
}) => {
    const [addPrivateKey, setAddPrivateKey] = useState('')
    const { isAddKeyModalOpen,changeModalState } = useStore();
    return (
        <IonModal
            isOpen={isAddKeyModalOpen}
            className={style.AddKeyModal}
            initialBreakpoint={0.7}
            breakpoints={[0, 0.25, 0.5, 0.7, 0.75]}
            handleBehavior="cycle"
            onDidDismiss={() => {
                changeModalState('isAddKeyModalOpen', false)

            }}
            mode="ios"
        >
            <IonContent className="ion-padding">
                <div className={style.main}>
                    <div className={style.inp}>
                        <label htmlFor="authname">Private Key</label>
                        <input type="text" id="authname" value={addPrivateKey} onChange={(e) => setAddPrivateKey(e.target.value)} />
                    </div>
                    <div className={style.sub} onClick={async () => {
                        if (addPrivateKey === '') {
                            showToastMessage('Please enter.')
                            return
                        } else {
                            /* const db = await Database.get();
                            const myDocumentpre = await db.account.findOne({
                                selector: { walletAddress: localGet('authAddress') }
                            });
                            await myDocumentpre.exec().then(async (res: any) => {
                                await res.patch({
                                    walletInfo: {
                                        pKey: addPrivateKey
                                    }
                                })
                            }) */
                            await saveWalletInfo(localGet('authAddress'), addPrivateKey, null);
                            changeModalState('isAddKeyModalOpen', false)
                            getdatas()
                            getWallet()
                        }
                    }}>Confirm</div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default AddPrivateKeyModal