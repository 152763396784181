import { IonContent, IonPage, IonRefresher, IonRefresherContent } from "@ionic/react";
import { useCommonChannels } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useStore } from "store";
import ChatroomItem from "./components/chatroom-item";
import { DropDownSVG, PeopleSVG } from "./svg";

const NewChatRoom = () => {
    const { pathname } = useLocation();
    const { commonChatRoomMessages } = useStore()
    const { currentChannel, onChooseChannel, channelPeopleCount } = useCommonChannels()
    const chatListRef = useRef<HTMLUListElement>(null)
    const [activeChatId, setActiveChatId] = useState<string | undefined>()

    const handleChatClick = (id: string) => {
        setActiveChatId(activeChatId === id ? undefined : id)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pathname !== '/chatroom' || !chatListRef || !chatListRef.current) return;
            chatListRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
        }, 500)
        return () => clearTimeout(timer)
    }, [pathname, chatListRef]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (currentChannel && chatListRef && chatListRef.current) {
                chatListRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            }
        }, 500)
        return () => clearTimeout(timer)
    }, [currentChannel])
    return (
        <IonPage>
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={() => window.location.reload()}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="chatroom">
                    <div className="chatroom__header">
                        <div onClick={onChooseChannel} className="chatroom__header--dropdown">
                            <span>{currentChannel?.room_name}</span>
                            <DropDownSVG />
                        </div>
                        <div className="chatroom__header--people">
                            <PeopleSVG />
                            <span>{channelPeopleCount}</span>
                        </div>
                    </div>
                    <div className="chatroom__content">
                        <div className="chatroom__content--list">
                            <ul ref={chatListRef} >
                                {
                                    commonChatRoomMessages.map(chat => (
                                        <ChatroomItem emojiIconList={['👍', '❤️', '✋', '👏', '💰', '🚀']} type="ChatRoom" chat_message={{ message_id: chat.message_id, avatar: chat.avatar_url, message_time: chat.message_time }} ActiveBoxId={activeChatId} onClick={() => handleChatClick(chat.message_id)} key={chat.message_id}>
                                            <div className="public_chat_txt_box">
                                                <img src={chat.avatar_url} alt="avatar" />
                                                <p>
                                                    {chat.text}
                                                </p>
                                            </div>
                                        </ChatroomItem>
                                    )
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default NewChatRoom


