import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { localGet } from "utils/localStorage";

interface Props {
    speed?: number;
}

const HorizontalMarquee: React.FC<Props> = ({ speed = 4 }) => {
    const marqueeRef = useRef<HTMLDivElement>(null);

    const location = useLocation()
    const [windes, setwindes] = useState<any>([])
    useEffect(() => {
        PubSub.subscribe('syncData', function (msg, searchName) {
            if (searchName.scrollWin) {
                setwindes(searchName.scrollWin)
            }
        })
    }, [])

    const duration = windes.length === 1 ? 6 : windes.length * speed;

    const marqueeStyle = {
        animationDuration: `${duration}s`,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    };
    const isHidden = (
        ['/account', '/friends', '/login'].includes(location.pathname) ||
        location.pathname.includes('/share') ||
        localGet('haslogin') !== true
    );
    return (
        <>
            {
                windes.length > 0 &&
                <div
                    className="marquee"
                    ref={marqueeRef}
                    style={isHidden ? { visibility: 'hidden' } : {}}>
                    <div style={marqueeStyle}>
                        {windes.map((win: any, index: any) => (
                            <span key={index}>Congratulations <img src={
                                // `${win?.winnerAvatarURL}?${new Date().getTime()}}`
                                win?.winnerAvatarURL
                            } alt="" style={{ width: "20px", height: "20px", borderRadius: '50%' }} /> {win?.text}</span>
                        ))}
                    </div>
                </div>
            }
        </>

    );
};

export default HorizontalMarquee;