

import { IonModal, IonContent } from "@ionic/react";
import style from "../../../style/Account.module.css";
import { useStore } from "../../../store";
import SVGIcon from "components/SvgComponent";

const WithdrawalSuccessfulModal: React.FC = () => {
    const { isWithDrawalModalOpen,changeModalState } = useStore();

    return (
        <IonModal mode="ios"
            isOpen={isWithDrawalModalOpen}
            className={style.withdramodal}
            onDidDismiss={() => changeModalState('isWithDrawalModalOpen', false)}>
            <IonContent className="ion-padding">
                <div className={style.withdrabox}>
                    <div className={style.withdrasvg}>
                        <SVGIcon 
                            name="SuccessSvg" 
                            width="64"
                            height="64"
                            viewBox="0 0 64 64"
                            fill="none"
                        />
                        <p>Withdrawal Successful</p>
                        <div className={style.withdrap}>Your funding will be deposited to your registered bank account.</div>
                    </div>

                    <div className={style.withdrabtn} onClick={() => changeModalState('isWithDrawalModalOpen', false)}>
                        <p>Done</p>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default WithdrawalSuccessfulModal