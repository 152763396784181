


import { IonContent, IonModal } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Player } from 'video-react';
import contactsback from '../../../image/account/contacts.png';
import videoback from '../../../image/account/videoback.png';
import style from "../../../style/Account.module.css";

import SVGIcon from "components/SvgComponent";
import "video-react/dist/video-react.css";
import { getAssets } from "../../../db/schema/assets";
import { useStore } from "../../../store";
import { localGet } from "../../../utils/localStorage";


const HelpModal = () => {
    const { isHelpModalOpen,changeModalState } = useStore();

    // const videoHelp1 = require('../../../image/upcoming/mp4/login.mp4')
    const [clicklogin, setClicklogin] = useState(false)
    const [clickcontact, setClickcontact] = useState(false)

    const [loginTIme, setLoginTime] = useState(0)
    const [contactTime, setContactTime] = useState(0)

    const loginRef: any = useRef<any>(null)
    const contactRef = useRef<any>(null)

    // get video src
    const [videos, setVideos] = useState<any>([])
    useEffect(() => {
        const getVideoSrc = async () => {
            const videos: any = await getAssets();
            setVideos(videos)
        }
        getVideoSrc()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localGet('assets')])

    return (
        <IonModal
            isOpen={isHelpModalOpen}
            onIonModalDidPresent={() => {
                let logintime = loginRef.current.getState().player.duration
                logintime = String(logintime).replace(/\..*/g, "")
                setLoginTime(logintime)

                let contactTime = contactRef.current.getState().player.duration
                contactTime = String(contactTime).replace(/\..*/g, "")
                setContactTime(contactTime)

            }}
            onIonModalDidDismiss={() => {
                setClicklogin(false)
                setClickcontact(false)

            }}
            onDidDismiss={() => {
                changeModalState('isHelpModalOpen', false)
                setClicklogin(false)
                setClickcontact(false)
            }}
            mode="ios"
        >
            <IonContent>
                <div className={style.openHelpModal}>
                    <div className={style.tit} onClick={() => changeModalState('isHelpModalOpen', false)}>
                        <div>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                        </div>
                        <p>Video Help</p>
                        <div style={{ paddingRight: '16px' }}></div>
                    </div>
                    <ul className={style.videoList}>
                        <li onClick={() => {
                            setClicklogin(true);
                            loginRef.current.play()
                            // const videoPlayer = loginRef.current.video.video
                            // if (videoPlayer.requestFullscreen) {
                            //     videoPlayer.requestFullscreen();
                            // } else if (videoPlayer.mozRequestFullScreen) { //  Firefox
                            //     videoPlayer.mozRequestFullScreen();
                            // } else if (videoPlayer.webkitRequestFullscreen) { // Chrome, Safari and Opera
                            //     videoPlayer.webkitRequestFullscreen();
                            // } else if (videoPlayer.msRequestFullscreen) { //  IE/Edge
                            //     videoPlayer.msRequestFullscreen();
                            // }
                        }}>
                            <div style={clicklogin ? { display: 'block' } : { display: 'none' }}>
                                <Player
                                    ref={loginRef}
                                    preload='auto'
                                    autoPlay={false}
                                    playsInline={true}
                                    // src={localGet('assets')?.find((item: any) => (item._id === 'login'))?.data.EN['720']}
                                    src={videos?.find((item: any) => (item._id === 'alexblocks_content/login'))?.data.EN['720']}
                                // src={videoHelp1}
                                // src='https://storage.googleapis.com/oneinamillion-assets/loginEN720'
                                // crossorigin='https://storage.googleapis.com'
                                // poster={videoback}
                                >
                                </Player>
                            </div>
                            <img src={videoback} alt="" style={clicklogin ? { display: 'none' } : { display: 'block' }} />
                            <div className={style.videostart}
                                style={clicklogin ? { display: 'none' } : {}}>
                                <SVGIcon name="PlayIcon" width="42" height="41" viewBox="0 0 42 41" fill="none" />
                            </div>

                            <div className={style.videoDes} style={clicklogin ? { display: 'none' } : {}}>
                                Introduction
                            </div>
                            <div className={style.videoTime} style={clicklogin ? { display: 'none' } : {}}>0:{loginTIme}</div>
                        </li>
                        <li onClick={() => {
                            contactRef.current.play()
                            setClickcontact(true)
                        }}>
                            <div style={clickcontact ? { display: 'block' } : { display: 'none' }}>
                                <Player
                                    ref={contactRef}
                                    preload='auto'
                                    autoPlay={false}
                                    playsInline={true}
                                    // src={localGet('assets')?.find((item: any) => (item._id === 'login'))?.data.EN['720']}
                                    src={videos?.find((item: any) => (item._id === 'alexblocks_content/contact_picker'))?.data.EN['720']}
                                    // src={videoHelp1}
                                    // src='https://storage.googleapis.com/oneinamillion-assets/loginEN720'
                                    // crossorigin='https://storage.googleapis.com'
                                    poster={contactsback}
                                >
                                </Player>
                            </div>
                            <img src={contactsback} alt="" style={clickcontact ? { display: "none" } : { display: 'block' }} />
                            <div className={style.videostart}
                                style={clickcontact ? { display: 'none' } : {}}>
                                <SVGIcon name="PlayIcon" width="42" height="41" viewBox="0 0 42 41" fill="none" />
                            </div>

                            <div className={style.videoDes} style={clickcontact ? { display: 'none' } : {}}>
                                How to open the phone contact?
                            </div>
                            <div className={style.videoTime} style={clickcontact ? { display: 'none' } : {}}>0:{contactTime}</div>
                        </li>
                        <li>
                            <img src={require('../../../image/account/Frame 2137.png')} alt="" />
                        </li>
                        <li>
                            <img src={require('../../../image/account/Frame 2138.png')} alt="" />
                        </li>
                        <li>
                            <img src={require('../../../image/account/Frame 2140.png')} alt="" />
                        </li>
                    </ul>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default HelpModal