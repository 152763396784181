import { localGet } from 'utils/localStorage';
import * as Database from '../';
import { getShare } from "./share";

export const versionSchema = {
    title: 'versionSchema',
    description: 'version info',
    version: 1,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100,
        },
        version: {
            type: 'string',
        },
    }
};

const appVersion = process.env.REACT_APP_VERSION;
export const checkVersion = async (onCompletion) => {
    const db = await Database.get();
    const inviteID = await getShare();
    const findVersion = await db.version.findOne().exec();
    if (findVersion === null) {
        if (localGet('haslogin') !== null && inviteID === null) {
            onCompletion("new");
        } else if (inviteID !== null) {
            console.log('invite', inviteID.id);
            await db.version.upsert({id: "version", version: appVersion})
            return
        }
    }

    console.log('version', appVersion, findVersion.version);
    if (findVersion.version !== appVersion) {
        onCompletion("update");
    }
};

export const saveVersion = async () => {
    const db = await Database.get();
    await db.version.insert({id: "version", version: appVersion});
};

export const updateVersion = async () => {
    const db = await Database.get();
    /*const findVersion = await db.version.findOne().exec();
    await findVersion.patch({ version: appVersion });*/
    await db.version.upsert({id: "version", version: appVersion})
};

export const getVersion = async () => {
    const db = await Database.get();
    return await db.version.findOne().exec();
}