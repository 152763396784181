import { createContext, useState } from "react";

export const MyContext = createContext<any>(undefined);


interface Props {
    children: any
}
export const MyProvider: React.FC<Props> = ({ children }) => {
    const [context, setcontext] = useState<any>({
        account: {
            openDepositModal: false,
            copyKeyModal: false,
            addKeyModal: false,
            balanceModal: false,
            verifyIdentity: false, //verifyIdentity modal
            upLoadImageModal: false,
            takeASelfieModal: false,
            driverLicenseModal: false,
            upLoadResultModal: false,
            openwithdrawn: false,
            addbankmodal: false,
            managebank: false,
            withdrawal: false, //withdrawal successful modal
            openClearDatabase: false,
            openLogoutAlert: false,
            openSettingModal: false,
            openHelpModal: false,
            mytickets: false,
            gamerecord: false,
            notSupprot: false,
            vocuhersModal: false,
        },
        friends: {
            activemodal: false,
            newChatModal: false,
            createChatModal: false,
            addFriendModal: false,
            channnelModal: false,
            groupModal: false,
            openInviteList: false,
            openUserInfoModal: false,
            openIOSContactsFailedAlert: false, //add friends modal alert
            newGroupModal: false,
            giftAVoucher: false,
        },
        buyTicket: {
            clickOpenModal: false,  //buy ticket modal
            showLoading: false,
            JackpotOpen: false,
            SplitTicketOpen: false,
            openAuth0Modal: false,
            openFaceIDFailedAlert: false,
            winModal: false,
            notwin: false,
        },
        common: {
            client: undefined,
            isLoading: false
        },
        topData: {
            clickRing: false,
            clickDaily: false,
            chatProps: undefined
        },
        giftVocher: {
            giftOptionsModal: false,
            giftResult: false,
            inFriends: false,
            groupVoucherChoose: false
        }
    });
    const updateObjectByKey = (key: string, value: any): any => {
        const updatedObject = { ...context };

        for (const prop in updatedObject) {
            if (updatedObject.hasOwnProperty(prop)) {
                if (updatedObject[prop].hasOwnProperty(key)) {
                    updatedObject[prop][key] = value;
                }
            }
        }

        return updatedObject;
    }
    const closeAllModals = () => {
        const updatedObject = { ...context };
        for (const prop in updatedObject) {
            if (updatedObject.hasOwnProperty(prop)) {
                const innerObj = updatedObject[prop];
                if (typeof innerObj === 'object' && innerObj !== null) {
                    for (const key in innerObj) {
                        if (innerObj.hasOwnProperty(key)) {
                            if (key !== 'client' && key !== 'chatProps') {
                                innerObj[key] = false;
                            }
                        }
                    }
                }
            }
        }
        setcontext(updatedObject)
    }
    const updateContext = (key: string, value: boolean) => {
        setcontext(updateObjectByKey(key, value));
    };
    return (
        <MyContext.Provider value={{ context, updateContext, closeAllModals }}>
            {children}
        </MyContext.Provider>
    );
};
