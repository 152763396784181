import { IonContent, IonModal, IonRefresher, IonRefresherContent } from '@ionic/react';
import { User } from '@supabase/supabase-js';
import { useLocalStorage } from 'hooks';
import { useEffect, useState } from 'react';
import { tokenApi } from '../../../../api';
import { setShowLoading, showToastMessage } from '../../../../components/GlobalLoading';
import { selectVoucher } from '../../../../db/schema/voucher';
import noneimg from "../../../../image/account/node.png";
import { useStore } from '../../../../store';
import { formatTimeDate } from '../../../../utils/formatDate';
import style from './index.module.css';

const MyVoucher = () => {
    const { globalUserInfo,isVoucherModalOpen,changeModalState } = useStore();
    const [localStorageUserInfo] = useLocalStorage<User>('user_info');
    const userInfo = localStorageUserInfo ?? globalUserInfo;
    const [defaultType, setDefaultType] = useState('Received')
    const [showData, setShowData] = useState<any>(null)

    useEffect(() => {
        PubSub.subscribe('syncData', async (msg, searchName) => {
            if (!searchName.vouchers) { } else {
                const data = await selectVoucher(defaultType)
                setShowData(data)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOnReceived = async () => {
        if (defaultType === 'Received') return
        else {
            setDefaultType('Received')
            const data = await selectVoucher('Received')
            console.log(data);

            setShowData(data)
        }
    }

    const handleOnSent = async () => {
        if (defaultType === 'Sent') return
        else {
            setDefaultType('Sent')
            const data = await selectVoucher('Sent')
            console.log(data);
            setShowData(data)
        }
    }

    const handleOnAccept = async () => {
        if (defaultType === 'Accepted') return
        else {
            setDefaultType('Accepted')
            const data = await selectVoucher('Accepted')
            console.log(data);

            setShowData(data)
        }
    }

    const handleOnRejected = async () => {
        if (defaultType === 'Rejected') return
        else {
            setDefaultType('Rejected')
            const data = await selectVoucher('Rejected')
            console.log(data);

            setShowData(data)
        }
    }

    const handleRejectVoucher = async (item: any) => {
        setShowLoading(true)
        tokenApi('/rejectVoucher', {
            "voucherId": item?._id
        }).then(async res => {
            if (res.code === 200) {
                const data = await selectVoucher('Received')
                setShowData(data)
                showToastMessage('Rejected succesfully.')
                setShowLoading(false)
            } else {
                showToastMessage(res?.detail)
                setShowLoading(false)
            }
        })
    }

    const handleAcceptVoucher = async (item:any) => {
        setShowLoading(true)
        console.log(item)
        tokenApi('/acceptVoucher', {
            "voucherId": item?._id
        }).then(async res => {
            if (res.code === 200) {
                const data = await selectVoucher('Received')
                setShowData(data)
                showToastMessage('Accetped successfully.')
                setShowLoading(false)
            } else {
                showToastMessage(res?.detail)
                setShowLoading(false)
            }
        })
    }

    const handleResend = async (item: any) => {
        setShowLoading(true)
        if (item.giftMethod === "email") {
            tokenApi('/inviteFriendByEmail', {
                "friends": [
                    item?.receiver?.email
                ],
                // "voucherId": item?._id
            }).then(async res => {
                if (res.code === 200) {
                    const data = await selectVoucher('Sent')
                    setShowData(data)
                    showToastMessage('Resend was successful.')
                    setShowLoading(false)
                } else {
                    showToastMessage(res?.detail)
                    setShowLoading(false)
                }
            })
        } else {
            tokenApi('/inviteFriendBySMS', {
                "friends": [
                    item?.receiver?.phone
                ],
                // "voucherId": item?._id
            }).then(res => {
                if (res.code === 200) {
                    showToastMessage('Resend was successful.')
                    setShowLoading(false)
                } else {
                    showToastMessage(res?.detail)
                    setShowLoading(false)
                }
            })
        }
    }

    return (
        < IonModal
            isOpen={isVoucherModalOpen}
            mode="ios"
            onDidDismiss={() => {
                changeModalState('isVoucherModalOpen', false)
                setDefaultType('Received')
                setShowData(null)
            }}
            onWillPresent={async () => {
                const data = await selectVoucher(defaultType)
                setShowData(data)
            }}
            className={style.voucherPage}
        >
            <IonContent>
                <div className={style.voucherMain}>
                    <div className={style.title}>
                        <div onClick={() => {
                            changeModalState('isVoucherModalOpen', false)
                        }}>
                            <svg
                                width="10"
                                height="16"
                                viewBox="0 0 10 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M8 14L2 8L8 2" stroke="white" strokeWidth="2" strokeLinecap="square" />
                            </svg>
                        </div>
                        <span>My Vouchers</span>
                        <div style={{ paddingRight: '16px', justifyContent: 'flex-end' }}>
                        </div>
                    </div>
                    <IonRefresher slot="fixed" onIonRefresh={(e) => {
                        setTimeout(async () => {
                            const data = await selectVoucher(defaultType)
                            setShowData(data)
                            e.detail.complete()
                        }, 1000);
                    }}>
                        <IonRefresherContent></IonRefresherContent>
                    </IonRefresher>
                    <div className={style.nav}>
                        <ul>
                            <li className={defaultType === 'Received' ? style.act : ''}
                                onClick={handleOnReceived}>
                                Received
                                <div></div>
                            </li>
                            <li className={defaultType === 'Sent' ? style.act : ''}
                                onClick={handleOnSent}>
                                Sent
                                <div></div>
                            </li>
                            <li className={defaultType === 'Accepted' ? style.act : ''}
                                onClick={handleOnAccept}>
                                Accepted
                                <div></div>
                            </li>
                            <li className={defaultType === 'Rejected' ? style.act : ''}
                                onClick={handleOnRejected}>
                                Rejected
                                <div></div>
                            </li>
                        </ul>
                    </div>
                    <div className={style.main}>
                        <ul>
                            {
                                showData?.map((item: any, index: any) => (
                                    <li key={index}>
                                        <div className={style.moneyInfo}>
                                            <p>$ <span>{item?.paymentAmount?.toLocaleString()}</span></p>
                                            {
                                                (defaultType === 'Accepted' || defaultType === 'Rejected') &&

                                                <div className={item?.buyer?._id !== userInfo?.user_metadata?.user_wallet ? style.from : style.to}>{item?.buyer?._id !== userInfo?.user_metadata?.user_wallet ? 'From' : "To"}</div>
                                            }
                                        </div>
                                        <div className={style.circle}>
                                            <div className={style.top}></div>
                                            <div className={style.border}></div>
                                            <div className={style.bottom}></div>
                                        </div>
                                        <div className={style.infoActs}>
                                            <div className={style.userInfo}>
                                                {
                                                    item?.buyer?._id === userInfo?.user_metadata?.user_wallet ?
                                                        item?.receiver?.avatar === null ?
                                                            <p>{item?.receiver?.email.length > 18 ? item?.receiver?.email.slice(0, 15) + '...' : item?.receiver?.email || item?.receiver?.phone}</p>
                                                            :
                                                            <p><img src={item?.receiver?.avatar} alt="" />{item?.receiver?.nickname}</p>
                                                        :
                                                        <p><img src={item?.buyer?.avatar} alt="" />{item?.buyer?.nickname}</p>
                                                }
                                                <span style={{ color: item.state === 'expired' ? '#9F423C' : '#979797' }}>{formatTimeDate(new Date(item?.createdTime))} - {formatTimeDate(new Date(item?.expiryTime))}</span>
                                            </div>
                                            {
                                                defaultType === 'Received' && item?.state === 'pending' ?
                                                    <div className={style.Received}>
                                                        <div className={style.Reject}
                                                            onClick={() => handleRejectVoucher(item)}>Reject</div>
                                                        <div className={style.Accept}
                                                            onClick={() => handleAcceptVoucher(item)}>Accept</div>
                                                    </div> : <></>
                                            }
                                            <div className={style.Sent}>
                                                <div className={style.status}
                                                    style={
                                                        item?.state === 'accepted' ?
                                                            { color: '#CFFFC7', borderColor: '#CFFFC7' }
                                                            : item?.state === 'rejected' ?
                                                                { color: '#AA6060', borderColor: '#AA6060' }
                                                                : item?.state === 'pending' ?
                                                                    { color: '#BDBCF6', borderColor: '#BDBCF6' }
                                                                    : item?.state === 'expired' ?
                                                                        { color: '#595862', borderColor: '#595862' }
                                                                        : { color: '#CFFFC7', borderColor: '#CFFFC7' }
                                                    }
                                                >
                                                    {
                                                        item?.state.charAt(0).toUpperCase()
                                                        + item?.state.slice(1)
                                                    }
                                                </div>
                                                {
                                                    defaultType === 'Sent' && item?.giftMethod !== 'wallet' && item?.state === 'pending' &&
                                                    <div className={style.act}
                                                        onClick={()=>handleResend(item)}
                                                    >Resend</div>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }

                        </ul>

                        <div className={style.dataNone} style={{ display: showData?.length > 0 ? 'none' : 'flex' }}>
                            <div className={style.dataNoneImg}>
                                <img src={noneimg} alt="" />
                            </div>
                            <p>A little empty here</p>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal >
    );
}

export default MyVoucher;