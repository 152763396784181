import { IonContent, IonModal } from '@ionic/react';
import Button from 'components/common/Button';
import { FC } from 'react';
import avatar from '../../image/chatRoom/meAvatar.png';
interface BlockModalProps {
    isOpen: boolean
    closeModal: () => void
}
const BlockModal: FC<BlockModalProps> = ({ isOpen, closeModal }) => {
    const onCloseModal = () => {
        closeModal()
    }
    return (
        <IonModal className='block-modal' isOpen={isOpen} initialBreakpoint={0.3} breakpoints={[0, 0.3]} onWillDismiss={onCloseModal}>
            <IonContent >
                <div className='block-modal__content'>
                    <div className='block-modal__content--avatar'>
                        <img src={avatar} alt='avatar' />
                    </div>
                    <p>Are you sure to block Cheryl?</p>
                    <Button >Block</Button>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default BlockModal