export function throttle(method: () => void, duration: number) {
    let timer: ReturnType<typeof setTimeout> | null = null;
    let prevTime = new Date();
  
    return function(this: any) {
      const that = this;
      const currentTime = new Date();
      const resTime = currentTime.getTime() - prevTime.getTime();
  
      if (resTime < duration) {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          prevTime = currentTime;
          method.apply(that);
        }, duration - resTime);
      } else {
        prevTime = currentTime;
        method.apply(that);
      }
    };
}
