import { RealtimeChannel } from '@supabase/supabase-js';
import { removeChannel } from 'api/chat';
import { useEffect } from 'react';

const useChannelSubscribe = ({
  realtimeChannel
}: {
  realtimeChannel?: RealtimeChannel;
}) => {
  useEffect(() => {
    realtimeChannel?.subscribe();
    return () => {
      realtimeChannel?.unsubscribe();
      realtimeChannel && removeChannel(realtimeChannel);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeChannel]);

/*   useEffect(() => {
    const listener = async (e: BeforeUnloadEvent) => {
      e.preventDefault();
      realtimeChannel && removeChannel(realtimeChannel);
    };
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */
};

export default useChannelSubscribe;
