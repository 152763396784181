import { IonModal, IonContent } from "@ionic/react";
import mixstyle from "../../../style/Topdata.module.css";
import { useStore } from '../../../store';
import SVGIcon from "components/SvgComponent";

const VerifyIdentityModal: React.FC = () => {
    const { isVerifyModalOpen,changeModalState } = useStore();
    return (
        <IonModal mode="ios"
         isOpen={isVerifyModalOpen}
          onDidDismiss={() => changeModalState('isVerifyModalOpen',false)}>
            <IonContent>
                <div className={mixstyle.verifyIdentityModal}>
                    <div className={mixstyle.tit} onClick={() => { changeModalState('isVerifyModalOpen',false) }}>
                        <div>
                            <SVGIcon name="BackSvg" width="8" height="12" viewBox="0 0 8 12" fill="none"/>
                            <span>Verify Identity</span>
                        </div>
                        <SVGIcon name="CloseSvg" width="14" height="14" viewBox="0 0 14 14"/>
                    </div>
                    <div className={mixstyle.line}></div>
                    <div className={mixstyle.images}>
                        <SVGIcon name="VerifyIcon" width="72" height="72" viewBox="0 0 72 72" fill="none"/>
                        <div>
                            Let's make sure you're you by complete the following steps.
                        </div>
                    </div>
                    <ul className={mixstyle.verify}>
                        <li onClick={() => changeModalState('isTakeASelfieModalOpen',true)}>
                            <div>
                                <SVGIcon name="VerifySelfIcon" width="24" height="24" viewBox="0 0 24 24"/>
                                <p>
                                    Take a Selfie
                                    <SVGIcon name="ReferralsDiamond" width="12" height="12" viewBox="0 -3 12 12"/>
                                    {/* <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_827_6397)">
                                            <path d="M2.48118 2H6.94672C6.99616 1.99999 7.04489 2.01476 7.08894 2.04311C7.13299 2.07146 7.1711 2.11258 7.20017 2.1631L8.39785 4.24388C8.41914 4.2809 8.42962 4.32611 8.42751 4.3718C8.4254 4.41749 8.41082 4.46084 8.38626 4.49448L4.82893 9.36427C4.81426 9.3843 4.79648 9.40028 4.77669 9.41121C4.75691 9.42214 4.73555 9.42778 4.71395 9.42778C4.69236 9.42778 4.671 9.42214 4.65121 9.41121C4.63143 9.40028 4.61364 9.3843 4.59898 9.36427L1.04165 4.49487C1.01701 4.46121 1.00238 4.41778 1.00027 4.372C0.998152 4.32622 1.00868 4.28094 1.03005 4.24388L2.22774 2.1631C2.2568 2.11258 2.29492 2.07146 2.33897 2.04311C2.38302 2.01476 2.43175 1.99999 2.48118 2Z" fill="#42E0A7" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.11133 8.76621L7.32342 10.4254C7.33809 10.4454 7.35587 10.4614 7.37566 10.4723C7.39544 10.4832 7.4168 10.4889 7.4384 10.4889C7.45999 10.4889 7.48135 10.4832 7.50114 10.4723C7.52092 10.4614 7.53871 10.4454 7.55337 10.4254L11.1107 5.55557C11.1353 5.52194 11.1498 5.47858 11.152 5.43289C11.1541 5.3872 11.1436 5.342 11.1223 5.30498L9.92461 3.2242C9.89555 3.17367 9.85743 3.13256 9.81338 3.10421C9.76933 3.07586 9.7206 3.06109 9.67117 3.0611H8.56259L9.24339 4.24387C9.26468 4.28089 9.27516 4.32609 9.27305 4.37178C9.27093 4.41747 9.25636 4.46082 9.2318 4.49446L6.11133 8.76621Z" fill="#42E0A7" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_827_6397">
                                                <rect width="12" height="12" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg> */}
                                </p>
                            </div>
                            <SVGIcon name="EnterArrow" width="12" height="24" viewBox="6 0 12 24"/>
                        </li>
                        <li onClick={() => changeModalState('isUpLoadImageModalOpen',true)}>
                            <div >
                                <SVGIcon name="UploadIcon" width="24" height="24" viewBox="0 0 24 24" fill="#A6A9B8"/>
                                <p>
                                    Photo ID
                                    <SVGIcon name="ReferralsDiamond" width="12" height="12" viewBox="0 -3 12 12"/>
                                    {/* <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_827_6397)">
                                            <path d="M2.48118 2H6.94672C6.99616 1.99999 7.04489 2.01476 7.08894 2.04311C7.13299 2.07146 7.1711 2.11258 7.20017 2.1631L8.39785 4.24388C8.41914 4.2809 8.42962 4.32611 8.42751 4.3718C8.4254 4.41749 8.41082 4.46084 8.38626 4.49448L4.82893 9.36427C4.81426 9.3843 4.79648 9.40028 4.77669 9.41121C4.75691 9.42214 4.73555 9.42778 4.71395 9.42778C4.69236 9.42778 4.671 9.42214 4.65121 9.41121C4.63143 9.40028 4.61364 9.3843 4.59898 9.36427L1.04165 4.49487C1.01701 4.46121 1.00238 4.41778 1.00027 4.372C0.998152 4.32622 1.00868 4.28094 1.03005 4.24388L2.22774 2.1631C2.2568 2.11258 2.29492 2.07146 2.33897 2.04311C2.38302 2.01476 2.43175 1.99999 2.48118 2Z" fill="#42E0A7" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.11133 8.76621L7.32342 10.4254C7.33809 10.4454 7.35587 10.4614 7.37566 10.4723C7.39544 10.4832 7.4168 10.4889 7.4384 10.4889C7.45999 10.4889 7.48135 10.4832 7.50114 10.4723C7.52092 10.4614 7.53871 10.4454 7.55337 10.4254L11.1107 5.55557C11.1353 5.52194 11.1498 5.47858 11.152 5.43289C11.1541 5.3872 11.1436 5.342 11.1223 5.30498L9.92461 3.2242C9.89555 3.17367 9.85743 3.13256 9.81338 3.10421C9.76933 3.07586 9.7206 3.06109 9.67117 3.0611H8.56259L9.24339 4.24387C9.26468 4.28089 9.27516 4.32609 9.27305 4.37178C9.27093 4.41747 9.25636 4.46082 9.2318 4.49446L6.11133 8.76621Z" fill="#42E0A7" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_827_6397">
                                                <rect width="12" height="12" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg> */}
                                </p>
                            </div>
                            <SVGIcon name="EnterArrow" width="12" height="24" viewBox="6 0 12 24"/>
                            {/* <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.70492 0L0.294922 1.41L4.87492 6L0.294922 10.59L1.70492 12L7.70492 6L1.70492 0Z" fill="#CCCCCC" />
                            </svg> */}
                        </li>
                    </ul>
                    <div className={mixstyle.act}>
                        <div className={mixstyle.iden}>Verify Identity</div>
                        <div className={mixstyle.later} onClick={() => {
                            changeModalState('isVerifyModalOpen',false)
                        }}>
                            I'll do it later.
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}
export default VerifyIdentityModal