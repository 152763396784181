import { supabase_public, http } from './initSupabase';
import { compressAvatar, uploadFile } from './file'

export interface AccountParams {
  id: string;
  userName?: string;
  password?: string;
  avatar?: File;
  pinCode?: string;
  wallet?: string;
}

/**
 * update account
 * @param params AccountParams
 * @returns {status, msg, data}
 */
export const updateAccount = async (params: AccountParams) => {
  let avatar = null

  // check params
  if (!params.id) {
    return { status: 400, msg: 'Missing user ID.', data: null };
  }

  // update password
  if (params.password) {
    const { error } = await supabase_public.auth.updateUser({
      password: params.password.trim()
    });
    if (error) {
      return { status: 400, msg: error.message, data: null };
    }
  }

  // update username
  if (params.userName) {
    // check duplicate username
    const response = await http.instance.get(`/checkUserNameExist?userName=${params.userName.trim()}`)
      .catch(function (error: { message: any; }) {
        return { status: 400, msg: error.message, data: null }
      })
    if ((response).data.data.isExist === true) {
      return { status: 400, msg: 'Username already exists.', data: null }
    }

    const { error } = await supabase_public.auth.updateUser({
      data: { user_name: params.userName.trim() }
    });
    if (error) {
      return { status: 400, msg: error.message, data: null };
    }
  }

  // update pin code
  if (params.pinCode) {
    const { error } = await supabase_public.auth.updateUser({
      data: { pin_code: params.pinCode.trim() }
    });
    if (error) {
      return { status: 400, msg: error.message, data: null };
    }
  }

  // update wallet
  if (params.wallet) {
    const { error } = await supabase_public.auth.updateUser({
      data: { user_wallet: params.wallet.trim() }
    });
    if (error) {
      return { status: 400, msg: error.message, data: null };
    }
  }

  // update avatar
  if (params.avatar) {
    const ext = params.avatar.name.split('.').pop();
    if (!ext) {
      return { status: 400, msg: 'File missing suffix.', data: null };
    }

    const file = await compressAvatar(params.avatar)
    if (file.status === 400) { return { file } }

    // upload image
    const filePath = params.id + '.' + ext;
    const { status, msg, data } = await uploadFile('avatars', params.id + '/' + filePath, file.data!)
    if (status === 400) { return { status: status, msg: msg, data: data } }
    avatar = data

    // update user profile avatar
    const { error } = await supabase_public.auth.updateUser({
      data: { avatar: avatar }
    })
    if (error) { return { status: 400, msg: error.message, data: null } }
  }
  return {
    status: 200,
    msg: 'Successfully updated account information.',
    data: { 'avatarURL': avatar }
  };
};


/**
 * retrieve a user
 * @returns {status, msg, data}
 */
export const retrieveUser = async () => {
  const {
    data: { user },
    error
  } = await supabase_public.auth.getUser();
  if (error) {
    return { status: 400, msg: error.message, data: null };
  }
  return { status: 200, msg: null, data: user };
};
