import { useState } from "react"
import GiftResult from "./GiftResult"

const GiftComponent = () => {
    // const { user, isAuthenticated } = useAuth0()
    const [isShow] = useState(false)
    // useEffect(() => {
    //     if (user && isAuthenticated) {
    //         setIsShow(true)
    //     }
    // }, [user, isAuthenticated])
    if (isShow) {
        return (
            <>
                {/* <GiftVoucherDetail isOpen={false}></GiftVoucherDetail> */}
                <GiftResult></GiftResult>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}
export default GiftComponent