import { getRoomMembers, roomMessages } from 'api/chat';
import { setShowLoading, showToastMessage } from 'components/GlobalLoading';
import { RefObject, useEffect, useState } from 'react';
import { useStore } from 'store';
import { ParsedChatMessage } from 'store/chatRoomSlice';
import useUser from './useUser';

interface ChatMessageHookParams {
  scrollRef: RefObject<HTMLUListElement>;
}

const useChatMessage = ({ scrollRef }: ChatMessageHookParams) => {
  const { getProfile } = useUser();
  const [privateUserId, setCurrentPrivateUserId] = useState<string>('');
  const [currentUserID, setCurrentUserID] = useState<string>('');
  const { newMessage, isChatModalOpen, currentRoomInfo, readMessage } =
    useStore();
  const [messages, setMessages] = useState<ParsedChatMessage[]>([]);

  const getAllChatMessages = async () => {
    setShowLoading(true);
    try {
      const user = await getProfile();
      if (!user) return;
      if (!currentRoomInfo) return;
      setCurrentUserID(user.id);
      const { status, data } = await roomMessages(currentRoomInfo.room_id);
      if (data && status === 200) {
        setMessages(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };
  const privateChatUserInfo = async () => {
    if (!currentRoomInfo) return;
    try {
      const data = await getRoomMembers(currentRoomInfo.room_id);
      if (data.length === 1) {
        setCurrentPrivateUserId(data[0].user_id);
      }
    } catch (error: any) {
      showToastMessage(error.message);
    }
  };
  useEffect(() => {
    if (newMessage) {
      if (newMessage.room_id === currentRoomInfo?.room_id) {
        setMessages((prev) => [...prev, newMessage]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessage]);
  useEffect(() => {
    if (currentRoomInfo) {
      if (!currentRoomInfo.isGroup) {
        privateChatUserInfo();
      }
      getAllChatMessages();
      currentRoomInfo && readMessage(currentRoomInfo.room_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoomInfo]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isChatModalOpen && messages && scrollRef && scrollRef.current) {
        scrollRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isChatModalOpen]);

  return { messages, currentUserID, setMessages, privateUserId };
};

export default useChatMessage;
