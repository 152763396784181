import { FC, MouseEvent, useEffect, useState } from "react";
import { useStore } from "store";
import { RoomInfo } from "store/chatRoomSlice";


interface RecentContactsResultsProps {
    loading?: boolean
    query: string
    openChatModal: (recentContact: RoomInfo) => void
};



const RecentContactsResults: FC<RecentContactsResultsProps> = ({ query, openChatModal, loading = false }) => {
    const { chatRooms } = useStore()
    const [filterContacts, setFilterContacts] = useState<RoomInfo[]>([])
    const isFilterNull = filterContacts.length === 0
    const isRecentContactNull = chatRooms.length === 0

    const handleClick = (item: RoomInfo) => {
        return (event: MouseEvent<HTMLLIElement>) => {
            openChatModal(item)
        }
    }
    useEffect(() => {
        setFilterContacts(chatRooms.filter((item) => item.name.includes(query.trim())))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return (
        <div style={{ width: '92%', height: 'fit-content', backgroundColor: '#000', position: 'absolute', zIndex: '9999', top: 105, maxWidth: '96%', display: 'inline-block', overflow: 'hidden' }}>
            {
                loading ? (
                    <p style={{ height: 50, lineHeight: '50px', textAlign: 'center' }}>
                        <span>Loading...</span>
                    </p>
                ) : (
                    <>
                        {
                            isFilterNull || isRecentContactNull ? (
                                <p style={{ height: 50, lineHeight: '50px', textAlign: 'center' }}>
                                    <span>No friends or group chats</span>
                                </p>
                            ) : (
                                <>
                                    <ul>
                                        {
                                            filterContacts.map((item) => {
                                                return (
                                                    <li onClick={handleClick(item)} style={{ height: 50, lineHeight: '50px', paddingLeft: '16px' }} key={item.name}>
                                                        <span>{item.name}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            )
                        }

                    </>
                )
            }
        </div>
    )
}

export default RecentContactsResults;