export interface Ticket {
  _id: string;
  draw: number;
  owner: string;
  ownerDetail: OwnerDetail;
  purchaser: string;
  purchaserDetail: OwnerDetail;
  state: string;
  ticketNumber: number;
  ticketShare: number;
  paymentMethod: string;
  splitTicketPrice: number;
  splitTicketPrize: number;
  ticketPrice: number;
  prize: number;
  organizer: OwnerDetail;
  invitees: Inviter[];
  purchaseTime: string;
  lastUpdateTime: string;
  drawData: string;
  ticketPercentage:string
}

export interface Inviter {
  _id: string;
  nickname: string;
  avatarURL: string;
  state: InviterState;
}

interface OwnerDetail {
  _id: string;
  nickname: string;
  avatarURL: string;
}
export enum InviterState {
  Pending = 'pending',
  Activate = 'activate',
  Rejected = 'rejected',
  Expired = 'expired'
}
